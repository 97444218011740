/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import { ButtonGroup } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { useSnackbar } from "notistack";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import {
  fetch_file_from_s3,
  get_css,
  get_source_code_js,
} from "../../../DAL/fetch_api/fetch_api";
import {
  get_source_code_html,
  get_source_code_css,
} from "../../../DAL/fetch_api/fetch_api";
import { upload_files } from "../../../DAL/section/section";
import Editor from "@monaco-editor/react";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    // marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },

  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditSourceCode(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(true);
  const [category, setCategory] = useState([]);
  // const [URL, setURL] = useState(
  //   "https://accelerator-builder-bucket.s3.amazonaws.com"
  // );
  const [CssFile, setCss] = useState("");
  const [htmlFile, setHtml] = useState("");
  const [JSFile, setJSFile] = useState("");
  const [selectedTab, setSelectedTab] = useState("html");
  const [isBackPage, setIsBackPage] = useState(false);
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [dotTabs, setDotTabs] = useState({
    html: false,
    css: false,
    js: false,
  });
  const [sectiontype, setSectionType] = useState("");
  const [order, setOrder] = useState("");
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const [checked, setChecked] = React.useState(false);

  // const handleChange = (event) => {
  //   //  console.log(event.target.checked);
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const handleback = () => {
    if (isCodeChanged) {
      handleIsBackPage();
    } else {
      history.goBack();
    }
  };

  const handleIsBackPage = () => {
    setIsBackPage(true);
  };

  const handleCodeCganged = (tab) => {
    setDotTabs((prevState) => ({
      ...prevState,
      [tab]: true,
    }));
    setIsCodeChanged(true);
  };

  const handlesubmit = async (data) => {
    setIsCodeChanged(false);
    setIsBackPage(false);
    data.preventDefault();
    // console.log(CssFile,"csss");
    // console.log(htmlFile,"htmlklllll");
    setiswaiting(true);
    let postdata = {
      project_id: props.match.params.id,
      html: htmlFile,
      css: CssFile,
      javascript: JSFile,
      type: "section",
    };
    const edit_file = await upload_files(postdata);
    if (edit_file.code == 200) {
      props.history.push("/section");
    } else {
      setiswaiting(false);
      enqueueSnackbar(edit_file.message, { variant: "error" });
    }
  };

  const fetch_files_from_s3 = async () => {
    const html_file_path = `${s3baseUrl}${props.match.params.id}/index.html`;
    const result_html = await fetch_file_from_s3(html_file_path);
    setHtml(result_html);
    const css_file_path = `${s3baseUrl}${props.match.params.id}/css/style.css`;
    const result_css = await fetch_file_from_s3(css_file_path);
    setCss(result_css);
    const js_file_path = `${s3baseUrl}${props.match.params.id}/js/index.js`;
    const result_js = await fetch_file_from_s3(js_file_path);
    setJSFile(result_js);
    setiswaiting(false);
  };

  useEffect(async () => {
    fetch_files_from_s3();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              // marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <div className="d-flex justify-content-between">
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Source Code
            </Typography>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              color="primary"
              size="small"
              className={classes.txt}
            >
              <Button
                style={{ width: "87px" }}
                variant={selectedTab === "html" ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab("html");
                }}
              >
                HTML{" "}
                {dotTabs.html && (
                  <Icon style={{ fontSize: 15 }}>fiber_manual_record</Icon>
                )}
              </Button>
              <Button
                style={{ width: "87px" }}
                variant={selectedTab === "css" ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab("css");
                }}
              >
                CSS{" "}
                {dotTabs.css && (
                  <Icon style={{ fontSize: 15 }}>fiber_manual_record</Icon>
                )}
              </Button>
              <Button
                style={{ width: "87px" }}
                variant={selectedTab === "js" ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab("js");
                }}
              >
                JS{" "}
                {dotTabs.js && (
                  <Icon style={{ fontSize: 15 }}>fiber_manual_record</Icon>
                )}
              </Button>
            </ButtonGroup>
          </div>
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3}>
              {selectedTab === "html" ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <div className="editor">
                    <Typography variant="subtitle2">HTML:</Typography>
                    <Editor
                      height="80vh"
                      defaultLanguage="html"
                      defaultValue="// some comment"
                      value={htmlFile}
                      id="html"
                      onChange={(newValue, event) => {
                        setHtml(newValue);
                        handleCodeCganged("html");
                      }}
                      label="HTML"
                      name="html"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}
              {selectedTab === "css" ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <div className="editor">
                    <Typography variant="subtitle2">CSS:</Typography>
                    <Editor
                      height="80vh"
                      defaultLanguage="css"
                      defaultValue="// some comment"
                      value={CssFile}
                      id="css"
                      onChange={(newValue, event) => {
                        setCss(newValue);
                        handleCodeCganged("css");
                      }}
                      label="CSS"
                      name="css"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}
              {selectedTab === "js" ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <div className="editor">
                    <Typography variant="subtitle2">Javascript:</Typography>
                    <Editor
                      height="80vh"
                      defaultLanguage="javascript"
                      defaultValue="// some comment"
                      value={JSFile}
                      id="js"
                      onChange={(newValue, event) => {
                        setJSFile(newValue);
                        handleCodeCganged("js");
                      }}
                      label="JavaScript"
                      name="js"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      <Dialog
        open={isBackPage}
        onClose={() => setIsBackPage(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "15px 20px 15px 20px" }}
        >
          You have unsaved changes. Would you like to save them?
        </DialogTitle>
        <DialogActions
          style={{
            padding: "15px 20px 20px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              setIsBackPage(false);
            }}
            size="small"
          >
            Cancel
          </Button>
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
                setIsBackPage(false);
                setIsCodeChanged(false);
                setDotTabs(false);
              }}
              size="small"
              className="me-2"
              color="primary"
            >
              Discard Changes
            </Button>
            <Button
              variant="contained"
              onClick={async (e) => {
                history.goBack();
                handlesubmit(e);
                setDotTabs(false);
              }}
              size="small"
              color="primary"
            >
              Save Changes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
