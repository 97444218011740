import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import {
  get_category_detail,
  edit_Category,
  upload_image,
} from "../../DAL/categories/category";
import { s3baseUrl } from "../../config/config";
import { default_image } from "../../assets";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditCategory(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [order, setOrder] = useState();
  const [status, setStatus] = useState(true);
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [temp_image, setTempImage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [is_load_data, setLoadData] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);

  const handlename = (event) => {
    setName(event.target.value);
  };

  //console.log(props,"props values")
  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    formData.append("name", name);
    formData.append("status", status);
    formData.append("order", order);
    //formData.append("image", Image);
    if (Image) {
      formData.append("image", Image);
    } else {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    }
    const templatecategory_edit_resp = await edit_Category(
      formData,
      props.match.params.id
    );
    console.log(templatecategory_edit_resp, "RESPONSE");
    if (templatecategory_edit_resp.code == 200) {
      setiswaiting(false);
      props.history.push("/categories");
    } else {
      enqueueSnackbar(templatecategory_edit_resp.message, { variant: "error" });
    }
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  useEffect(async () => {
    const templatecategory_detail = await get_category_detail(
      props.match.params.id
    );
    console.log(templatecategory_detail);
    setName(templatecategory_detail.category.name);
    setOrder(templatecategory_detail.category.order);
    setStatus(templatecategory_detail.category.status);
    setProfileImage(s3baseUrl + templatecategory_detail.category.image);
    setimage(templatecategory_detail.category.image);
    setLoadData(true);
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      {is_load_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Category
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    value={name}
                    onChange={handlename}
                    label="Name"
                    name="name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="order"
                    type="number"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    label="Order"
                    name="order"
                  />
                </Grid>
                <Grid item xs={6}>
                  Status :
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                        name="checkedA"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {file ? (
                    <>
                      {" "}
                      <img className={classes.img} src={file} />{" "}
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/x-png,image/jpeg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    // directory=""
                    //   webkitdirectory=""
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      fullWidth
                      variant="outlined"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit Category
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
