import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  TextField,
  FormControl,
  Autocomplete,
  InputLabel,
  Select,
  Grid,
} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useParams } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Chip from "@material-ui/core/Chip";
import { Divider, MenuItem } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TwitterPicker } from "react-color";
import {
  get_template_detail,
  edit_Template,
  get_category_detail,
  upload_image,
  get_template_page_detail,
  edit_Template_Page,
} from "../../../DAL/templates/templates";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import {
  color_array,
  options,
  font_weight,
  line_Height,
  template_type,
  google_font_family_classes,
} from "../../../ui_libs/constants";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    fontSize: "15px",
  },
  default_Theme_btn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EditTemplatePage(props) {
  const params = useParams();

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [isuser, setusers] = React.useState(false);
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = useState([]);

  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [imagepath, setImagepath] = React.useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [mode, setMode] = useState("light");
  const [primarybackground, setPrimaryBackground] = useState("");
  const [secondarybackground, setSecondaryBackground] = useState("");
  const [primarytextcolour, setPrimaryTextColour] = useState("");
  const [secondarytextcolour, setSecondaryTextColour] = useState("");
  const [order, setOrder] = useState("");
  const [hoverTextColor, sethoverTextColor] = useState("");
  const [hoverBackgroundColor, sethoverBackgroundColor] = useState("");
  const [textFontFamily, settextFontFamily] = useState("");

  const [textH1FontFamily, settextH1FontFamily] = useState("");
  const [textH2FontFamily, settextH2FontFamily] = useState("");
  const [textH3FontFamily, settextH3FontFamily] = useState("");
  const [textH4FontFamily, settextH4FontFamily] = useState("");

  const [themeBoxShadow, setthemeBoxShadow] = useState("");
  const [themeH1FontSize, setthemeH1FontSize] = useState("");
  const [themeH2FontSize, setthemeH2FontSize] = useState("");
  const [themeH3FontSize, setthemeH3FontSize] = useState("");
  const [themeH4FontSize, setthemeH4FontSize] = useState("");
  const [themePFontSize, setthemePFontSize] = useState("");
  const [themeLineHeight, setthemeLineHeight] = useState("");
  const [themeH1FontWeight, setthemeH1FontWeight] = useState("");
  const [themeH2FontWeight, setthemeH2FontWeight] = useState("");
  const [themeH3FontWeight, setthemeH3FontWeight] = useState("");
  const [themeH4FontWeight, setthemeH4FontWeight] = useState("");
  const [themePFontWeight, setthemePFontWeight] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [state, setState] = React.useState({
    status: true,
    approved: true,
    completed: true,
    published: true,
  });

  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ template_category_id: x._id });
      } else {
        _catTag.push({ template_category_id: x.template_category_id });
      }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };
  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    console.log(category);
    let _cattemplate = [];
    // category.map((x, i) => {
    //   if (x._id) {
    //     _cattemplate.push({ category_id: x._id });
    //   } else {
    //     _cattemplate.push({ template_category_id: x.template_category_id });
    //   }
    // });
    // console.log(_cattemplate, "template id");

    setiswaiting(true);
    let postdata = {
      page_slug: name,
      // mode: mode,
      template: params.template_id,
      theme_primary_color: primarybackground,
      theme_secondary_color: secondarybackground,
      text_primary_color: primarytextcolour,
      text_secondary_color: secondarytextcolour,
      hover_text_color: hoverTextColor,
      hover_background_color: hoverBackgroundColor,
      text_font_family: textFontFamily,

      theme_h1_font_family: textH1FontFamily,
      theme_h2_font_family: textH2FontFamily,
      theme_h3_font_family: textH3FontFamily,
      theme_h4_font_family: textH4FontFamily,

      theme_box_shadow: themeBoxShadow,
      theme_h1_font_size: themeH1FontSize,
      theme_h2_font_size: themeH2FontSize,
      theme_h3_font_size: themeH3FontSize,
      theme_h4_font_size: themeH4FontSize,
      theme_p_font_size: themePFontSize,
      theme_h1_font_weight: themeH1FontWeight,
      theme_h2_font_weight: themeH2FontWeight,
      theme_h3_font_weight: themeH3FontWeight,
      theme_h4_font_weight: themeH4FontWeight,
      theme_p_font_weight: themePFontWeight,
      theme_line_height: themeLineHeight,

      // image: Image,
    };
    const country_edit_resp = await edit_Template_Page(
      postdata,
      params.pages_id
    );
    if (country_edit_resp.code == 200) {
      enqueueSnackbar(country_edit_resp.message, { variant: "success" });

      props.history.push("/templates");
    } else {
      setiswaiting(false);
      enqueueSnackbar(country_edit_resp.message, { variant: "error" });
    }
  };

  function findMatchingCssRule(css_rule, returnValue = null) {
    for (let item of google_font_family_classes) {
      if (
        css_rule.replaceAll(" ", "").replaceAll(";", "") ===
        item.css_rule.replaceAll(" ", "").replaceAll(";", "")
      ) {
        return item.css_rule;
      }
    }
    return returnValue ? returnValue : "'Montserrat',sans-serif"; // Return null if no match is found
  }

  const get_template = async () => {
    const resp = await get_template_page_detail(params.pages_id);
    if (resp.code == 200) {
      const Page = resp.Page;
      console.log(Page, "firstfirstfirstfirstfirst");
      setName(Page.page_slug);
      setType(Page.app_type);
      setState({
        ...state,
        status: Page.status,
        approved: Page.is_approved,
        completed: Page.is_completed,
        published: Page.is_published,
      });
      setOrder(Page.order);
      setMode(Page.mode);
      setPrimaryBackground(Page.theme_primary_color);
      setSecondaryBackground(Page.theme_secondary_color);
      setPrimaryTextColour(Page.text_primary_color);
      setSecondaryTextColour(Page.text_secondary_color);
      sethoverTextColor(Page.hover_text_color);
      sethoverBackgroundColor(Page.hover_background_color);

      settextFontFamily(
        findMatchingCssRule(Page.text_font_family, "'Roboto',sans-serif")
      );
      settextH1FontFamily(findMatchingCssRule(Page.theme_h1_font_family));
      settextH2FontFamily(findMatchingCssRule(Page.theme_h2_font_family));
      settextH3FontFamily(findMatchingCssRule(Page.theme_h3_font_family));
      settextH4FontFamily(findMatchingCssRule(Page.theme_h4_font_family));

      setthemeBoxShadow(Page.theme_box_shadow);
      setthemeH1FontSize(Page.theme_h1_font_size);
      setthemeH2FontSize(Page.theme_h2_font_size);
      setthemeH3FontSize(Page.theme_h3_font_size);
      setthemeH4FontSize(Page.theme_h4_font_size);
      setthemePFontSize(Page.theme_p_font_size);

      setthemeH1FontWeight(Page.theme_h1_font_weight);
      setthemeH2FontWeight(Page.theme_h2_font_weight);
      setthemeH3FontWeight(Page.theme_h3_font_weight);
      setthemeH4FontWeight(Page.theme_h4_font_weight);
      setTemplateType(Page.app_type);
      setthemePFontWeight(Page.theme_p_font_weight);
      setthemeLineHeight(Page.theme_line_height);
      // setisApproved(  is_approved);
      // setisCompleted(  is_completed);
      // setisPublished(  is_published);
      setCategory(Page.category);
      setProfileImage(s3baseUrl + Page.image);
      // setimage(Page.image);
    }
    setusers(true);
  };
  const defaultTheme = () => {
    setMode("light");
    setPrimaryBackground("#FFFFFF");
    setSecondaryBackground("#eb144c");
    setPrimaryTextColour("#000000");
    setSecondaryTextColour("#FFFFFF");
    sethoverTextColor("#FFFFFF");
    sethoverBackgroundColor("#eb144c");
    settextFontFamily("'Roboto', sans-serif");
    settextH1FontFamily("'Montserrat', sans-serif");
    settextH2FontFamily("'Montserrat', sans-serif");
    settextH3FontFamily("'Montserrat', sans-serif");
    settextH4FontFamily("'Montserrat', sans-serif");

    setthemeBoxShadow("#ABB8C3");
    setthemeH1FontSize("2.500rem");
    setthemeH2FontSize("2.000rem");
    setthemeH3FontSize("1.750rem");
    setthemeH4FontSize("1.500rem");
    setthemePFontSize("1.000rem");
    setthemeLineHeight("1.5");
    setthemeH1FontWeight("500");
    setthemeH2FontWeight("500");
    setthemeH3FontWeight("500");
    setthemeH4FontWeight("500");
    setthemePFontWeight("500");
  };
  //console.log(hoverTextColor);
  const get_templatecategory = async () => {
    const templatecategory_detail = await get_category_detail(
      props.match.params.id
    );
    console.log(templatecategory_detail.category, "category show krwaani");
    setCategorydata(templatecategory_detail.category);
  };
  useEffect(async () => {
    await get_template();
    await get_templatecategory();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }
  // console.log(mode, "Mode of the theme");
  // console.log(state, "state.status");
  // console.log(state.approved, "state.approved");
  // console.log(state.completed, "state.completed");
  // console.log(themeH1FontSize, "themeH1FontSize");
  // console.log(templateType, "template type");
  console.log(themeH4FontWeight, "h4 font weight");

  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Page
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    className={classes.txt}
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Name"
                    name="name"
                    autoFocus
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    size="small"
                    className={classes.default_Theme_btn}
                    variant="contained"
                    color="primary"
                    onClick={defaultTheme}
                  >
                    Set Default Theme Values
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <p>Primary Background</p>
                  <TwitterPicker
                    color={primarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Background</p>
                  <TwitterPicker
                    color={secondarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Text Color</p>
                  <TwitterPicker
                    color={hoverTextColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverTextColor(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Background Color</p>
                  <TwitterPicker
                    color={hoverBackgroundColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverBackgroundColor(updateColor.hex)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <p>Primary Text Colour</p>
                  <TwitterPicker
                    color={primarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Text Colour</p>
                  <TwitterPicker
                    color={secondarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>Box Shadow</p>
                  <TwitterPicker
                    color={themeBoxShadow}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setthemeBoxShadow(updateColor.hex)
                    }
                  />
                </Grid>

                <Divider style={{ width: "100%" }} />

                {/* <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="mode">Mode</InputLabel>
                    <Select
                      labelId="mode"
                      fullWidth
                      label="Mode"
                      value={mode}
                      onChange={(e) => {
                        const selectedMode = e.target.value;
                        setMode(selectedMode);
                      }}
                    >
                      <MenuItem value="dark">Dark</MenuItem>
                      <MenuItem value="light">Light</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="line_height">Line Height</InputLabel>
                    <Select
                      labelId="line_height"
                      fullWidth
                      label="line height"
                      value={themeLineHeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeLineHeight(selectedSize);
                      }}
                    >
                      {line_Height.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="font_family">Font Family</InputLabel>
                    <Select
                      labelId="font_family"
                      fullWidth
                      label="font family"
                      value={textFontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextFontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h1_font_family">H1 Font Family </InputLabel>
                    <Select
                      labelId="h1_font_family"
                      fullWidth
                      label="h1 font family "
                      value={textH1FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH1FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h2_font_family">H2 Font Family </InputLabel>
                    <Select
                      labelId="h2_font_family"
                      fullWidth
                      label="h2 font family "
                      value={textH2FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH2FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h3_font_family">H3 Font Family </InputLabel>
                    <Select
                      labelId="h3_font_family"
                      fullWidth
                      label="h3 font family "
                      value={textH3FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH3FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h4_font_family">H4 Font Family </InputLabel>
                    <Select
                      labelId="h4_font_family"
                      fullWidth
                      label="h4 font family "
                      value={textH4FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH4FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Divider style={{ width: "100%" }} />
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Size</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Size"
                      value={themeH1FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Size</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Size"
                      value={themeH2FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontSize(selectedSize);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Font Size
                      </MenuItem>
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Size</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Size"
                      value={themeH3FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontSize(selectedSize);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Font Size
                      </MenuItem>
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H4">H4 Font Size</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Size"
                      value={themeH4FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Size</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Size"
                      value={themePFontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider style={{ width: "100%" }} />

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Weight</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Weight"
                      value={themeH1FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Weight</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Weight"
                      value={themeH2FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Weight</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Weight"
                      value={themeH3FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H4">H4 Font Weight</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Weight"
                      value={themeH4FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Weight</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Weight"
                      value={themePFontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider style={{ width: "100%" }} />

                {/* <Grid item xs={12}>
                  {file ? (
                    <>
                      {" "}
                      <img className={classes.img} src={file} />{" "}
                    </>
                  ) : null}
                </Grid> */}

                {/* <Grid item xs={12}>
                  <input
                    accept="image/x-png,image/jpeg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      fullWidth
                      variant="outlined"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Update Page
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
