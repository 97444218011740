import { invokeApi } from "../../bl_libs/invokeApi";

export const get_gallery_images = async () => {
  let requestObj = {
    path: `api/images/get_stock_images_list`,
    method: "GET",
    // headers: {
    //   "x-sh-auth": localStorage.getItem("token"),
    // },
  };
  return invokeApi(requestObj);
};

export const _upload_picture_stock_images = (files) => {
  let requestObj = {
    path: "api/images/add_stock_image",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  requestObj["postData"] = files;
  return invokeApi(requestObj);
};
export const delete_picture = (id) => {
  let requestObj = {
    path: "api/images/delete_stock_image/" + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
