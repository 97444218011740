import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Chip from "@material-ui/core/Chip";
import { useSnackbar } from "notistack";
import { template_type } from "../../../ui_libs/constants";

import {
  add_section,
  get_category_detail,
  get_sectionType_detail,
  upload_image,
  upload_folder,
} from "../../../DAL/section/section";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  txt1: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddSection(props) {
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [categorydata, setCategorydata] = React.useState([]);
  const [sectiondata, setSectiondata] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [folderarr, setFolderArr] = React.useState([]);
  const [folder, setFolder] = React.useState("");
  const [_folder, set_Folder] = React.useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("0");
  const [sectiontype, setSectionType] = useState("");
  const [state, setState] = React.useState({
    checkedA: true,
    isPublished: false,
  });
  const [checked, setChecked] = React.useState(false);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ category_id: x._id });
      } else {
        _catTag.push({ category_id: x.template_category_id });
      }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };

  const handleChangeSectionType = (value) => {
    console.log(value._id, "section id ");
    setSectionType(value._id);
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "section");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp, "image upload succesfully");
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // console.log(e.target.files[0])
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };
  const folderChangedHandler = (e) => {
    console.log(Object.values(e.target.files), "guko");
    console.log(e.target.files);
    setFolderArr(Object.values(e.target.files));
    Object.values(e.target.files).map((x, i) => {
      console.log(x.name);
    });
    if (e.target.files.length) {
      set_Folder(e.target.files);
      setFolder(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    if (!Image) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    }
    setiswaiting(true);
    console.log(Image);
    console.log(category);
    console.log(sectiontype);
    let postData = {
      name: name,
      category: category,
      section_type_id: sectiontype,
      status: state.checkedA,
      is_published: state.isPublished,
      primary_background: "black",
      secondary_background: "white",
      primary_text_colour: "black",
      secondary_text_colour: "white",
      image: Image,
      app_type: "0",
    };
    const section_add_resp = await add_section(postData);
    if (section_add_resp.code == 200) {
      //  props.history.push("Templates");
      console.log(section_add_resp);
      console.log(folderarr);
      const promises = [];
      folderarr.map((file, i) => {
        if (file.name == "index.html") {
          let formData = new FormData(); //formdata object
          formData.append("project_name", section_add_resp.section._id);
          formData.append("upload_file", file);
          formData.append("content_type", file.type);

          const upload_folder_response = upload_folder(formData);
          console.log(upload_folder_response);
          promises.push(upload_folder_response);
        } else if (file.name == "style.css") {
          let formData = new FormData(); //formdata object
          formData.append(
            "project_name",
            section_add_resp.section._id + "/css"
          );
          formData.append("upload_file", file);
          formData.append("content_type", file.type);
          const upload_folder_res = upload_folder(formData);
          console.log(upload_folder_res);
          promises.push(upload_folder_res);
        } else if (file.name == "index.js") {
          console.log("index.js");
          let formData = new FormData(); //formdata object
          formData.append("project_name", section_add_resp.section._id + "/js");
          formData.append("upload_file", file);
          formData.append("content_type", file.type);
          const upload_folder_res = upload_folder(formData);
          console.log(upload_folder_res);
          promises.push(upload_folder_res);
        } else {
          let formData = new FormData(); //formdata object
          formData.append(
            "project_name",
            section_add_resp.section._id + "/assets"
          );
          formData.append("upload_file", file);
          formData.append("content_type", file.type);
          const upload_folder_resp = upload_folder(formData);
          console.log(upload_folder_resp);
          promises.push(upload_folder_resp);
        }
      });

      await Promise.all(promises)
        .then((res) => {
          if (res.length !== 0) {
            enqueueSnackbar("Folder Uploaded", { variant: "success" });
          }
          props.history.push("/section");
        })
        .catch((err) => {
          enqueueSnackbar("Folder not uploaded successfully", {
            variant: "error",
          });
        });
    } else {
      setiswaiting(false);
      enqueueSnackbar(section_add_resp.message, { variant: "error" });
    }
  };

  const get_template_category = async () => {
    const templatecategory_detail = await get_category_detail(
      props.match.params.id
    );
    console.log(templatecategory_detail.category);
    setCategorydata(templatecategory_detail.category);
  };

  const get_section_type = async () => {
    const section_type_detail = await get_sectionType_detail();
    console.log(section_type_detail.section_type, "section");
    setSectiondata(section_type_detail.section_type);
    console.log(section_type_detail.section_type, "Add section details");
  };

  useEffect(async () => {
    await get_template_category();
    await get_section_type();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }
  console.log(type, "type app_status");

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Add Section
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  className={classes.txt}
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label="Name"
                  name="name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="combo-box-demo"
                  options={sectiondata}
                  onChange={(event, value) => handleChangeSectionType(value)}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Section Type"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={categorydata}
                  //value={category}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option._id == value._id}
                  onChange={(event, value) => handleChangeCategory(value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.txt1}
                      {...params}
                      variant="outlined"
                      label="Template Categories"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.txt}
                  style={{ minWidth: 550 }}
                >
                  <InputLabel id="Type">Type</InputLabel>
                  <Select
                    labelId="Type"
                    fullWidth
                    label="Type"
                    value={type}
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      setType(selectedType);
                    }}
                  >
                    {template_type.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  // directory=""
                  //   webkitdirectory=""
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6}>
                Status :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                Is Published :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.isPublished}
                      onChange={handleChange}
                      name="isPublished"
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChecked}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Are you want to upload the folder ?"
                />
              </Grid>

              {checked == true ? (
                <>
                  <Grid item xs={12}>
                    {_folder && "Folder uploaded"}
                  </Grid>

                  <Grid item xs={12}>
                    <input
                      //  accept="image/x-png,image/jpeg"
                      hidden
                      id="contained-button-folder"
                      multiple
                      type="file"
                      directory=""
                      webkitdirectory=""
                      onChange={folderChangedHandler}
                    />
                    <label htmlFor="contained-button-folder">
                      <Button
                        component="span"
                        fullWidth
                        variant="outlined"
                        color="primary"
                      >
                        Upload Folder
                      </Button>
                    </label>
                  </Grid>
                </>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Section
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
