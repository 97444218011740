import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { useSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import { _get_active_categories } from "../../DAL/category/category_list";
import { add_customer, update_password } from "../../DAL/customer/customer";

// import "./addtemplate.css";
import Checkbox from "@material-ui/core/Checkbox";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
// import { upload_folder } from "../../../DAL/section/section";
import { TwitterPicker } from "react-color";
import { Select, MenuItem } from "@material-ui/core";
import { add_designer, upload_image } from "../../DAL/designer/designer";
import Country_Select from "./CountrySelect";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  "MuiFormControl-marginNormal": {
    marginBottom: 0,
    marginTop: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modemenu: {},
}));

export default function UpdatePassword(props) {
  console.log(props.match.params.id, " id");
  const id = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  const [category, setCategory] = useState([]);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");

  const [state, setState] = React.useState({
    status: true,
    verification_status: true,
  });

  const [inputs, setInputs] = useState({
    password: "",
  });

  const [checked, setChecked] = React.useState(false);

  //   const handleChecked = (event) => {
  //     setChecked(event.target.checked);
  //   };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
    console.log(inputs, "values");
  };
  const handleChangestatus = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
    // console.log(state.status, "checked");
  };

  const handleback = () => {
    history.goBack();
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    //console.log(Image);

    setiswaiting(true);
    let postdata = {
      password: inputs.password,
    };
    console.log(postdata, "data to be send");

    const update_password_resp = await update_password(postdata, id);
    if (update_password_resp.code == 200) {
      props.history.push("/customer");
    } else {
      setiswaiting(false);
      enqueueSnackbar(update_password_resp.message, { variant: "error" });
    }
  };

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  // console.log(state.verification_status, "check verification_status ");

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Update Password
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}

          <form
            className={classes.form}
            className="MuiFormControl-marginNormal"
            onSubmit={handlesubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  value={inputs.password}
                  onChange={handleChange}
                  label="Password"
                  name="password"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
