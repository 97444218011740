import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { useSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import { _get_active_categories } from "../../DAL/category/category_list";
import { add_customer, edit_Customer } from "../../DAL/customer/customer";

// import "./addtemplate.css";
import Checkbox from "@material-ui/core/Checkbox";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
// import { upload_folder } from "../../../DAL/section/section";
import { TwitterPicker } from "react-color";
import { Select, MenuItem } from "@material-ui/core";
import { add_designer, upload_image } from "../../DAL/designer/designer";
import Country_Select from "./CountrySelect";
import { get_customer_detail } from "../../DAL/customer/customer";
import { template_type } from "../../ui_libs/constants";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  "MuiFormControl-marginNormal": {
    marginBottom: 0,
    marginTop: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modemenu: {},
}));

export default function EditCustomer(props) {
  console.log(props.match.params.id, "ID to send for details");
  const id = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");

  const [folderarr, setFolderArr] = React.useState([]);
  const [folder, setFolder] = React.useState("");
  const [_folder, set_Folder] = React.useState("");

  const [state, setState] = React.useState({
    status: true,
    verification_status: true,
  });

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    address: "",
    contact_number: "",
    business_name: "",
    business_country: "",
    business_type: [],
    post_code: "",
    template_type: "",
  });

  const [checked, setChecked] = React.useState(false);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
    console.log(inputs, "values");
  };
  const handleChangestatus = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
    // console.log(state.status, "checked");
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // console.log(e.target.files[0])
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const handleback = () => {
    history.goBack();
  };
  const get_Category = async () => {
    const category_list_resp = await _get_active_categories();
    console.log(category_list_resp, "category list to show");
    if (category_list_resp.code == 200) {
      // setInputs({
      //     ...inputs,
      //     ["business_type"]: category_list_resp.template_category,
      //   });
      setCategory(category_list_resp.template_category);
    } else {
      enqueueSnackbar(category_list_resp.message, { variant: "error" });
    }
  };
  const get_customer = async () => {
    const customer_details = await get_customer_detail(id);

    if (customer_details.code == 200) {
      console.log(customer_details.data.customer, "customer postcode");
      const result = customer_details.data.customer;
      setInputs({
        ...inputs,
        ["first_name"]: result.first_name,
        ["last_name"]: result.last_name,
        ["last_name"]: result.last_name,
        ["business_name"]: result.business_name,
        ["business_type"]: result.business_type._id,
        ["contact_number"]: result.contact_number,
        ["post_code"]: result.post_code,
        ["email"]: result.user_id.email,
        ["business_country"]: result.business_country,
        ["template_type"]: result.type,
      });
    } else {
      console.log(customer_details, "okokokokokook");
    }
  };
  const handleCountry = (newVal) => {
    setInputs({ ...inputs, ["business_country"]: newVal });
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    //console.log(Image);

    setiswaiting(true);
    let postdata = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      contact_number: inputs.contact_number,
      email: inputs.email,
      business_name: inputs.business_name,
      business_type: inputs.business_type,
      business_country: inputs.business_country,
      post_code: inputs.post_code,
      type: inputs.template_type,
    };
    console.log(postdata, "data to be send");
    console.log(Image, "image to be send");

    const customer_edit_resp = await edit_Customer(postdata, id);
    if (customer_edit_resp.code == 200) {
      props.history.push("/customer");
    } else {
      setiswaiting(false);
      enqueueSnackbar(customer_edit_resp.message, { variant: "error" });
    }
  };
  useEffect(() => {
    get_Category();
    get_customer();
    get_customer_detail();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }
  console.log(category, "check cate");
  console.log(inputs.business_type, "check Business id");
  console.log(inputs.template_type, "check template types");
  // console.log(state.verification_status, "check verification_status ");

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Update Customer
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}

          <form
            className={classes.form}
            className="MuiFormControl-marginNormal"
            onSubmit={handlesubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  value={inputs.email}
                  onChange={handleChange}
                  label="Email"
                  name="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="first_name"
                  value={inputs.first_name}
                  onChange={handleChange}
                  label="First Name"
                  name="first_name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="last_name"
                  value={inputs.last_name}
                  onChange={handleChange}
                  label="Last Name"
                  name="last_name"
                />
              </Grid>

              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="address"
                  value={inputs.address}
                  onChange={handleChange}
                  label="Address"
                  name="address"
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="contact_number"
                  value={inputs.contact_number}
                  onChange={handleChange}
                  label="Contact Number"
                  name="contactnumber"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.txt}
                  style={{ minWidth: 550 }}
                >
                  <InputLabel id="Type">Template Type</InputLabel>
                  <Select
                    labelId="Type"
                    fullWidth
                    label="Template Type"
                    value={inputs.template_type}
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      setInputs({ ...inputs, ["template_type"]: selectedType });
                    }}
                  >
                    {template_type.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  value={inputs.password}
                  onChange={handleChange}
                  label="Password"
                  name="password"
                />
              </Grid> */}
              <Grid item xs={12}>
                <h3>Business Information</h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="business_name"
                  value={inputs.business_name}
                  onChange={handleChange}
                  label="Business Name"
                  name="business_name"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.txt}
                  style={{ minWidth: 550 }}
                >
                  <InputLabel id="Type">Business Type</InputLabel>
                  <Select
                    labelId="Type"
                    fullWidth
                    label="Business Type"
                    value={inputs.business_type}
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      setInputs({
                        ...inputs,
                        ["business_type"]: selectedType,
                      });
                    }}
                  >
                    {category.map((option, index) => {
                      return (
                        <MenuItem value={option._id}>{option.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Country_Select
                  id="business_country"
                  setter={handleCountry}
                  country={inputs.business_country}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="postcode"
                  value={inputs.post_code}
                  onChange={handleChange}
                  label="Post Code"
                  name="postcode"
                />
              </Grid>

              {/* <Grid item xs={12}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid> */}
              {/* <Grid item xs={6}>
                Status :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      id="status"
                      checked={state.status}
                      onChange={handleChangestatus}
                      name="status"
                    />
                  }
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                Verification Status :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      id="status"
                      checked={state.verification_status}
                      onChange={handleChangestatus}
                      name="verification_status"
                    />
                  }
                />
              </Grid> */}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update Customer
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
