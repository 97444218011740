import {
  Button,
  ButtonGroup,
  Card,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { get_file_listing } from "../../DAL/templates/templates";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { s3baseUrl } from "../../config/config";
import { upload_folder } from "../../DAL/section/section";
import CleanCSS from "clean-css";
const { minify } = require("terser");

export default function S3files() {
  const [selectedTab, setSelectedTab] = useState("js");
  const [fileList, setFileList] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // const handleSelectFile = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const file = e.target.files[0];
  //   if (!file) {
  //     enqueueSnackbar("No file selected.", { variant: "error" });
  //     setIsLoading(false);
  //     return;
  //   }

  //   const fileName = file.name;
  //   const fileType = fileName.split(".").pop().toLowerCase();

  //   const reader = new FileReader();
  //   reader.onload = async (event) => {
  //     let minifiedContent = null;
  //     try {
  //       const content = event.target.result;
  //       if (fileType === "js") {
  //         const result = await minify(content);

  //         if (result.error) throw result.error;
  //         minifiedContent = result.code;
  //       } else if (fileType === "css") {
  //         const result = new CleanCSS().minify(content);
  //         if (result.errors.length) throw new Error(result.errors.join("\n"));
  //         minifiedContent = result.styles;
  //       }
  //     } catch (error) {
  //       enqueueSnackbar(`Error during minification: ${error.message}`, {
  //         variant: "error",
  //       });
  //       setIsLoading(false);
  //       return;
  //     }

  //     let minifiedBlob;
  //     let minifiedFile;
  //     if (minifiedContent) {
  //       minifiedBlob = new Blob([minifiedContent], {
  //         type: "text/plain",
  //       });
  //       minifiedFile = new File([minifiedBlob], fileName, {
  //         type: "text/plain",
  //       });
  //     } else {
  //       minifiedFile = e.target.files[0];
  //     }

  //     // Create a Blob from the minified content

  //     let formData = new FormData();
  //     formData.append("project_name", "asserts-db/" + selectedTab);
  //     formData.append("upload_file", minifiedFile);
  //     formData.append("content_type", minifiedFile.type);

  //     const resp = await upload_folder(formData);
  //     if (resp.code === 200) {
  //       fetchFiles(selectedTab);
  //     } else {
  //       enqueueSnackbar(resp.message, { variant: "error" });
  //     }
  //     setIsLoading(false);
  //   };

  //   reader.readAsText(file);
  // };

  const handleSelectFile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formData = new FormData();
    formData.append("project_name", "asserts-db/" + selectedTab);
    formData.append("upload_file", e.target.files[0]);
    formData.append("content_type", e.target.files[0].type);
    const resp = await upload_folder(formData);
    if (resp.code == 200) {
      fetchFiles(selectedTab);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  const fetchFiles = async (link) => {
    setIsLoading(true);
    const postData = {
      path: "asserts-db/" + link + "/",
    };
    const resp = await get_file_listing(postData);
    if (resp.code == 200) {
      setFileList(resp.templates);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFiles("js");
    setSelectedTab("js");
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          color="primary"
        >
          <Button
            variant={selectedTab === "js" && "contained"}
            onClick={() => {
              setSelectedTab("js");
              fetchFiles("js");
            }}
          >
            Js Files
          </Button>
          <Button
            variant={selectedTab === "css" && "contained"}
            onClick={() => {
              setSelectedTab("css");
              fetchFiles("css");
            }}
          >
            CSS Files
          </Button>
          <Button
            variant={selectedTab === "html" && "contained"}
            onClick={() => {
              setSelectedTab("html");
              fetchFiles("html");
            }}
          >
            HTML Files
          </Button>
        </ButtonGroup>
        <div>
          <Button variant="contained" color="primary" component="label">
            <input
              hidden
              type="file"
              className="w-100 mt-2"
              id="edit-able-src-input-file"
              onChange={handleSelectFile}
            />
            Upload File
          </Button>
        </div>
      </div>
      <div>
        {isloading == true ? (
          <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
        ) : (
          <>
            <Card style={{ padding: "24px" }}>
              <div>
                <h2 style={{ marginTop: "0px" }}>
                  {/* {selectedTab === "js" ? "JS Files" : "CSS Files"} */}
                  {selectedTab === "js"
                    ? "JS Files"
                    : selectedTab === "css"
                    ? "CSS Files"
                    : "HTML Files"}
                </h2>
              </div>

              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{""}</TableCell>
                      <TableCell>File Name</TableCell>
                      <TableCell align="right">{""}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {fileList.length == 0 && (
                      <TableRow>
                        <h3>No data found to display</h3>
                      </TableRow>
                    )}
                    {fileList.map((row, index) => (
                      <TableRow
                        key={row.Key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {row.Key.replaceAll("asserts-db/js/", "")
                            .replaceAll("asserts-db/css/", "")
                            .replaceAll("asserts-db/html/", "")}
                        </TableCell>
                        <TableCell>
                          <Chip
                            className="ms-0 m-2"
                            sx={{
                              cursor: "pointer",
                              "& .MuiChip-label": {
                                paddingInline: "1rem",
                              },
                            }}
                            label={"Preview"}
                            onClick={() => {
                              window.open(s3baseUrl + row.Key);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        )}
      </div>
    </>
  );
}
