import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
} from "@mui/material";
import React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { s3baseUrl } from "../../../config/config";
import { useState } from "react";

export default function Image_url_replace_Modal({
  open,
  setOpen,
  data,
  updateAllUrls,
  sourceImages,
  template_id,
  setImageData,
}) {
  const [selectedImage, setSelectedImage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const [previewImage, setPreviewImage] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeImage = (e, i) => {
    setSelectedImage(e.target.value);
    let imageTempData = data;

    imageTempData[i].link_to_replace = s3baseUrl + e.target.value;

    setImageData(imageTempData);
  };
  const handleRemoveImage = (i) => {
    setSelectedImage("");
    let imageTempData = data;
    imageTempData[i].link_to_replace = "";
    setImageData([...imageTempData]);
    console.log(imageTempData, "chaaaaa");
  };
  const handleChangeImageClick = (i) => {
    setSelectedImage("");
    let imageTempData = data;
    imageTempData[i].change_image = true;
    setImageData([...imageTempData]);
    console.log(imageTempData, "chaaaaa");
  };

  const handlePreview = (e, image) => {
    setAnchorEl(e.currentTarget);
    setPreviewImage(image);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderTop: "5px solid #023373",
              overflow: "hidden",
            },
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
            borderBottom: "1px solid #023373",
          }}
        >
          <DialogTitle>
            <Stack>Change Images URL</Stack>
          </DialogTitle>
          <div
            className="icon"
            style={{ margin: "16px 24px" }}
            onClick={handleClose}
          >
            x
          </div>
        </div>
        {data.length > 0 && (
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              borderBottom: "1px solid #023373",
              paddingBlock: "8px",
            }}
          >
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Builder's URL Image
            </div>
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Source's URL Image
            </div>
          </div>
        )}
        <div
          style={{
            maxHeight: "65vh",
            minHeight: "55vh",
            overflow: "auto",
          }}
        >
          {data.length > 0 ? (
            data.map((item, i) => (
              <div
                style={{ display: "flex", borderBottom: "1px solid #023373" }}
              >
                <div
                  style={{
                    width: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                  // style={{
                  //   cursor: "pointer",
                  //   textDecoration: "underline solid #023373 1px",
                  // }}
                  >
                    {i + 1}
                  </span>
                </div>
                <div
                  style={{
                    width: "30%",
                    padding: "12px 24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid gray",
                      boxShadow:
                        "inset 0 -6em 20em rgba(64,60,67,.16), 0 2px 5px 1px rgba(64,60,67,.16)",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    onMouseEnter={(e) => handlePreview(e, item.link)}
                    onMouseLeave={() => handlePopoverClose()}
                  >
                    <img src={item.link} style={{ height: 150, width: 250 }} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "5%",
                    justifyContent: "center",
                  }}
                >
                  <TrendingFlatIcon />
                </div>
                <div
                  style={{
                    width: "30%",
                    padding: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {item.link_to_replace !== "" ? (
                    <div
                      style={{
                        border: "1px solid gray",
                        boxShadow:
                          item.link_to_replace !== "" &&
                          "inset 0 -6em 20em rgba(64,60,67,.16), 0 2px 5px 1px rgba(64,60,67,.16)",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onMouseEnter={(e) =>
                        handlePreview(e, item.link_to_replace)
                      }
                      onMouseLeave={() => handlePopoverClose()}
                    >
                      <img
                        src={item.link_to_replace}
                        style={{ height: 150, width: 250 }}
                      />
                    </div>
                  ) : (
                    <span>No Image Available</span>
                  )}
                </div>
                <div
                  style={{
                    width: "30%",
                    padding: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {item.change_image === false ? (
                    <span
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline solid #023373 1px",
                      }}
                      onClick={() => handleChangeImageClick(i)}
                    >
                      Chnage Image
                    </span>
                  ) : (
                    <div>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Choose Image
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Choose Image"
                          value={item.link_to_replace.replace(s3baseUrl, "")}
                          onChange={(e) => handleChangeImage(e, i)}
                          IconComponent={ArrowDropDown}
                          sx={{
                            width: "250px",
                          }}
                        >
                          {sourceImages.map((option) => (
                            <MenuItem key={option.Key} value={option.Key}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={s3baseUrl + option.Key}
                                  alt={option.label}
                                  style={{
                                    width: "60px",
                                    height: "40px",
                                    marginRight: "8px",
                                  }}
                                  onMouseEnter={(e) =>
                                    handlePreview(
                                      e,
                                      `${s3baseUrl}${option.Key}`
                                    )
                                  }
                                  onMouseLeave={() => handlePopoverClose()}
                                />
                                <span
                                  style={{
                                    width: 200,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "noWrap",
                                  }}
                                >
                                  {option.Key.replace(
                                    `${template_id}/assets/`,
                                    ""
                                  )}
                                </span>
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10vh",
              }}
            >
              <span>No Image Found To Replace</span>
            </div>
          )}
        </div>
        {data.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "12px 24px",
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={updateAllUrls}
            >
              Update All Images Link
            </Button>
          </div>
        )}

        <div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
              marginLeft: "5px",
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div
              style={{
                width: "40vw",
                height: "100%",
                boxShadow: "inset 0 -60em 200em rgba(64,60,67,.16)",
              }}
            >
              <img
                src={previewImage}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  border: "8px solid white",
                }}
                alt={previewImage}
              />
            </div>
          </Popover>
        </div>
      </Dialog>
    </>
  );
}
