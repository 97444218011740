import { invokeApi } from "../../bl_libs/invokeApi";

export const Change_Password = (data) => {
  let requestObj = {
    path: "api/app_api/change_password",
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};
