import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import {
  add_Sectiontype,
  get_sectioncategory_detail,
  upload_image,
} from "../../DAL/TemplateType/TemplateType";
import { useSnackbar } from "notistack";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddSectionType(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [iswaiting, setiswaiting] = useState(false);
  const [status, setStatus] = React.useState(true);
  const handlename = (event) => {
    setName(event.target.value);
  };
  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setStatus(event.target.checked);
  };

  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ category_id: x._id });
      }
      // else {
      //   _catTag.push({ category_id: x.template_category_id });
      // }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();

    console.log(category, "final category");
    if (!Image) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    } else {
      setiswaiting(true);
      let postdata = {
        name: name,
        category: category,
        status: status,
        image: Image,
      };
      const templatecategory_add_resp = await add_Sectiontype(postdata);
      console.log(templatecategory_add_resp);
      if (templatecategory_add_resp.code == 200) {
        props.history.push("/section_type");
      } else {
        setiswaiting(false);
        enqueueSnackbar(templatecategory_add_resp.message, {
          variant: "error",
        });
      }
    }

    // let formData = new FormData(); //formdata object

    // formData.append("name", name);
    // formData.append("template_category", category);
    // formData.append("status", status);
    // formData.append("image", Image);
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);

    if (upload_image_resp.code == 200) {
      setimage(upload_image_resp.file_name);
      setiswaiting(false);
    }
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  useEffect(async () => {
    const sectioncategory_detail = await get_sectioncategory_detail(
      props.match.params.id
    );
    console.log(sectioncategory_detail.category);
    setCategorydata(sectioncategory_detail.category);
    //  setLoadData(true);
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Add Section Type
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Name"
                  className={classes.txt}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="name"
                  value={name}
                  onChange={handlename}
                  name="name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={categorydata}
                  style={{ marginBottom: 5 }}
                  //value={category}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option._id == value._id}
                  onChange={(event, value) => handleChangeCategory(value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        required
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Template Categories"
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Autocomplete
              multiple
              id="tags-standard"
             // value={category}
              options={categorydata}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => handleChangeCategory(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Template Category"
                />
                )}
                />
                  </Grid> */}
              {/* <TextField
                    required
                id="outlined-multiline-static"
                label="Name"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                fullWidth
                id="name"
                value={name}
                onChange={handlename}
                name="name"
                autoFocus

              /> */}

              <Grid item xs={12}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  // directory=""
                  //   webkitdirectory=""
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    style={{ marginBottom: 5 }}
                    component="span"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6}>
                Status :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={status}
                      onChange={handleChange}
                      name="checkedA"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Section Type
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
