import { invokeApi } from "../../bl_libs/invokeApi";

export const _dashboard_counts = () => {
  let requestObj = {
    path: "api/admin/dashboard_admin",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
