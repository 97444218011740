import { Icon } from "@iconify/react";
import {
  Backdrop,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { s3baseUrl } from "../../../config/config";

function PreviewDialog({ previewDialog, setPreviewDialog, previewImage }) {
  const handleClose = () => {
    setPreviewDialog(false);
  };

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        handleClose();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
  }, []);
  return (
    <>
      {/* <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={previewDialog}
        onClose={handleClose}
      > */}
      {previewDialog && (
        <Backdrop
          open="open"
          sx={{
            backgroundColor: "#000000b0",
            zIndex: (theme) => theme.zIndex.drawer + 5,
          }}
          onClick={() => handleClose()}
        >
          <div style={{}}>
            <span
              className=""
              style={{
                position: "absolute",
                top: "2%",
                right: "2%",
                cursor: "pointer",
              }}
              onClick={() => handleClose()}
            >
              <Icon
                icon="material-symbols:close"
                style={{ fontSize: 30, color: "white" }}
              />{" "}
              {/* <CloseIcon sx={{ fontSize: 30, color: "white" }} /> */}
            </span>
            <div
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={s3baseUrl  + previewImage.file_path}
                style={{
                  maxWidth: "100%",
                  maxHeight: "90%",
                  objectFit: "cover",
                  border: "8px solid white",
                }}
                alt={previewImage.file_name}
              />
            </div>
          </div>
        </Backdrop>
      )}
      {/* </Dialog> */}
    </>
  );
}

export default PreviewDialog;
