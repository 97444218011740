import { invokeApi } from "../../bl_libs/invokeApi";

export const Category_list = async () => {
  let requestObj = {
    path: `api/category/list_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const template_search = async (search_text) => {
//     let requestObj = {
//       path: `api/template/search_template?text=${search_text}`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };

export const upload_image = async (data) => {
  let requestObj = {
    path: "api/app_api/upload_image_on_s3",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_category = async (data) => {
  let requestObj = {
    path: "api/category/add_category",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_Category = async (id) => {
  let requestObj = {
    path: `api/category/delete_category/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_category_detail = async (id) => {
  // console.log(id)
  let requestObj = {
    path: `api/category/detail_category/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_Category = async (data, id) => {
  let requestObj = {
    path: `api/category/update_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
