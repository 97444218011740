import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { Close } from "@mui/icons-material";

function DetailDialog({
  openDetailDialog,
  handleCloseDialog,
  selectedrow,
  detail,
  title,
}) {
  const name = selectedrow?.name;
  console.log(name, "templateName");
  return (
    <Dialog
      open={openDetailDialog}
      onClose={handleCloseDialog}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <div
          className="model-head"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h5">{title}</Typography>
          <Typography
            variant="h4"
            style={{ cursor: "pointer" }}
            onClick={handleCloseDialog}
          >
            <Close />
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined">
          <CardContent>
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableBody>
                  {detail?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">
                          <b>{row?.name}</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{row?.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export default DetailDialog;
