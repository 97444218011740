import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { useSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";

// import "./addtemplate.css";
import Checkbox from "@material-ui/core/Checkbox";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import { upload_folder } from "../../../DAL/section/section";
import { TwitterPicker } from "react-color";
import { Select, MenuItem } from "@material-ui/core";
import { add_designer, upload_image } from "../../../DAL/designer/designer";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  "MuiFormControl-marginNormal": {
    marginBottom: 0,
    marginTop: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modemenu: {},
}));

export default function AddDesigner(props) {
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [mode, setMode] = useState("");
  const [primarybackground, setPrimaryBackground] = useState("");
  const [secondarybackground, setSecondaryBackground] = useState("");
  const [primarytextcolour, setPrimaryTextColour] = useState("");
  const [secondarytextcolour, setSecondaryTextColour] = useState("");
  const [hoverTextColor, sethoverTextColor] = useState("");
  const [hoverBackgroundColor, sethoverBackgroundColor] = useState("");
  const [textFontFamily, settextFontFamily] = useState("");
  const [themeBoxShadow, setthemeBoxShadow] = useState("");
  const [themeH1FontSize, setthemeH1FontSize] = useState("");
  const [themeH2FontSize, setthemeH2FontSize] = useState("");
  const [themeH3FontSize, setthemeH3FontSize] = useState("");
  const [themeH4FontSize, setthemeH4FontSize] = useState("");
  const [themePFontSize, setthemePFontSize] = useState("");
  const [themeLineHeight, setthemeLineHeight] = useState("");
  const [themeH1FontWeight, setthemeH1FontWeight] = useState("");
  const [themeH2FontWeight, setthemeH2FontWeight] = useState("");
  const [themeH3FontWeight, setthemeH3FontWeight] = useState("");
  const [themeH4FontWeight, setthemeH4FontWeight] = useState("");
  const [themePFontWeight, setthemePFontWeight] = useState("");

  const [folderarr, setFolderArr] = React.useState([]);
  const [folder, setFolder] = React.useState("");
  const [_folder, set_Folder] = React.useState("");

  const [state, setState] = React.useState({
    status: true,
    verification_status: true,
  });
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    address: "",
    contact_number: "",
  });

  const [checked, setChecked] = React.useState(false);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
    console.log(inputs, "values");
  };
  const handleChangestatus = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
    // console.log(state.status, "checked");
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // console.log(e.target.files[0])
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    console.log(Image);
    if (!Image) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    } else {
      setiswaiting(true);
      let postdata = {
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        address: inputs.address,
        contact_number: inputs.contact_number,
        email: inputs.email,
        password: inputs.password,
        profile_image: Image,
        status: state.status,
      };
      console.log(postdata, "data to be send");
      console.log(Image, "image to be send");

      const designer_add_resp = await add_designer(postdata);
      if (designer_add_resp.code == 200) {
        props.history.push("/designer");
      } else {
        setiswaiting(false);
        enqueueSnackbar(designer_add_resp.message, { variant: "error" });
      }
    }
  };

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }
  // console.log(state.status, "check status");
  // console.log(state.verification_status, "check verification_status ");

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Add Designer
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}

          <form
            className={classes.form}
            className="MuiFormControl-marginNormal"
            onSubmit={handlesubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  value={inputs.email}
                  onChange={handleChange}
                  label="Email"
                  name="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  value={inputs.password}
                  onChange={handleChange}
                  label="Password"
                  name="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="first_name"
                  value={inputs.first_name}
                  onChange={handleChange}
                  label="First Name"
                  name="first_name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="last_name"
                  value={inputs.last_name}
                  onChange={handleChange}
                  label="Last Name"
                  name="last_name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="address"
                  value={inputs.address}
                  onChange={handleChange}
                  label="Address"
                  name="address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="contact_number"
                  value={inputs.contact_number}
                  onChange={handleChange}
                  label="Contact Number"
                  name="contactnumber"
                />
              </Grid>

              <Grid item xs={12}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6}>
                Status :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      id="status"
                      checked={state.status}
                      onChange={handleChangestatus}
                      name="status"
                    />
                  }
                />
              </Grid>
              {/* <Grid item xs={6}>
                Verification Status :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      id="status"
                      checked={state.verification_status}
                      onChange={handleChangestatus}
                      name="verification_status"
                    />
                  }
                />
              </Grid> */}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Designer
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
