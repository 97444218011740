import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_active_categories = (data) => {
  let requestObj = {
    path: "/api/category/list_category",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
