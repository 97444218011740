import React from "react";
import { useEffect } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArticleIcon from "@mui/icons-material/Article";
// import CategoryIcon from '@mui/icons-material/Category';
import PostAddIcon from "@mui/icons-material/PostAdd";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CollectionsIcon from '@mui/icons-material/Collections';
import DashboardIcon from "@material-ui/icons/Dashboard";
import { withRouter } from "react-router";
import SettingsIcon from "@material-ui/icons/Settings";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CategoryIcon from "@material-ui/icons/Category";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import LanguageIcon from "@material-ui/icons/Language";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BuildIcon from "@material-ui/icons/Build";
import { color_palette } from "../../../../theme/theme";
import LayersIcon from "@mui/icons-material/Layers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
function SideMenu(props) {
  const { selectedIndex, handleListItemClick } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openquotes, setOpenquotes] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickquotes = () => {
    setOpenquotes(!openquotes);
  };
  useEffect(() => {
    const currentPath = props.history.location.pathname;
    if (currentPath.search("dashboard") > 0) {
      handleListItemClick("dashboard");
    } else if (currentPath.search("customer") > 0) {
      handleListItemClick("customer");
    } else if (currentPath.search("templates") > 0) {
      handleListItemClick("templates");
    } else if (currentPath.search("categories") > 0) {
      handleListItemClick("categories");
    } else if (currentPath.search("section_type") > 0) {
      handleListItemClick("section_type");
    } else if (currentPath.search("setting") > 0) {
      handleListItemClick("setting");
    } else if (currentPath.search("section") > 0) {
      handleListItemClick("section");
    } else if (currentPath.search("project") > 0) {
      handleListItemClick("project");
    } else if (currentPath.search("designer") > 0) {
      handleListItemClick("designer");
    } else if (currentPath.search("s3files") > 0) {
      handleListItemClick("s3files");
    } else if (currentPath.search("template-group") > 0) {
      handleListItemClick("template-group");
    } else if (currentPath.search("templateCategories") > 0) {
      handleListItemClick("templateCategories");
    } else if (currentPath.search("stock_images") > 0) {
      handleListItemClick("stock_images");
    } else {
      handleListItemClick("");
    }
  }, [selectedIndex, props.history.location.pathname]);
  return (
    <>
      <List component="nav" aria-label="main mailbox folders">
        {/* ------------------------------------------------------ Dashboard */}
        <ListItem
          style={
            selectedIndex === "dashboard"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "dashboard"}
          onClick={(event) => {
            handleListItemClick("dashboard");
            props.history.push("/dashboard");
          }}
          button
        >
          <ListItemIcon>
            <DashboardIcon
              style={
                selectedIndex === "dashboard"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        {/* ------------------------------------------------------ Customer */}
        {/* <ListItem
          style={
            selectedIndex === "customer"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "customer"}
          onClick={(event) => {
            handleListItemClick("customer");
            props.history.push("/customer");
          }}
          button
        >
          <ListItemIcon>
            <AccountBoxIcon
              style={
                selectedIndex === "customer"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Customer" />
        </ListItem> */}
        {/* --------------------------------Designer--------------------- */}
        {/* <ListItem
          style={
            selectedIndex === "designer"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "designer"}
          onClick={(event) => {
            handleListItemClick("designer");
            props.history.push("/designer");
          }}
          button
        >
          <ListItemIcon>
            <SettingsIcon
              style={
                selectedIndex === "designer"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Designer" />
        </ListItem> */}

        {/* ------------------------------------------------------ Category */}
        <ListItem
          style={
            selectedIndex === "categories"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "categories"}
          onClick={(event) => {
            handleListItemClick("categories");
            props.history.push("/categories");
          }}
          button
        >
          <ListItemIcon>
            <CategoryIcon
              style={
                selectedIndex === "categories"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>



        
        {/* --------------------------------Project--------------------- */}
        {/* <ListItem
          style={
            selectedIndex === "project"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "project"}
          onClick={(event) => {
            handleListItemClick("project");
            props.history.push("/project");
          }}
          button
        >
          <ListItemIcon>
            <SettingsIcon
              style={
                selectedIndex === "project"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Project" />
        </ListItem> */}
        {/* ------------------------------------------------------ Templates */}
        <ListItem
          style={
            selectedIndex === "templates"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "templates"}
          onClick={(event) => {
            handleListItemClick("templates");
            props.history.push("/templates");
          }}
          button
        >
          <ListItemIcon>
            <PostAddIcon
              style={
                selectedIndex === "templates"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Templates" />
        </ListItem>
        {/* ------------------------------------------------------ Templates Group */}
        <ListItem
          style={
            selectedIndex === "template-group"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "template-group"}
          onClick={(event) => {
            handleListItemClick("template-group");
            props.history.push("/template-group");
          }}
          button
        >
          <ListItemIcon>
            <LayersIcon
              style={
                selectedIndex === "template-group"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Template Group" />
        </ListItem>
        {/* ------------------------------------------------------ Template Categories */}
        <ListItem
          style={
            selectedIndex === "templateCategories"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "templateCategories"}
          onClick={(event) => {
            handleListItemClick("templateCategories");
            props.history.push("/templateCategories");
          }}
          button
        >
          <ListItemIcon>
            <CategoryIcon
              style={
                selectedIndex === "templateCategories"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Template Categories" />
        </ListItem>



        {/* ------------------------------------------------------ Section */}
        <ListItem
          style={
            selectedIndex === "section"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "section"}
          onClick={(event) => {
            handleListItemClick("section");
            props.history.push("/section");
          }}
          button
        >
          <ListItemIcon>
            <ArticleIcon
              style={
                selectedIndex === "section"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Section" />
        </ListItem>


        
        {/* ------------------------------------------------------ Section Type */}
        <ListItem
          style={
            selectedIndex === "section_type"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "section_type"}
          onClick={(event) => {
            handleListItemClick("section_type");
            props.history.push("/section_type");
          }}
          button
        >
          <ListItemIcon>
            <AccountBoxIcon
              style={
                selectedIndex === "section_type"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Section Type" />
        </ListItem>

        {/* ------------------------------------------------------ S3 Upload Files */}
        <ListItem
          style={
            selectedIndex === "s3files"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "s3files"}
          onClick={(event) => {
            handleListItemClick("s3files");
            props.history.push("/s3files");
          }}
          button
        >
          <ListItemIcon>
            <FilePresentIcon
              style={
                selectedIndex === "s3files"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="S3 Files" />
        </ListItem>
        {/* ------------------------------------------------------ Stock Images */}
        <ListItem
          style={
            selectedIndex === "stock_images"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "stock_images"}
          onClick={(event) => {
            handleListItemClick("stock_images");
            props.history.push("/stock_images");
          }}
          button
        >
          <ListItemIcon>
            <CollectionsIcon
              style={
                selectedIndex === "stock_images"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Stock Images" />
        </ListItem>

        {/* ------------------------------------------------------ Setting  */}
        <ListItem
          style={
            selectedIndex === "setting"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "setting"}
          onClick={(event) => {
            handleListItemClick("setting");
            props.history.push("/setting");
          }}
          button
        >
          <ListItemIcon>
            <SettingsIcon
              style={
                selectedIndex === "setting"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </>
  );
}

export default withRouter(SideMenu);
