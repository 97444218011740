/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  Select,
} from "@material-ui/core";
import { color_palette } from "../../theme/theme";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import { Editor } from "../../components";
import { AddSettings, GetSettings } from "../../DAL/settings/settings";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: color_palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  txt: {
    color: "black",
  },
  alert: {
    width: "100%",
  },
  swtch: {
    marginTop: "20px",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Settings(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    support_email: "",
    terms_and_conditions: "",
    privacy_policy: "",
  });
  const [isDisable, setIsDisable] = useState(false);
  const [waiting, setWaiting] = useState(true);

  const handleChangeInCkEdditor = (key, value) => {
    setInputs({
      ...inputs,
      [key]: value,
    });
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(inputs);
    if (isDisable === false) {
      setWaiting(true);
      UpdateSettings(e);
    } else {
      setIsDisable(false);
    }
  };

  const UpdateSettings = async (event) => {
    event.preventDefault();
    const postData = {
      support_email: inputs.support_email,
      terms_and_conditions: inputs.terms_and_conditions,
      privacy_policy: inputs.privacy_policy,
    };
    const result = await AddSettings(postData);
    if (result.code === 200) {
      //  setIsDisable(true);
      setWaiting(false);
    } else {
      setWaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };

  const FetchSettings = async () => {
    const result = await GetSettings();
    if (result.code === 200) {
      const obj = {
        support_email: result.setting.support_email,
        privacy_policy: result.setting.privacy_policy,
        terms_and_conditions: result.setting.terms_and_conditions,
      };
      setInputs(obj);
      setWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    FetchSettings();
  }, []);

  if (waiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SettingsIcon />
          </Avatar>
          <Typography className={classes.txt} component="h1" variant="h5">
            Settings
          </Typography>

          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  type="email"
                  fullWidth
                  id="support_email"
                  value={inputs.support_email}
                  onChange={handleChange}
                  disabled={isDisable}
                  label="Support email"
                  name="support_email"
                  autoComplete="off"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary">
                  Terms and conditions:
                </Typography>
                <Editor
                  content={inputs.terms_and_conditions}
                  setContent={(value) => {
                    handleChangeInCkEdditor("terms_and_conditions", value);
                  }}
                  mode={isDisable}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary">Privacy Policy :</Typography>
                <Editor
                  content={inputs.privacy_policy}
                  setContent={(value) => {
                    handleChangeInCkEdditor("privacy_policy", value);
                  }}
                  mode={isDisable}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {isDisable ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                  startIcon={<EditIcon />}
                  fullWidth
                >
                  Edit settings
                </Button>
              ) : (
                <Button
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update Settings
                </Button>
              )}
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
export default withRouter(Settings);
