import {
  Autocomplete,
  Chip,
  // Button,
  // CircularProgress,
  Container,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  // Switch,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Templates_list,
  _add_template_group,
  _details_template_group,
  _edit_template_group,
  get_category_detail,
} from "../../DAL/templates/templates";
import PageTemplateModal from "./PageTemplateModal";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import { is_Paid } from "../../ui_libs/constants";
import CustomConfirmation from "../../components/CustomConfirmation";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  default_Theme_btn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "MuiFormControl-marginNormal": {
    marginBottom: 0,
    marginTop: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modemenu: {},
}));

export default function AddTemplateGroup(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [componentFor, setComponentFor] = useState("Add");
  const [name, setName] = useState("");
  const [isPublished, setIsPublished] = useState(true);
  const [pageTemplateModal, setPageTemplateModal] = useState(false);
  const [templateToAddList, setTemplateToAddList] = useState([]);
  const [pageGroupType, setPageGroupType] = useState("funnel");
  const [targetIndex, setTargetIndex] = useState(null);
  const [targetIndexToRemovePage, setTargetIndexToRemovePage] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [discount, setDiscount] = useState();
  const [ribbon, setRibbon] = useState("");
  const [discountError, setDiscountError] = useState(false);
  const [categorydata, setCategorydata] = React.useState([]); //ok
  const [category, setCategory] = useState([]);

  const [openCustomConfirmation, setOpenCustomConfirmation] = useState(false);

  const handleback = () => {
    history.goBack();
  };

  const getTemplateList = async () => {
    const template_list_resp = await Templates_list();
    if (template_list_resp.code == 200) {
      let list = [];
      template_list_resp.templates.map((template) => {
        if (template.status) {
          list.unshift(template);
        }
      });

      console.log(list, "DATA");
      setData(list);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      template_array: templateToAddList,
      name: name,
      category: category,
      is_published: isPublished,
      group_type: pageGroupType,
      is_paid: isPaid,
      amount: amount,
      currency: currency,
      discount: discount,
      ribbon: ribbon,
    };

    console.log(postData, "postDataoftemplategroup");
    let resp = null;
    if (componentFor === "Add") {
      resp = await _add_template_group(postData);
    } else {
      resp = await _edit_template_group(props.match.params.group_id, postData);
    }

    if (resp.code === 200) {
      enqueueSnackbar(resp.message, { variant: "success" });
      handleback();
      console.log(resp, "resultoftemplategroup");
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setIsLoading(false);
    console.log(resp, "resultoftemplategroup");
  };

  const handlePageClick = (templateToAdd) => {
    let list = [...templateToAddList];
    const obj = {
      template: templateToAdd._id,
      name: templateToAdd.name,
      image: templateToAdd.image,
    };
    if (targetIndex !== null) {
      list.splice(targetIndex, 1, obj);
    } else {
      list.push(obj);
    }

    setTemplateToAddList([...list]);
    setPageTemplateModal(false);
    setTargetIndex(null);
  };
  const handleConfirmRemovePage = (index) => {
    setOpenCustomConfirmation(true);
    setTargetIndexToRemovePage(index);
  };
  const removePage = () => {
    let list = [...templateToAddList];
    list.splice(targetIndexToRemovePage, 1);
    setTemplateToAddList([...list]);
    setOpenCustomConfirmation(false);
  };

  const handlePageUp = (index) => {
    let newArray = [...templateToAddList];
    let tempPage = templateToAddList[index - 1];

    newArray[index - 1] = newArray[index];
    newArray[index] = tempPage;

    setTemplateToAddList([...newArray]);
  };

  const handlePageDown = (index) => {
    let newArray = [...templateToAddList];
    let tempPage = templateToAddList[index + 1];

    newArray[index + 1] = newArray[index];
    newArray[index] = tempPage;

    setTemplateToAddList([...newArray]);
  };

  const checkIsEditTemplate = async () => {
    if (props.match.params.group_id) {
      setIsLoading(true);
      let resp = await _details_template_group(props.match.params.group_id);
      if (resp.code === 200) {
        console.log(resp, "edittemplateGroup");
        setName(resp.template.name);
        setTemplateToAddList(resp.template.template_array);
        setIsPublished(resp.template.is_published);
        setPageGroupType(resp.template.group_type);
        setIsPaid(resp.template.is_paid);
        setAmount(resp.template.amount);
        setCurrency(resp.template.currency);
        setDiscount(resp.template.discount);
        setRibbon(resp.template.ribbon);
        setCategory(resp.template.category)
        setIsLoading(false);
        setComponentFor("Edit");
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
      setIsLoading(false);
    }
  };

  const handlePageName = (value, i) => {
    let list = [...templateToAddList];
    list[i].name = value;
    console.log(list);
    setTemplateToAddList([...list]);
  };

  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ category_id: x._id });
      } else {
        _catTag.push({ category_id: x.template_category_id });
      }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };

  useEffect(async () => {
    getTemplateList();
    checkIsEditTemplate();
    const category_detail = await get_category_detail();
    setCategorydata(category_detail.category);
  }, []);

  if (isloading) {
    return <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />;
  }

  return (
    <>
      <PageTemplateModal
        pageTemplateModal={pageTemplateModal}
        setPageTemplateModal={setPageTemplateModal}
        templatesList={data}
        handlePageClick={handlePageClick}
      />

      <CustomConfirmation
        open={openCustomConfirmation}
        setOpen={setOpenCustomConfirmation}
        handleAgree={removePage}
        title={`Are you sure about removing this template from the group?`}
      />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            {componentFor} Template Group
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                minHeight: "75vh",
              }}
            >
              <div>
                {" "}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    className={classes.txt}
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Name"
                    name="name"
                    autoFocus
                    sx={{ marginBlock: "24px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.txt}
                    fullWidth
                    sx={{ marginBlockEnd: "12px" }}
                  >
                    <InputLabel id="Template-Group-Type">
                      Template Group Type
                    </InputLabel>
                    <Select
                      labelId="Template-Group-Type"
                      fullWidth
                      label="Template Group Type"
                      value={pageGroupType}
                      onChange={(e) => {
                        setPageGroupType(e.target.value);
                      }}
                    >
                      <MenuItem value="funnel">Funnel</MenuItem>
                      <MenuItem value="book_a_call">Book A Call</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={categorydata}
                    // value={category}
                    defaultValue={category}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option._id == value._id
                    }
                    onChange={(event, value) => handleChangeCategory(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Category"
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    // className={classes.txt}
                    fullWidth
                    sx={{ mb: 2, mt: 2 }}
                  >
                    <InputLabel id="Type">Is Paid</InputLabel>
                    <Select
                      labelId="Type"
                      fullWidth
                      label="Is Paid"
                      value={isPaid}
                      onChange={(e) => {
                        const is_paid = e.target.value;
                        setIsPaid(is_paid);
                      }}
                    >
                      {is_Paid.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                    <TextField
                      fullWidth
                      label="Amount"
                      name="amount"
                      value={amount}
                      onChange={(e) => {
                        const is_paid = e.target.value;
                        setAmount(is_paid);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                    <TextField
                      fullWidth
                      label="Currency"
                      name="currency"
                      value={currency}
                      onChange={(e) => {
                        const is_paid = e.target.value;
                        setCurrency(is_paid);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid> */}
                <Divider
                  style={{
                    width: "100%",
                    marginTop: "13px",
                    marginBottom: "13px",
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  item
                  xs={12}
                  style={{ marginBottom: "15px" }}
                >
                  <Grid item xs={4} lg={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="Type">Is Paid</InputLabel>
                      <Select
                        labelId="Type"
                        // fullWidth
                        label="Is Paid"
                        value={isPaid}
                        onChange={(e) => {
                          const is_paid = e.target.value;
                          setIsPaid(is_paid);
                        }}
                      >
                        {is_Paid.map((option, index) => {
                          return (
                            <MenuItem value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        fullWidth
                        required
                        label="Currency"
                        name="currency"
                        disabled={isPaid === false}
                        value={isPaid === true ? currency : ""}
                        // type="number"
                        onChange={(e) => {
                          const is_paid = e.target.value;
                          setCurrency(is_paid);
                        }}
                        // InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        fullWidth
                        required
                        disabled={isPaid === false}
                        label="Amount"
                        name="amount"
                        value={isPaid === true ? amount : ""}
                        onChange={(e) => {
                          const is_paid = e.target.value;
                          setAmount(is_paid);
                        }}
                        // InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        fullWidth
                        label="Discount"
                        name="discount"
                        disabled={isPaid === false}
                        value={isPaid === true ? discount : ""}
                        error={discountError}
                        helperText={
                          discountError
                            ? `Discount cannot be greater than amount`
                            : ""
                        }
                        onChange={(e) => {
                          const newDiscount = parseFloat(e.target.value);
                          if (!isNaN(newDiscount) && newDiscount < amount) {
                            setDiscount(newDiscount);
                            setDiscountError(false);
                          } else if (newDiscount > amount) {
                            setDiscountError(true);
                            // setDiscount("");
                          } else if (newDiscount < amount) {
                            setDiscountError(false);
                            setDiscount("");
                          } else {
                            setDiscountError(false);
                            setDiscount("");
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Ribbon"
                        placeholder="You can enter only 15 characters"
                        name="ribbon"
                        value={isPaid === true ? ribbon : ""}
                        disabled={isPaid === false}
                        onChange={(e) => {
                          const inputValue = e.target.value.slice(0, 15);
                          setRibbon(inputValue);
                        }}
                        inputProps={{ maxLength: 15 }}
                        // disabled={ribbon.length === 14}
                        // InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider style={{ width: "100%", marginTop: "13px" }} />
                <Grid item xs={6} sx={{ marginBottom: "24px" }}>
                  Published :
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={isPublished}
                        onChange={() => setIsPublished(!isPublished)}
                        name="published"
                      />
                    }
                  />
                </Grid>
                {templateToAddList.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {templateToAddList.map((page, index) => (
                      <div
                        style={{
                          backgroundColor: "#fff",
                          height: "166px",
                          width: "100%",
                          borderRadius: "8px",
                          boxShadow: "rgba(64,60,67,0.16) 0 1px 5px 1px",
                          marginBottom: "16px",
                          position: "relative",
                          padding: "8px",
                          display: "flex",
                        }}
                      >
                        <CloseIcon
                          sx={{
                            position: "absolute",
                            top: "2%",
                            right: "1%",
                            cursor: "pointer",
                          }}
                          fontSize="small"
                          onClick={() => handleConfirmRemovePage(index)}
                        />

                        <div
                          style={{
                            height: "150px",
                            width: "50%",
                            boxShadow: "none",
                          }}
                        >
                          <img
                            src={s3baseUrl + page.image}
                            alt=""
                            width="100%"
                            height="150px"
                            className="create-project-image"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <div style={{ width: "100%", paddingInline: "16px" }}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="page_name"
                              value={page.name}
                              onChange={(e) =>
                                handlePageName(e.target.value, index)
                              }
                              label="Page Name"
                              name="page_name"
                              size="small"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              border: "1px solid gray",
                              borderRadius: "30px",
                              padding: "2px 16px",
                            }}
                          >
                            <ChangeCircleIcon
                              fontSize="small"
                              sx={{ marginInlineEnd: "8px" }}
                              onClick={() => {
                                setTargetIndex(index);
                                setPageTemplateModal(true);
                              }}
                            />
                            <RemoveRedEyeIcon
                              fontSize="small"
                              sx={{ marginInlineEnd: "8px" }}
                              onClick={() => {
                                window.open(
                                  s3baseUrl + page.template + "/" + "index.html"
                                );
                              }}
                            />
                            <ArrowUpwardIcon
                              fontSize="small"
                              sx={{
                                marginInlineEnd: "8px",
                                pointerEvents: index === 0 && "none",
                                opacity: index === 0 && "0.3",
                              }}
                              onClick={() => handlePageUp(index)}
                              style={{}}
                            />
                            <ArrowDownwardIcon
                              fontSize="small"
                              onClick={() => handlePageDown(index)}
                              sx={{
                                pointerEvents:
                                  index === templateToAddList.length - 1 &&
                                  "none",
                                opacity:
                                  index === templateToAddList.length - 1 &&
                                  "0.3",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      height: "85px",
                      width: "300px",
                      borderRadius: "8px",
                      boxShadow: "rgba(64,60,67,0.16) 0 1px 5px 1px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "24px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => setPageTemplateModal(true)}
                    >
                      Add Page
                    </Button>
                  </div>
                </Grid>
              </div>
              <div>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    {componentFor === "Edit" ? "Update" : "Add"} Template Group
                  </Button>
                </Grid>
              </div>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
