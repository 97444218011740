import { CircularProgress, Tooltip } from "@mui/material";
import React, { useMemo, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import { get_readable_storage_size, get_short_string } from "../../utils/utils";
import { _upload_picture_stock_images } from "../../../DAL/Gallery/gallery_api";
import { Button } from "@material-ui/core";

const baseStyle = {
  display: "flex",
  height: "60vh",
  margin: "55px 25px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#000000",
  borderStyle: "dashed",
  backgroundColor: "#f4f7ff",
  color: "#162D3D",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  marginBottom: 55,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  marginBottom: 15,
  // marginRight: 8,
  width: 282,
  height: 200,

  padding: 4,
  boxSizing: "border-box",
};
const thumbChild = {
  display: "flex",
  justifyContent: "space-between",
  borderRadius: 2,
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #eaeaea",
  height: "100%",
  width: "100%",
  position: "relative",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  width: "100%",
  height: "100%",
  justifyContent: "center",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function UploadDropZone({
  setUploadDialog,
  setUserImagesList,
  setSearchImageList,
  userImagesList,
  searchImageList,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [fileDropped, setFileDroped] = useState(false);
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open,
  } = useDropzone({
    // multiple images
    // multiple: false,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    },
    type: "file",
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      let images = [];
      images = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFileDroped(!fileDropped);
      setFiles((previousData) => [...previousData, ...images]);
    },
  });

  const handleRemoveImage = async (img) => {
    let list = files.filter((images) => {
      if (images !== img) return images;
    });
    setFiles([...list]);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const thumbs = files.map((file) => (
    <div style={thumb}>
      <div style={thumbChild} key={file.name}>
        <div style={thumbInner} className="border-bottom">
          <img src={file.preview} style={img} />
        </div>
        <div className="image-name-size w-100 m-0 mt-1">
          <div>
            <span>{get_short_string(file.path, 25)}</span>
          </div>
          <div>
            <span> {get_readable_storage_size(file.size)}</span>
          </div>
        </div>
        <div style={{ position: "absolute", top: "1%", right: "1%" }}>
          <Tooltip title="Remove Image" placement="top" arrow>
            <div
              className="image-icon icon-delete"
              onClick={() => handleRemoveImage(file)}
            >
              <Icon icon="system-uicons:cross" />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks

      // files.forEach((file) => URL.revokeObjectURL(file.preview));
      setShow(false);
    },
    [fileDropped]
  );

  const filepath = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const handleUploadImages = async () => {
    if (files.length == 0) return;
    setIsLoading(true);
    let formData = new FormData();
    let fileSize = 0;
    for (let i = 0; i < files.length; i++) {
      formData.append("upload_file", files[i]);
      fileSize += files[i].size;
    }
    // if (files.length > 1 && fileSize > planDetail.assert_size) {
    //   enqueueSnackbar(
    //     "Your space is full. Please upgrade your plan or delete some images or upload images 1 by one",
    //     { variant: "error" }
    //   );

    //   setIsLoading(false);
    //   return;
    // } else if (files.length == 1 && fileSize > planDetail.assert_size) {
    //   enqueueSnackbar(
    //     "Your space is full. Please upgrade your plan or delete some images",
    //     {
    //       variant: "error",
    //     }
    //   );

    //   setIsLoading(false);
    //   return;
    // }
    const resp = await _upload_picture_stock_images(formData);
    console.log(resp, "imagerespresp");
    if (resp.code == 200) {
      // // update List here
      let images_list = [...resp.gallery].reverse();
      setUserImagesList([...images_list, ...userImagesList]);
      setSearchImageList([...images_list, ...searchImageList]);
      setIsLoading(false);
      setUploadDialog(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      {(show || files.length === 0) && (
        <>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />

            <div
              style={{
                textAlignLast: "center",
              }}
            >
              {" "}
              <p>Drag 'n' drop images here</p>
              <p>or</p>
            </div>

            <Button
              color="primary"
              variant="contained"
              component="label"
              style={{
                borderRadius: "30px",
                padding: "3px 23px",
              }}
              onClick={open}
            >
              <Icon
                icon="material-symbols:add"
                style={{ fontSize: "1.25rem" }}
              />

              <span
                style={{
                  marginLeft: "0.5rem",
                  fontSize: "16px",
                  fontWeight: "lighter",
                }}
              >
                Upload from computer
              </span>
            </Button>
          </div>
          {files.length !== 0 && (
            <div
              className="border-top"
              style={{
                position: "absolute",
                bottom: "0",
                display: "flex",
                background: "white",
                height: "50px",
                justifyContent: "space-between",
                alignItems: "center",
                width: "97%",
                overflow: "hidden",
              }}
            >
              <div className="">
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  component="label"
                  style={{
                    margin: "8px",
                    borderRadius: "30px",
                    padding: "0px 23px",
                  }}
                  onClick={() => setShow(!show)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {!show && files.length !== 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <aside style={thumbsContainer}>{thumbs}</aside>

          <div
            className="border-top"
            style={{
              position: "absolute",
              bottom: "0",
              display: "flex",
              background: "white",
              height: "50px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "97%",
              overflow: "hidden",
            }}
          >
            <div className="">
              <Button
                disabled={isLoading}
                variant="outlined"
                component="label"
                style={{
                  margin: "8px",
                  borderRadius: "30px",
                  padding: "0px 23px",
                }}
                onClick={() => setShow(true)}
              >
                Add More Images
              </Button>
            </div>
            {isLoading ? (
              <CircularProgress size="1.2rem" sx={{ marginRight: "2rem" }} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="label"
                style={{
                  margin: "8px",
                  borderRadius: "30px",
                  padding: "0px 23px",
                }}
                onClick={handleUploadImages}
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// ReactDOM.render(<UploadFiles />, document.getElementById("root"));

export default UploadDropZone;
