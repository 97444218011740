import React from "react";

export default function Headers() {
  return (
    <div className="main-bg">
      <header>
        <div className="container-fluid">
          {/* <div className="logo">Header</div> */}
        </div>
      </header>
    </div>
  );
}
