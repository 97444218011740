import { invokeApi } from "../../bl_libs/invokeApi";

export const Customer_list = async (page, limit) => {
  let requestObj = {
    path: `api/customer/get_customer?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
// export const get_customer_detail = async (id) => {
//   let requestObj = {
//     path: `api/customer/get_customer/${id}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
export const get_customer_detail = async (id) => {
  console.log(id, "id from the api");
  let requestObj = {
    path: `api/customer/customer_details_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Customer_search = async (page, limit, search_text) => {
  let requestObj = {
    path: `api/customer/search_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_customer = async (data) => {
  let requestObj = {
    path: `api/customer/add_customer_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_customer = async (id) => {
  let requestObj = {
    path: `api/customer/delete_customer_for_admin/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_Customer = async (data, id) => {
  let requestObj = {
    path: `api/customer/edit_customer_for_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_password = async (data, id) => {
  let requestObj = {
    path: `api/customer/update_customer_password_for_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
