/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import { TextField } from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { color_palette } from "../../theme/theme";
import {
  sectionType_list,
  delete_SectionType,
} from "../../DAL/TemplateType/TemplateType";
import { default_image } from "../../assets";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

function SectionType(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(false);
  const [isdata, setisdata] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const [page, setPage] = React.useState(0);
  // const [totalPage, settotalpage] = React.useState();
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}>Name</span>,
      width: 150,
      field: "name",
    },
    {
      title: <span className={classes.hed}>Image</span>,
      width: 150,
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            <img
              src={
                rowData.image
                  ? s3baseUrl + rowData.image
                  : s3baseUrl + default_image
              }
              style={{ height: 50, width: 50 }}
            />
          </div>
        </>
      ),
    },
    {
      title: <span className={classes.hed}>Order</span>,
      width: 150,
      field: "order",
    },
    {
      title: <span className={classes.hed}>Status</span>,

      render: (rowData) =>
        rowData.status ? (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked
                style={{ color: color_palette.primary, marginLeft: -15 }}
              />
              Active
            </div>
          </>
        ) : (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked={true}
                disabled
                color={color_palette.primary}
                style={{ marginLeft: -15 }}
              />
              Inactive
            </div>
          </>
        ),
      sorting: false,
    },
    {
      title: <span className={{ float: "right" }}>Actions</span>,
      render: (rowData) => (
        <div className={classes.mnu}>
          <Menu
            key="left"
            direction="right"
            align="center"
            position="anchor"
            viewScroll="auto"
            menuButton={
              <MoreVertIcon style={{ marginLeft: 20 }}></MoreVertIcon>
            }
          >
            <MenuItem
              onClick={() =>
                props.history.push({
                  pathname: `/section_type/editsectiontype/${rowData._id}`,
                  user: rowData,
                })
              }
              className={classes.mnu}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => handleClickOpen(rowData)}
              className={classes.mnu}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ]);

  const hanldeSearchChange = async (e) => {
    // setSearch(e.target.value);
    // setData([]);
    // setisdata(false);
    // const data_search = await country_search(e.target.value);
    // console.log(data_search, "DATA SEARCH");
    // if (data_search.code == 200) {
    //   if (data_search.data.country.length != 0) {
    //     setData(data_search.data.country);
    //     settotalpage(data_search.data.total_pages);
    //     setPage(0)
    //   }
    //   setisdata(true);
    // }
  };
  const Addsectiontype = () => {
    props.history.push("/section_type/add_sectiontype");
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deletetemplate = async () => {
    setOpen(false);
    setData([]);
    setisdata(false);
    console.log(selectedrow._id);
    const delete_template_resp = await delete_SectionType(selectedrow._id);
    if (delete_template_resp.code == 200) {
      setisdata(false);
      await get_templatetype();
    } else {
      setisdata(false);
      enqueueSnackbar(delete_template_resp.message, { variant: "error" });
      handleClose();
    }
  };
  const get_templatetype = async () => {
    const template_list_resp = await sectionType_list();
    console.log(template_list_resp.section_type, "DATA");
    if (template_list_resp.code == 200) {
      setData(template_list_resp.section_type);
      // settotalpage(template_list_resp.data.total_pages);
      setisdata(true);
      setusers(true);
    } else {
      enqueueSnackbar(template_list_resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_templatetype();
  }, []);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <Button
            onClick={Addsectiontype}
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Add Section Type
          </Button>
          <MaterialTable
            tableRef={tableRef}
            style={{
              marginTop: "50px",
              borderRadius: "12px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              padding: "5px",
            }}
            title="Section Type"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata == false ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            // actions={[
            //   {
            //     icon: () => <EditIcon />,
            //     tooltip: "Edit Type",
            //     iconProps: { style: { marginLeft:"30px" } },
            //     onClick: (event, rowData) =>
            //       props.history.push({
            //         pathname: `/sectin_type/editsectiontype/${rowData._id}`,
            //         user: rowData,
            //       }),
            //   },
            //   {
            //     icon: () => <DeleteIcon />,
            //     tooltip: "Delete Type",
            //     onClick: (event, rowData) => handleClickOpen(rowData),
            //   },
            // ]}

            options={{
              actionsColumnIndex: -1,
              search: true,
              searchFieldAlignment: "right",
              // searchFieldStyle: { marginRight: "-100px" },
              searchFieldVariant: "standard",
              pageSize: 25,
              pageSizeOptions: [25, 50, 100, 200],
              emptyRowsWhenPaging: false,
              headerStyle: {
                // backgroundColor: "black",
                color: "black",
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow.name}`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deletetemplate} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
export default withRouter(SectionType);
