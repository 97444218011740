import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Avatar, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withRouter } from "react-router-dom";
import { app_logo_icon } from "../../assets";
import { SideMenu } from "./components";
import { AdminLogout } from "../../DAL/login/login";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { color_palette } from "../../theme/theme";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "white",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  avatar: {
    width: 100,
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(0),
  },
  name: {
    marginTop: theme.spacing(1),
    alignSelf: "center",
  },
}));

function Appbar(props) {
  const { children } = props;
  //console.log(props);
  const classes = useStyles();
  //for sidebar selected navigation
  const [selectedIndex, setSelectedIndex] = React.useState();
  //for profile menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //for sidebar
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const Logout = async () => {
    const data = {
      token: localStorage.getItem("token"),
    };
    handleClose();
    const result = await AdminLogout(data);
    if (result.code === 200) {
      localStorage.clear();
      props.history.push("/login");
    }
  };
  const ChangePassword = () => {
    handleClose();
    props.history.push("/change-password");
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ height: 67 }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {/* <img
            className={classes.avatar}
            alt="app-logo"
            style={{ marginTop: 5, marginLeft: 0 }}
            height="auto"
            src={app_logo_icon}
          ></img> */}
          Builder Templates Admin
          <Typography
            variant="h6"
            noWrap
            onClick={() => {
              props.history.push("/dashboard");
              setSelectedIndex(0);
            }}
            style={{ cursor: "pointer" }}
          ></Typography>
          <IconButton
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{
              marginLeft: "auto",
              backgroundColor: "inherit",
            }}
          >
            <Typography
              style={{ marginRight: 10, color: color_palette.secondary }}
            >
              {localStorage.getItem("user").charAt(0).toUpperCase() +
                localStorage
                  .getItem("user")
                  .substring(1, localStorage.getItem("user").length)}

              {/* {localStorage.getItem("user")} */}
            </Typography>
            <AccountCircleIcon
              style={{ width: 40, height: 40, color: "white" }}
            />
            <ArrowDropDownIcon style={{ color: "white" }} />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={ChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={Logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {/* Avatar */}

          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />

        {/* Side menu */}
        <SideMenu
          selectedIndex={selectedIndex}
          handleListItemClick={handleListItemClick}
        />
        {/* Side menu */}
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {/* children */}
        {children}
      </main>
    </div>
  );
}

export default withRouter(Appbar);
