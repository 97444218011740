import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {
  _delete_template_assets,
  get_file_listing,
} from "../../../DAL/templates/templates";
import { useSnackbar } from "notistack";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Checkbox,
  IconButton,
} from "@mui/material";
import { upload_folder } from "../../../DAL/section/section";
import { s3baseUrl } from "../../../config/config";
import { default_file_image } from "../../../assets";
import { FileCopy, ArrowBack, MoreVert } from "@material-ui/icons";
import { Menu, MenuItem } from "@szhsin/react-menu";
import CustomConfirmation from "../../../components/CustomConfirmation";
import FilePreviewModal from "../../../components/FilePreviewModal";
import DescriptionIcon from "@mui/icons-material/Description";

function TemplateAssets(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isuser, setIsUser] = useState(false);
  const [checked, setChecked] = useState({});

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // Selected row state
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // states for toggle and checked box buttons
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);

  // checked delete confirmation
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState(null);

  // loader states on upload and delete

  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const template_id = props.match.params.id;

  const handleFileUpload = (e) => {
    const files = Object.values(e.target.files);
    setSelectedFiles(files);
    setShowPreviewModal(true);
  };

  const handleUpload = async () => {
    setIsUser(false);
    setShowPreviewModal(false);
    await Upload_File(selectedFiles);
    // Set loading state to true
    // setIsUser(true);
  };

  const Upload_File = async (files) => {
    console.log(files, "uploaded files");
    for (let i = 0; i < files.length; i++) {
      let formData = new FormData();
      formData.append("project_name", template_id + "/assets");
      formData.append("upload_file", files[i]);
      formData.append(
        "content_type",
        files[i].type ? files[i].type : "image/png"
      );
      const upload_folder_resp = await upload_folder(formData);
      if (upload_folder_resp.code === 200) {
        i + 1 == files.length && get_file();
      }
    }
  };

  const get_file = async () => {
    const postData = {
      path: template_id + "/assets/",
    };
    const list_resp = await get_file_listing(postData);

    if (list_resp.code === 200) {
      let dataWithCheckedKey = list_resp?.templates.map((item, i) => {
        return {
          ETag: item.ETag,
          Key: item.Key,
          LastModified: item.LastModified,
          Owner: item.Owner,
          Size: item.Size,
          StorageClass: item.StorageClass,
          checked: false,
        };
      });
      setData(dataWithCheckedKey);
      console.log(list_resp.templates, "file templates", dataWithCheckedKey);
      setIsUser(true);
    } else {
      enqueueSnackbar(list_resp.message, { variant: "error" });
    }
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Copied to clipboard", { variant: "success" });
  };

  useEffect(() => {
    get_file();
  }, []);

  const handleGoBack = () => {
    props.history.goBack();
  };

  const handleDeletePage = async () => {
    setIsLoadingDelete(true); // Set loading state to true
    setIsUser(true);

    console.log(selectedRow, "selected row");
    const delete_resp = await _delete_template_assets({
      path: selectedRow.Key,
    });

    if (delete_resp.code === 200) {
      setOpenDelete(false);
      get_file();
      enqueueSnackbar("Asset Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
    // loader
    setIsLoadingDelete(false);
    setIsUser(false);
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleDeleteCheckedClick = (row) => {
    setSelectedRowForDeletion(row);
    setOpenDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // loader
      setIsUser(false);

      let tempObj = Object.keys(checked);
      let tempData = [...data];
      tempObj.map((key) => {
        tempData = tempData.filter((d) => d.Key !== key);
      });
      setData(tempData);
      tempObj.map(async (key, index) => {
        const result = await _delete_template_assets({ path: key });
        if (result.code == 200) {
          delete checked[key];
          setChecked({ ...checked });
          if(index+1==Object.keys(tempObj).length){
            setIsUser(true)
          }
        } else {
          setIsUser(true)
          return;
        }
      });

      setOpenDeleteConfirmation(false);
      // Show success snackbar
      enqueueSnackbar("Rows deleted successfully.", { variant: "success" });
    } catch (error) {
      setIsUser(true)
      // Show error snackbar
      console.log(error);
      enqueueSnackbar("Error deleting rows.", { variant: "error" });
    } finally {
      // setIsUser(true); // Set loading state to false
      console.log("hellofinaly");
    }
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleSelectAll = () => {
    if (Object.keys(checked).length == data.length) {
      setChecked({});
    } else {
      let tempObj = {};
      data.map((d) => {
        tempObj = {
          ...tempObj,
          [d.Key]: d,
        };
      });
      setChecked(tempObj);
    }
  };

  const handleSelectRow = (data) => {
    console.log(data, "fasasjlkajs");
    if (!checked[data.Key]) {
      setChecked((prev) => {
        return {
          ...prev,
          [data.Key]: data,
        };
      });
    } else {
      setChecked((prev) => {
        delete prev[data.Key];
        return { ...prev };
      });
    }
  };

  useEffect(() => {
    let tempArr = [];
    data.map((row) => {
      if (row.checked) {
        tempArr.push(row.Key);
      }
    });
    setSelectedData([...tempArr]);
  }, [data]);
  useEffect(() => {
    console.log(openDeleteConfirmation, "ascskacjakcsacgsa");
  }, [openDeleteConfirmation]);

  const handleSelectClick = () => {
    if (isSelectionMode) {
      // Clear selected checkboxes and hide upload button
      setChecked({});
      setShowCheckboxes(false);
      setIsSelectionMode(false);
    } else {
      // Enable selection mode and show checkboxes
      setIsSelectionMode(true);
      setShowCheckboxes(true);
    }
  };
  return (
    <>
      {!isuser ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <ArrowBack onClick={handleGoBack} style={{ cursor: "pointer" }} />

            {/* <div>
              {!isSelectionMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  style={{ marginRight: "12px" }}
                  onClick={() => {
                    setIsSelectionMode(true);
                    setShowCheckboxes(true);
                  }}
                >
                  Select
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "12px" }}
                    onClick={() => handleDeleteCheckedClick()}
                    disabled={Object.keys(checked).length === 0}
                  >
                    Delete
                  </Button>
                </>
              )}

              <Button variant="contained" color="primary" component="label">
                Upload File
                <input
                  multiple
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileUpload}
                />
              </Button>
            </div> */}

            <div>
              {!isSelectionMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  style={{ marginRight: "12px" }}
                  onClick={handleSelectClick}
                >
                  Select
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "12px" }}
                    onClick={handleDeleteCheckedClick}
                    disabled={Object.keys(checked).length === 0}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    style={{ marginRight: "12px" }}
                    onClick={handleSelectClick}
                  >
                    Cancel
                  </Button>
                </>
              )}

              {!isSelectionMode && (
                <Button variant="contained" color="primary" component="label">
                  Upload File
                  <input
                    multiple
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </Button>
              )}
            </div>
          </div>
          <div style={{ marginTop: "30px" }} className="container">
            <Card
              sx={{
                borderRadius: "12px",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                padding: "5px",
              }}
            >
              <Table
                aria-label="simple table"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: "12px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {showCheckboxes && (
                        <Checkbox
                          checked={
                            Object.keys(checked).length == data.length &&
                            data.length > 0
                          }
                          onChange={() => handleSelectAll()}
                        />
                      )}
                    </TableCell>

                    <TableCell>
                      <Typography variant="h6" sx={{ fontSize: "18px" }}>
                        #
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                        File
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                        File Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                        Preview
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                        Copy URL
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "500",
                        }}
                      >
                        {/* Actions */}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "center" }}
                        >
                          No assets found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((list, index) => (
                      <TableRow key={list.Key}>
                        <TableCell>
                          {showCheckboxes && (
                            <Checkbox
                              checked={Boolean(checked[list.Key])}
                              onChange={() => handleSelectRow(list)}
                            />
                          )}
                        </TableCell>

                        <TableCell style={{ padding: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          <div style={{ display: "flex", alignItems: "left" }}>
                            <img
                              src={s3baseUrl + list.Key}
                              height="50px"
                              width="85px"
                              alt=""
                              onError={(e) => {
                                e.target.src = default_file_image;
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "400",
                              marginLeft: "8px",
                            }}
                          >
                            {list.Key}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          <div>
                            <Chip
                              label="Preview"
                              color="primary"
                              onClick={() =>
                                window.open(s3baseUrl + list.Key, "_blank")
                              }
                              style={{ marginRight: "10px" }}
                            />
                          </div>
                        </TableCell>

                        <TableCell>
                          <Chip
                            label="Copy URL"
                            onClick={() =>
                              handleCopyToClipboard(s3baseUrl + list.Key)
                            }
                          >
                            Copy URL
                          </Chip>
                        </TableCell>
                        <TableCell style={{ padding: "10px" }}>
                          <Menu
                            menuButton={
                              <MoreVert
                                style={{
                                  cursor: "pointer",
                                  fontSize: "25px",
                                  marginRight: "10px",
                                }}
                              />
                            }
                            align="end"
                            position="anchor"
                            menuStyles={{
                              zIndex: 9999,
                              marginTop: "10px",
                            }}
                          >
                            <MenuItem onClick={() => handleDeleteClick(list)}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Card>
          </div>

          {selectedRow && (
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={
                <React.Fragment>
                  <Typography sx={{ marginBottom: "15px" }}>
                    Are you sure you want to delete this file:
                  </Typography>

                  {selectedRow.Key.toLowerCase().endsWith(".png") ||
                  selectedRow.Key.toLowerCase().endsWith(".jpg") ||
                  selectedRow.Key.toLowerCase().endsWith(".jpeg") ||
                  selectedRow.Key.toLowerCase().endsWith(".gif") ||
                  selectedRow.Key.toLowerCase().endsWith(".svg") ? (
                    <img
                      src={s3baseUrl + selectedRow.Key}
                      style={{ maxHeight: "250px", maxWidth: "150px" }}
                      alt=""
                    />
                  ) : (
                    <DescriptionIcon sx={{ width: "45px", height: "70px" }} />
                  )}

                  <Typography>{selectedRow.Key}</Typography>
                </React.Fragment>
              }
              handleAgree={handleDeletePage}
            />
          )}

          <CustomConfirmation
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            title={
              <React.Fragment>
                <Typography style={{ marginBottom: "15px" }}>
                  Are you sure you want to delete this file:
                </Typography>
                <div
                  style={{
                    maxHeight: "400px",
                  }}
                >
                  <div
                    style={{
                      maxHeight: "300px",
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "transparent transparent",
                    }}
                  >
                    {Object.values(checked).map((row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          <img
                            src={s3baseUrl + row.Key}
                            style={{ maxHeight: "250px", maxWidth: "150px" }}
                            alt=""
                          />

                          <Typography sx={{ padding: "12px" }}>
                            {" "}
                            {row.Key}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            }
            handleAgree={handleConfirmDelete}
          />

          {selectedFiles.length > 0 && (
            <FilePreviewModal
              open={showPreviewModal}
              onClose={() => setShowPreviewModal(false)}
              files={selectedFiles}
              onUpload={handleUpload}
              onDelete={handleDeleteFile}
            />
          )}
        </MuiThemeProvider>
      )}
    </>
  );
}

export default withRouter(TemplateAssets);
