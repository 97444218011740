import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { useSnackbar } from "notistack";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import { get_css } from "../../../DAL/fetch_api/fetch_api";
import {
  get_source_code_html,
  get_source_code_css,
} from "../../../DAL/fetch_api/fetch_api";
import { upload_files } from "../../../DAL/section/section";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },

  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProjectSourceCode(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  const [category, setCategory] = useState([]);
  const [URL, setURL] = useState(
    "https://accelerator-builder-bucket.s3.amazonaws.com"
  );
  const [CssFile, setCss] = useState("");
  const [htmlFile, setHtml] = useState("");
  const [sectiontype, setSectionType] = useState("");
  const [order, setOrder] = useState("");
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (data) => {
    data.preventDefault();
    // console.log(CssFile,"csss");
    // console.log(htmlFile,"htmlklllll");
    setiswaiting(true);
    let postdata = {
      project_id: props.match.params.id,
      html: htmlFile,
      css: CssFile,
      type: "project",
    };
    const edit_file = await upload_files(postdata);
    if (edit_file.code == 200) {
      props.history.push("/project");
    } else {
      setiswaiting(false);
      enqueueSnackbar(edit_file.message, { variant: "error" });
    }
  };

  useEffect(async () => {
    setiswaiting(true);

    console.log(props.match.params.id, "project id");

    const result = await get_source_code_css(props.match.params.id);
    //console.log(result,"reuslt to show status")
    if (result) {
      setCss(result);
    } else {
      enqueueSnackbar("File is not Available! ", { variant: "error" });
      history.goBack();
    }

    // console.log(result,"result")
  }, []);
  useEffect(async () => {
    console.log(props.match.params.id, "id to send in api ");

    setiswaiting(true);
    const result = await get_source_code_html(props.match.params.id);
    //console.log(result.status,"reuslt to show files")
    if (result) {
      setHtml(result);
      setiswaiting(false);
    } else {
      enqueueSnackbar("File is not Available! ", { variant: "error" });
      history.goBack();
    }
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Edit Source Code
          </Typography>
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="html"
                  value={htmlFile}
                  multiline
                  rows={15}
                  onChange={(e) => setHtml(e.target.value)}
                  label="HTML"
                  name="html"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="css"
                  value={CssFile}
                  multiline
                  rows={15}
                  onChange={(e) => setCss(e.target.value)}
                  label="CSS"
                  name="css"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
