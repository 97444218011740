import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from "notistack";
import {
  edit_template_category,
  get_template_category_detail,
} from "../../DAL/template_categories/TemplateCategories";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditTemplateCategory(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [order, setOrder] = useState();
  const [status, setStatus] = useState(true);
  const [errorflag, setflag] = useState("");
  const [is_load_data, setLoadData] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);

  const handlename = (event) => {
    setName(event.target.value);
  };

  //console.log(props,"props values")
  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();

    setiswaiting(true);

    const postData = {
      name: name,
      status: status,
      order: order,
    };

    const templatecategory_edit_resp = await edit_template_category(
      postData,
      props.match.params.id
    );
    console.log(templatecategory_edit_resp, "RESPONSE");
    if (templatecategory_edit_resp.code == 200) {
      props.history.push("/templateCategories");
    } else {
      enqueueSnackbar(templatecategory_edit_resp.message, { variant: "error" });
    }
    setiswaiting(false);
  };

  useEffect(async () => {
    const templatecategory_detail = await get_template_category_detail(
      props.match.params.id
    );
    console.log(templatecategory_detail, "templateDetailtemplateDetail");
    setName(templatecategory_detail.template_category.name);
    setOrder(templatecategory_detail.template_category.order);
    setStatus(templatecategory_detail.template_category.status);
    setLoadData(true);
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      {is_load_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Template Category
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={name}
                    onChange={handlename}
                    label="Name"
                    name="name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    label="Order"
                    name="order"
                  />
                </Grid>
                <Grid item xs={6}>
                  Status :
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                        name="checkedA"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit Category
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
