/* eslint-disable jsx-a11y/alt-text */
import React, { forwardRef, useEffect } from "react";
// import { Button, Card, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import {
  _delete_template_group,
  _get_template_group,
} from "../../DAL/templates/templates";
import { MoreVert } from "@material-ui/icons";
import CustomConfirmation from "../../components/CustomConfirmation";
import { makeStyles } from "@material-ui/core/styles";
import LayersIcon from "@mui/icons-material/Layers";
import { color_palette } from "../../theme/theme";
import Label from "../../components/Label";
import moment from "moment";
import DetailDialog from "../../components/detail_template/DetailDialog";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "4px",
    },
  },
  hed: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    // width: "10px",
    // overflow: "hidden",
  },
  cellRelative: {
    position: "relative",
    // Add any other styles you need
  },
}));

export default function TemplateGroup(props) {
  const classes = useStyles();
  const tableRef = React.useRef(null);

  const [fileList, setFileList] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedrow, setSelectedRow] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [targetTemplateGroup, setTargetTemplateGroup] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [detail, setDetail] = useState();
  const [isdata, setisdata] = React.useState(false);

  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}></span>,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={{ float: "right" }}>Actions</span>,
      render: (rowData) => (
        <div className={classes.mnu}>
          <Menu
            menuButton={
              <MoreVert
                style={{
                  cursor: "pointer",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />
            }
            align="end"
            position="anchor"
            menuStyles={{
              zIndex: 9999,
              marginTop: "10px",
            }}
          >
            <MenuItem
              onClick={() =>
                props.history.push({
                  pathname: `/template-group/edit-template-group/${rowData._id}`,
                  data: rowData,
                })
              }
              sx={{ cursor: "pointer" }}
            >
              Edit Group
            </MenuItem>
            <MenuItem
              onClick={
                () =>
                  props.history.push({
                    pathname: `/template-group/edit-root-values/${rowData.template_array[0].template}`,
                    data: {
                      groupPages: rowData.template_array,
                      changeRootValuesOfAllTemplates: true,
                    },
                  })
                // console.log(rowData,"rowDatarowData")
              }
              sx={{ cursor: "pointer" }}
            >
              Edit Group Root Values
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDelete(true);
                setTargetTemplateGroup(rowData);
              }}
              sx={{ cursor: "pointer" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
      ),
    },
    {
      title: <span className={classes.hed}>Group Name</span>,
      width: 200,
      field: "name",
      render: (rowData) => (
        <>
          <div
            style={{
              display: "flex",
              maxWidth: "200px",
              minWidth: "100px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleDetailDialog(rowData);
            }}
          >
            <span
              style={{
                textOverflow: "ellipsis",
                maxWidth: "190px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {rowData.name}
            </span>
          </div>
        </>
      ),
    },

    {
      title: <span className={classes.hed}>Group Type</span>,
      width: 150,
      render: (rowData) => (
        <>
          <div
            style={{
              display: "flex",
              maxWidth: "100px",
              minWidth: "100px",
            }}
          >
            <span
              style={{
                textOverflow: "ellipsis",
                maxWidth: "95px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {rowData.group_type === "funnel" ? "Funnel" : "Book A Call"}
            </span>
          </div>
        </>
      ),
    },

    {
      title: <span className={classes.hed}>Published</span>,

      render: (rowData) =>
        rowData.is_published ? (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked
                disabled
                style={{
                  color: color_palette.primary,
                  marginLeft: -15,
                }}
              />
              Published
            </div>
          </>
        ) : (
          <>
            <div style={{ minWidth: 150 }}>
              <Radio
                checked={true}
                disabled
                style={{
                  color: "gray",
                  marginLeft: -15,
                }}
              />
              Not Published
            </div>
          </>
        ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Usage Count</span>,
      width: 250,
      field: "use_count",
    },

    {
      title: <span className={classes.hed}>Is Paid</span>,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            maxWidth: "200px",
            minWidth: "100px",
          }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              maxWidth: "190px",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {rowData.is_paid === true
              ? `YES/${getCurrencySymbol("en-US", rowData.currency)}${
                  rowData.amount
                }`
              : `NO/${getCurrencySymbol("en-US", rowData.currency)}0`}
          </span>
        </div>
      ),
    },

    // {
    //   title: <span className={classes.hed}>Amount</span>,
    //   width: 150,
    //   render: (rowData) => (
    //     <>
    //       {rowData.is_paid === true
    //         ? `${getCurrencySymbol("en-US", rowData?.currency)}${
    //             rowData.amount
    //           }`
    //         : `${getCurrencySymbol("en-US", rowData?.currency)}0`}
    //     </>
    //   ),
    // },
    {
      title: <span className={classes.hed}>Discount</span>,
      width: 150,
      render: (rowData) => (
        <>
          {" "}
          {rowData.is_paid === true
            ? `${getCurrencySymbol("en-US", rowData?.currency)}${
                rowData.discount
              }`
            : `${getCurrencySymbol("en-US", rowData?.currency)}0`}
        </>
      ),
    },
    {
      title: <span className={classes.hed}>Final Amount</span>,

      render: (rowData) => (
        <>
          <span style={{ fontWeight: "500" }}>
            {rowData.is_paid === true
              ? `${getCurrencySymbol("en-US", rowData?.currency)}${
                  rowData.amount - rowData.discount
                }`
              : `${getCurrencySymbol("en-US", rowData?.currency)}0`}
          </span>
        </>
      ),
    },
    {
      title: <span className={classes.hed}>Pages</span>,

      render: (rowData) => (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {rowData.template_array.length}
            <LayersIcon sx={{ marginInlineStart: "4px" }} fontSize="small" />
          </div>
        </>
      ),
    },
    {
      title: <span className={classes.hed}></span>,
      render: (rowData) => (
        <>
          <div
            style={{
              display: "flex",
              // height: "120px",
            }}
          >
            {rowData.template_array.map((page) => (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginInlineEnd: "10px",
                }}
              >
                <img
                  src={s3baseUrl + page.image}
                  height={"70px"}
                  width={"120px"}
                  onClick={() => {
                    window.open(s3baseUrl + page.template + "/" + "index.html");
                  }}
                  style={{ cursor: "pointer" }}
                />

                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      width: "80px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      marginTop: "4px",
                    }}
                  >
                    {page.name}
                  </span>
                  <div>
                    <Menu
                      menuButton={
                        <MoreVert
                          style={{
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        />
                      }
                      align="center"
                      position="anchor"
                      direction="right"
                      arrow={true}
                    >
                      <MenuItem
                        onClick={() =>
                          props.history.push({
                            pathname: `/template-group/editTemplates/${page.template}`,
                          })
                        }
                        sx={{ cursor: "pointer" }}
                      >
                        Edit Page
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          props.history.push({
                            pathname: `/template-group/edit-root-values/${page.template}`,
                          })
                        }
                        sx={{ cursor: "pointer" }}
                      >
                        Edit Root Values
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          props.history.push({
                            pathname: `/template-group/${page.template}/pages`,
                          })
                        }
                      >
                        Pages List
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          props.history.push({
                            pathname: `/template-group/editTemplateSourceCode/${page.template}`,
                          })
                        }
                      >
                        Source Code
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          props.history.push({
                            pathname: `/template-group/${page.template}/template-assets`,
                          })
                        }
                      >
                        View Assets
                      </MenuItem>
                    </Menu>
                  </div>
                </span>
              </span>
            ))}
          </div>
        </>
      ),
    },
  ]);

  const AddtemplateGroup = () => {
    props.history.push("/template-group/add-template-group");
  };

  const handleOpenDetailDialog = () => {
    setOpenDetailDialog(true);
  };

  const handleDetailDialog = (row) => {
    handleOpenDetailDialog();
    setSelectedRow(row);
  };

  const handleCloseDialog = () => {
    setOpenDetailDialog(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setOpenDelete(false);
    const resp = await _delete_template_group(targetTemplateGroup._id);
    if (resp.code === 200) {
      getTemplateGroups();
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getTemplateGroups = async () => {
    const resp = await _get_template_group();
    if (resp.code === 200) {
      console.log(resp, "respofgroupslisting");
      setFileList(resp.template);
      setisdata(true);
    }
    setIsLoading(false);
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  useEffect(() => {
    if (selectedrow) {
      setDetail([
        {
          name: "Name",
          value: selectedrow ? selectedrow?.name : "-",
        },
        {
          name: "Usage Count",
          value: selectedrow ? selectedrow?.use_count : "-",
        },
        {
          name: "Published",
          value: selectedrow?.status === true ? "YES" : "NO",
        },
        {
          name: "Is Paid",
          value: selectedrow.is_paid === true ? "YES" : "NO",
        },
        {
          name: "Currency",
          value: selectedrow ? selectedrow?.currency : "-",
        },
        {
          name: "Amount",
          value:
            selectedrow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedrow?.currency)}${
                  selectedrow?.amount
                }`
              : `${getCurrencySymbol("en-US", selectedrow?.currency)}0`,
        },
        {
          name: "Discount",
          value:
            selectedrow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedrow?.currency)}${
                  selectedrow?.discount
                }`
              : `${getCurrencySymbol("en-US", selectedrow?.currency)}0`,
        },
        {
          name: "Final Amount",
          value:
            selectedrow?.is_paid === true
              ? `Amount = Actual Amount (${getCurrencySymbol(
                  "en-US",
                  selectedrow?.currency
                )}${selectedrow?.amount}) - Discount (${getCurrencySymbol(
                  "en-US",
                  selectedrow?.currency
                )}${selectedrow?.discount}) = ${getCurrencySymbol(
                  "en-US",
                  selectedrow?.currency
                )}${selectedrow?.amount - selectedrow?.discount}`
              : `${getCurrencySymbol("en-US", selectedrow?.currency)}0`,
        },
        {
          name: "Ribbon",
          value: selectedrow ? selectedrow?.ribbon : "-",
        },
        {
          name: "Template Group Id",
          value: selectedrow ? selectedrow?._id : "-",
        },
        {
          name: "Created At",
          value: selectedrow.createdAt ? (
            moment(selectedrow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectedrow.updatedAt ? (
            moment(selectedrow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
    }
  }, [selectedrow]);

  useEffect(() => {
    setIsLoading(true);
    getTemplateGroups();
  }, []);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleDelete}
        title={"Are you sure you want to delete this template group"}
      />
      <DetailDialog
        handleCloseDialog={handleCloseDialog}
        openDetailDialog={openDetailDialog}
        selectedrow={selectedrow}
        detail={detail}
        title="Template Group Detail"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Button variant="contained" color="primary" onClick={AddtemplateGroup}>
          Add Template Group
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {isloading == true ? (
          <CircularProgress style={{ marginTop: "15%" }} />
        ) : (
          <>
            <Card style={{ width: "75vw" }}>
              <TableContainer className={classes.tableContainer}>
                <MaterialTable
                  tableRef={tableRef}
                  style={{
                    marginTop: "20px",
                    borderRadius: "12px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                    padding: "5px",
                    overflow: "hidden",
                  }}
                  title="Template Group"
                  icons={tableIcons}
                  columns={columns.map((c) => ({
                    ...c,
                    tableData: undefined,
                  }))}
                  // cellEditable={{
                  //   cellStyle: { position: "relative" },
                  // }}
                  cellStyle={(rowData, dataIndex, theme) => {
                    return {
                      // Apply the cellRelative class to all cells
                      className: classes.cellRelative,
                    };
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <div style={{ padding: 20 }}>
                          {isdata == false ? (
                            <CircularProgress />
                          ) : (
                            <h5>No data found to display</h5>
                          )}
                        </div>
                      ),
                    },
                  }}
                  data={fileList}
                  options={{
                    actionsColumnIndex: -1,
                    search: true,
                    searchFieldAlignment: "right",
                    searchFieldVariant: "standard",
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 100, 200],
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      color: "black",
                      padding: "dense",
                      fontWeight: "bold",
                    },
                  }}
                />
                {/* <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{""}</TableCell>
                      <TableCell>Actions</TableCell>
                      <TableCell>Group Name</TableCell>
                      <TableCell>Template Type</TableCell>
                      <TableCell>Group Type</TableCell>
                      <TableCell>Published</TableCell>
                      <TableCell>Usage Count</TableCell>
                      <TableCell>Is Paid</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Final Amount</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Pages</TableCell>
                      <TableCell>{""}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {fileList.length == 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <h3>No data found to display</h3>
                        </TableCell>
                      </TableRow>
                    )}
                    {fileList.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          onClick={() => {
                            setTargetTemplateGroup(row);
                            console.log(row, "targetTemplategroup");
                          }}
                        >
                          <Menu
                            menuButton={
                              <MoreVert
                                style={{
                                  cursor: "pointer",
                                  fontSize: "25px",
                                  marginRight: "10px",
                                }}
                              />
                            }
                            align="end"
                            position="anchor"
                            menuStyles={{
                              zIndex: 9999,
                              marginTop: "10px",
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                props.history.push({
                                  pathname: `/template-group/edit-template-group/${row._id}`,
                                  data: row,
                                })
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenDelete(true);
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "200px",
                              minWidth: "100px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDetailDialog(row);
                            }}
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                maxWidth: "190px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {row.name}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "200px",
                              minWidth: "100px",
                            }}
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                maxWidth: "190px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              <Label
                                style={{ width: "100px" }}
                                color={
                                  row.app_type === 0
                                    ? "success"
                                    : row.app_type === 1
                                    ? "primary"
                                    : row.app_type === 2
                                    ? "info"
                                    : row.app_type === 3
                                    ? "info"
                                    : "-"
                                }
                              >
                                {row.app_type === 0
                                  ? "ALL"
                                  : row.app_type === 1
                                  ? "Inovate"
                                  : row.app_type === 2
                                  ? "Magnitizer"
                                  : row.app_type === 3
                                  ? "Vission"
                                  : "-"}
                              </Label>
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "100px",
                              minWidth: "100px",
                            }}
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                maxWidth: "95px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {row.group_type === "funnel"
                                ? "Funnel"
                                : "Book A Call"}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          {row.is_published ? (
                            <>
                              <div style={{ minWidth: 100 }}>
                                <Radio
                                  checked
                                  disabled
                                  style={{
                                    color: color_palette.primary,
                                    marginLeft: -15,
                                  }}
                                />
                                Published
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ minWidth: 150 }}>
                                <Radio
                                  checked={true}
                                  disabled
                                  style={{
                                    color: "gray",
                                    marginLeft: -15,
                                  }}
                                />
                                Not Published
                              </div>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "200px",
                              minWidth: "100px",
                            }}
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                maxWidth: "190px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {row.use_count}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "200px",
                              minWidth: "100px",
                            }}
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                maxWidth: "190px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              <Label
                                color={
                                  row.is_paid === true ? "success" : "error"
                                }
                              >
                                {row.is_paid === true
                                  ? `YES/${getCurrencySymbol(
                                      "en-US",
                                      row.currency
                                    )}${row.amount}`
                                  : `NO/${getCurrencySymbol(
                                      "en-US",
                                      row.currency
                                    )}0`}
                              </Label>
                            </span>
                          </div>
                        </TableCell>

                        <TableCell>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "200px",
                              minWidth: "100px",
                            }}
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                maxWidth: "190px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              <Label
                                color={
                                  row.is_paid === true ? "success" : "error"
                                }
                              >
                                {row.is_paid === true
                                  ? `${getCurrencySymbol(
                                      "en-US",
                                      row.currency
                                    )}${row.discount}`
                                  : `${getCurrencySymbol(
                                      "en-US",
                                      row.currency
                                    )}0`}
                              </Label>
                            </span>
                          </div>
                        </TableCell>

                        <TableCell>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "200px",
                              minWidth: "100px",
                            }}
                          >
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                maxWidth: "190px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              <Label
                                color={
                                  row.is_paid === true ? "success" : "error"
                                }
                              >
                                <span style={{ fontWeight: "500" }}>
                                  {row.is_paid === true
                                    ? `${getCurrencySymbol(
                                        "en-US",
                                        row?.currency
                                      )}${row.amount - row.discount}`
                                    : `${getCurrencySymbol(
                                        "en-US",
                                        row.currency
                                      )}0`}
                                </span>
                              </Label>
                            </span>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            {row.template_array.length}
                            <LayersIcon
                              sx={{ marginInlineStart: "4px" }}
                              fontSize="small"
                            />
                          </div>
                        </TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "400px",
                              // height: "120px",
                            }}
                            className={classes.tableContainer}
                          >
                            {row.template_array.map((page) => (
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginInlineEnd: "10px",
                                }}
                              >
                                <img
                                  src={s3baseUrl + page.image}
                                  height={"70px"}
                                  width={"120px"}
                                  onClick={() => {
                                    window.open(
                                      s3baseUrl +
                                        page.template +
                                        "/" +
                                        "index.html"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                />

                                <span
                                  style={{
                                    textOverflow: "ellipsis",
                                    width: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    marginTop: "4px",
                                  }}
                                >
                                  {page.name}
                                </span>
                              </span>
                            ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table> */}
              </TableContainer>
            </Card>
          </>
        )}
      </div>
    </>
  );
}
