import { invokeApi } from "../../bl_libs/invokeApi";

export const sectionType_list = async () => {
  let requestObj = {
    path: `api/section_type/list_section_type`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const template_search = async (search_text) => {
//     let requestObj = {
//       path: `api/template/search_template?text=${search_text}`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };
export const add_Sectiontype = async (data) => {
  let requestObj = {
    path: "api/section_type/add_section_type",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const upload_image = async (data) => {
  let requestObj = {
    path: "api/app_api/upload_image_on_s3",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_SectionType = async (id) => {
  let requestObj = {
    path: `api/section_type/delete_section_type/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_sectiontype_detail = async (id) => {
  // console.log(id)
  let requestObj = {
    path: `api/section_type/detail_section_type/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_sectioncategory_detail = async (id) => {
  // console.log(id)
  let requestObj = {
    path: `api/category/list_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_Sectiontype = async (data, id) => {
  let requestObj = {
    path: `api/section_type/update_section_type/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
