import { invokeApi } from "../../bl_libs/invokeApi";
import { s3baseUrl } from "../../config/config";

export const Templates_list = async () => {
  let requestObj = {
    path: `api/template/list_template_all`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Template_Pages_List = async (id) => {
  let requestObj = {
    path: `api/page/list_page_by_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
// export const template_search = async (search_text) => {
//     let requestObj = {
//       path: `api/template/search_template?text=${search_text}`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };

export const upload_image = async (data) => {
  let requestObj = {
    path: "api/app_api/upload_image_on_s3",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_Template = async (data) => {
  let requestObj = {
    path: "api/template/add_template",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_category_detail = async (id) => {
  // console.log(id)
  let requestObj = {
    path: `api/category/list_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_Template = async (id) => {
  let requestObj = {
    path: `api/template/delete_template/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_Template_Page = async (id) => {
  let requestObj = {
    path: `api/page/delete_page/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_page_slug_in_template = async (id, data) => {
  let requestObj = {
    path: `api/template/add_page_slug_in_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_template_page_by_slugs = async (id) => {
  let requestObj = {
    path: `api/page/add_template_page_by_slugs/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_payment_page_id_against_template = async (id, data) => {
  let requestObj = {
    path: `api/template/add_payment_page_id_against_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_template_detail = async (id) => {
  // console.log(id)
  let requestObj = {
    path: `api/template/detail_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_file_listing = async (data) => {
  let requestObj = {
    path: `api/template/get_template_asserts/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_template_page_detail = async (id) => {
  let requestObj = {
    path: `api/page/detail_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_Template = async (data, id) => {
  let requestObj = {
    path: `api/template/update_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const edit_Template_root_values = async (data, id) => {
  let requestObj = {
    path: `api/template/update_template_root_values/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const edit_Template_Page = async (data, id) => {
  let requestObj = {
    path: `api/page/edit_page/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _making_zip_of_project = async (id) => {
  let requestObj = {
    path: `api/template/make_zip_file_of_template/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _delete_zip_of_project = async (id) => {
  let requestObj = {
    path: `api/template/delete_zip_file_of_template/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// delete template assets
export const _delete_template_assets = async (data) => {
  let requestObj = {
    path: `api/template/delete_assert_file_of_template/${undefined}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// Add template Group
export const _add_template_group = async (data) => {
  let requestObj = {
    path: `api/template_group/add_template_group`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_template_group = async () => {
  let requestObj = {
    path: `api/template_group/list_template_group`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _delete_template_group = async (id) => {
  let requestObj = {
    path: `api/template_group/delete_template_group/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _details_template_group = async (id) => {
  let requestObj = {
    path: `api/template_group/detail_template_group/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _edit_template_group = async (id, data) => {
  let requestObj = {
    path: `api/template_group/edit_template_group/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
