import React from "react";
import { Switch, Redirect } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "../components";
import { MainWithSidebar, MinimalLayout } from "../layouts";

import {
  Dashboard,
  Login,
  ChangePassword,
  Setting,
  Customer,
  ListTemplates,
  TemplatePagesList,
  EditTemplatePage,
  AddTemplates,
  EditTemplates,
  ListSection,
  AddSection,
  EditSection,
  Category,
  AddCategory,
  EditCategory,
  SectionType,
  AddSectionType,
  EditSectionType,
  TemplateAssets,
  TemplateGroup,
  AddTemplateGroup,
  AddTemplateCategory,
  EditTemplateCategory,
  TemplatesCategory,
  EditRootValues,
} from "../pages";
import AddCustomer from "../pages/customer/AddCustomer";
import EditCustomer from "../pages/customer/EditCustomer";
import Project from "../pages/project/project";
import AddProject from "../pages/project/add_project/addProject";
import EditProject from "../pages/project/edit_project/EditProject";
import EditSourceCode from "../pages/section/source_code/EditSourceCode";
import EditTemplateSourceCode from "../pages/templates/template_source_code/Template_source_code";
import listDesigner from "../pages/Designer/listDesigner";
import AddDesigner from "../pages/Designer/add_designer/addDesigner";
import EditDesigner from "../pages/Designer/edit_designer/editDesigner";
import ProjectSourceCode from "../pages/project/source_code/ProjectSourceCode";
import DesignerSourceCode from "../pages/Designer/source_code/DesignerSourceCode";
import SaveAsTemplate from "../pages/project/Save_As_Template/SaveAsTemplate";
import EditCustomers from "../pages/customer/EditCustomer";
import UpdatePassword from "../pages/customer/UpdatePassword";
import S3files from "../pages/s3-files/s3files";
import Gallery from "../pages/Gallery/Gallery";

function Routes() {
  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicRouteWithLayout
          component={Login}
          exact
          layout={MinimalLayout}
          path="/login"
        />
        {/* --------------------------------------- Dashboard --------------------------------------- */}
        <PrivateRouteWithLayout
          component={Dashboard}
          exact
          layout={MainWithSidebar}
          path="/dashboard"
        />
        {/* --------------------------------------- Change Password --------------------------------------- */}
        <PrivateRouteWithLayout
          component={ChangePassword}
          exact
          layout={MainWithSidebar}
          path="/change-password"
        />

        {/* --------------------------------------- Setting --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Setting}
          exact
          layout={MainWithSidebar}
          path="/setting"
        />
        {/* --------------------------------------- s3 files --------------------------------------- */}

        <PrivateRouteWithLayout
          component={S3files}
          exact
          layout={MainWithSidebar}
          path="/s3files"
        />
        {/* --------------------------------------- Stock Images --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Gallery}
          exact
          layout={MainWithSidebar}
          path="/stock_images"
        />
        {/* --------------------------------------- Customer--------------------------------------- */}
        <PrivateRouteWithLayout
          component={Customer}
          exact
          layout={MainWithSidebar}
          path="/customer"
        />
        <PrivateRouteWithLayout
          component={AddCustomer}
          exact
          layout={MainWithSidebar}
          path="/Addcustomer"
        />
        <PrivateRouteWithLayout
          component={EditCustomers}
          exact
          layout={MainWithSidebar}
          path="/customer/editcustomer/:id"
        />
        <PrivateRouteWithLayout
          component={UpdatePassword}
          exact
          layout={MainWithSidebar}
          path="/customer/update_password/:id"
        />
        {/* --------------------------------------- Templates--------------------------------------- */}
        <PrivateRouteWithLayout
          component={ListTemplates}
          exact
          layout={MainWithSidebar}
          path="/templates"
        />
        <PrivateRouteWithLayout
          component={AddTemplates}
          exact
          layout={MainWithSidebar}
          path="/templates/addTemplates"
        />
        <PrivateRouteWithLayout
          component={EditTemplates}
          exact
          layout={MainWithSidebar}
          path="/templates/editTemplates/:id"
        />
        <PrivateRouteWithLayout
          component={EditRootValues}
          exact
          layout={MainWithSidebar}
          path="/templates/edit-root-values/:id"
        />
        <PrivateRouteWithLayout
          component={EditTemplateSourceCode}
          exact
          layout={MainWithSidebar}
          path="/templates/editTemplateSourceCode/:id"
        />
        <PrivateRouteWithLayout
          component={TemplatePagesList}
          exact
          layout={MainWithSidebar}
          path="/templates/:id/pages"
        />
        <PrivateRouteWithLayout
          component={TemplateAssets}
          exact
          layout={MainWithSidebar}
          path="/templates/:id/template-assets"
        />
        <PrivateRouteWithLayout
          component={EditTemplatePage}
          exact
          layout={MainWithSidebar}
          path="/templates/:template_id/pages/:pages_id"
        />

        {/* --------------------------------------- Template Group --------------------------------------- */}
        <PrivateRouteWithLayout
          component={TemplateGroup}
          exact
          layout={MainWithSidebar}
          path="/template-group"
        />
        <PrivateRouteWithLayout
          component={AddTemplateGroup}
          exact
          layout={MainWithSidebar}
          path="/template-group/add-template-group"
        />
        <PrivateRouteWithLayout
          component={AddTemplateGroup}
          exact
          layout={MainWithSidebar}
          path="/template-group/edit-template-group/:group_id"
        />
         <PrivateRouteWithLayout
          component={EditTemplates}
          exact
          layout={MainWithSidebar}
          path="/template-group/editTemplates/:id"
        />
        <PrivateRouteWithLayout
          component={EditRootValues}
          exact
          layout={MainWithSidebar}
          path="/template-group/edit-root-values/:id"
        />
        <PrivateRouteWithLayout
          component={EditTemplateSourceCode}
          exact
          layout={MainWithSidebar}
          path="/template-group/editTemplateSourceCode/:id"
        />
        <PrivateRouteWithLayout
          component={TemplatePagesList}
          exact
          layout={MainWithSidebar}
          path="/template-group/:id/pages"
        />
        <PrivateRouteWithLayout
          component={TemplateAssets}
          exact
          layout={MainWithSidebar}
          path="/template-group/:id/template-assets"
        />
        <PrivateRouteWithLayout
          component={EditTemplatePage}
          exact
          layout={MainWithSidebar}
          path="/template-group/:template_id/pages/:pages_id"
        />

        {/* --------------------------------------- Section--------------------------------------- */}
        <PrivateRouteWithLayout
          component={ListSection}
          exact
          layout={MainWithSidebar}
          path="/section"
        />
        <PrivateRouteWithLayout
          component={AddSection}
          exact
          layout={MainWithSidebar}
          path="/section/addSection"
        />
        <PrivateRouteWithLayout
          component={EditSection}
          exact
          layout={MainWithSidebar}
          path="/section/editSection/:id"
        />
        <PrivateRouteWithLayout
          component={EditSourceCode}
          exact
          layout={MainWithSidebar}
          path="/section/editSourceCode/:id"
        />

        {/* --------------------------------------- Categories--------------------------------------- */}
        <PrivateRouteWithLayout
          component={Category}
          exact
          layout={MainWithSidebar}
          path="/categories"
        />
        <PrivateRouteWithLayout
          component={AddCategory}
          exact
          layout={MainWithSidebar}
          path="/categories/add_category"
        />
        <PrivateRouteWithLayout
          component={EditCategory}
          exact
          layout={MainWithSidebar}
          path="/categories/editcategory/:id"
        />

        {/* --------------------------------------- Template Categories--------------------------------------- */}
        <PrivateRouteWithLayout
          component={TemplatesCategory}
          exact
          layout={MainWithSidebar}
          path="/templateCategories"
        />
        <PrivateRouteWithLayout
          component={AddTemplateCategory}
          exact
          layout={MainWithSidebar}
          path="/templateCategories/add_template-category"
        />
        <PrivateRouteWithLayout
          component={EditTemplateCategory}
          exact
          layout={MainWithSidebar}
          path="/templateCategories/edit_template-category/:id"
        />

        {/* --------------------------------------- Section Type--------------------------------------- */}
        <PrivateRouteWithLayout
          component={SectionType}
          exact
          layout={MainWithSidebar}
          path="/section_type"
        />
        <PrivateRouteWithLayout
          component={AddSectionType}
          exact
          layout={MainWithSidebar}
          path="/section_type/add_sectiontype"
        />
        <PrivateRouteWithLayout
          component={EditSectionType}
          exact
          layout={MainWithSidebar}
          path="/section_type/editsectiontype/:id"
        />
        {/* --------------------------------------- Project --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Project}
          exact
          layout={MainWithSidebar}
          path="/project"
        />
        {/* <PrivateRouteWithLayout
          component={AddProject}
          exact
          layout={MainWithSidebar}
          path="/project/addProject"
        /> */}
        <PrivateRouteWithLayout
          component={EditProject}
          exact
          layout={MainWithSidebar}
          path="/project/editProject/:id"
        />
        <PrivateRouteWithLayout
          component={ProjectSourceCode}
          exact
          layout={MainWithSidebar}
          path="/project/editSourceCode/:id"
        />
        <PrivateRouteWithLayout
          component={SaveAsTemplate}
          exact
          layout={MainWithSidebar}
          path="/project/saveastemplate/:id"
        />
        {/* --------------------------------------- Desinger --------------------------------------- */}

        <PrivateRouteWithLayout
          component={listDesigner}
          exact
          layout={MainWithSidebar}
          path="/designer"
        />
        <PrivateRouteWithLayout
          component={AddDesigner}
          exact
          layout={MainWithSidebar}
          path="/designer/adddesigner"
        />
        <PrivateRouteWithLayout
          component={EditDesigner}
          exact
          layout={MainWithSidebar}
          path="/designer/editdesigner/:id"
        />
        <PrivateRouteWithLayout
          component={DesignerSourceCode}
          exact
          layout={MainWithSidebar}
          path="/designer/editSourceCode/:id"
        />
      </Switch>
    </>
  );
}

export default Routes;
