import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from "notistack";
import { add_template_category } from "../../DAL/template_categories/TemplateCategories";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddTemplateCategory(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  const [status, setStatus] = React.useState(true);

  const handlename = (event) => {
    setName(event.target.value);
  };
  const handleChange = (event) => {
    setStatus(event.target.checked);
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();

    setiswaiting(true);

    const postData = {
      name: name,
      status: status,
    };

    const templatecategory_add_resp = await add_template_category(postData);
    console.log(templatecategory_add_resp);
    if (templatecategory_add_resp.code == 200) {
      setiswaiting(false);
      props.history.push("/templateCategories");
    } else {
      setiswaiting(false);
      enqueueSnackbar(templatecategory_add_resp.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {}, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Add Template Category
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <>
                  <TextField
                    required
                    id="outlined-multiline-static"
                    label="Name"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={handlename}
                    name="name"
                    autoFocus
                  />
                  <Grid item xs={6}>
                    Status :
                    <FormControlLabel
                      style={{ marginLeft: 10, marginBottom: 10 }}
                      control={
                        <Switch
                          color="primary"
                          checked={status}
                          onChange={handleChange}
                          name="checkedA"
                        />
                      }
                    />
                  </Grid>
                </>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Category
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
