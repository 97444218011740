/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { TextField } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import {
  get_templatetype_detail,
  edit_Templatetype,
  get_sectioncategory_detail,
  get_sectiontype_detail,
  upload_image,
  edit_Sectiontype,
} from "../../DAL/TemplateType/TemplateType";
import { useSnackbar } from "notistack";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
import { toInteger } from "lodash";

const useStyles = makeStyles(() => ({
  jss6: {
    top: 0,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  "MuiFormControl-marginNormal": {
    marginBottom: 0,
    marginTop: 0,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditSectionType(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [order, setOrder] = useState();
  const [status, setStatus] = useState(true);
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [temp_image, setTempImage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [is_load_data, setLoadData] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);

  const handlename = (event) => {
    setName(event.target.value);
  };
  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ category_id: x._id });
      } else {
        _catTag.push(x);
      }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();

    let _cattemplate = [];
    category.map((x, i) => {
      _cattemplate.push({ category_id: x.category_id });
    });
    if (!Image) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    } else {
      setiswaiting(true);
      let postdata = {
        name: name,
        status: status,
        order: order,
        category: _cattemplate,
        image: Image,
      };
      const template_edit_resp = await edit_Sectiontype(
        postdata,
        props.match.params.id
      );
      console.log(template_edit_resp, "RESPONSE");
      if (template_edit_resp.code == 200) {
        props.history.push("/section_type");
      } else {
        enqueueSnackbar(template_edit_resp.message, { variant: "error" });
      }
    }
    // let formData = new FormData(); //formdata object

    // formData.append("name", name);
    // formData.append("status", status);
    // formData.append("order", order);
    // formData.append("template_category", _cattemplate);
    // formData.append("image", Image);
  };
  const templateType_Detail = async () => {
    const template_detail = await get_sectiontype_detail(props.match.params.id);
    console.log(template_detail.section_type.name, "okokokokokok");
    setName(template_detail.section_type.name);
    setOrder(template_detail.section_type.order);
    setStatus(template_detail.section_type.status);
    setProfileImage(s3baseUrl + template_detail.section_type.image);
    setimage(template_detail.section_type.image);
    let _cat = [];
    categorydata.map((x, i) => {
      _cat.push({ category_id: x._id });
    });
    // setCategory(_cat);
    setCategory(template_detail.section_type.category);
    setLoadData(true);
  };
  const templateCategory_Detail = async () => {
    const templatecategory_detail = await get_sectioncategory_detail(
      props.match.params.id
    );
    console.log(templatecategory_detail, "template category");
    setCategorydata(templatecategory_detail.category);
    setLoadData(true);
  };
  console.log(category, "category from response");
  console.log(categorydata, "arrays");

  useEffect(() => {
    templateType_Detail();
    templateCategory_Detail();
  }, []);
  // useEffect(() => {
  //   templateCategory_Detail();
  // }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      {is_load_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Section Type
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    margin="normal"
                    className={(classes.txt, classes.jss6)}
                    required
                    fullWidth
                    value={name}
                    onChange={handlename}
                    label="Name"
                    name="name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    className={classes.jss6}
                    id="tags-filled"
                    options={categorydata}
                    // value={category}
                    defaultValue={category}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.template_category_id == value.template_category_id
                    }
                    onChange={(event, value) => handleChangeCategory(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Template Categories"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    margin="normal"
                    required
                    className={classes.jss6}
                    fullWidth
                    // type="number"
                    value={order}
                    InputLabelProps={{ shrink: true }}
                    // onChange={(e) => setOrder(e.target.value)}
                    onChange={(e) => {
                      if (e.target.value.match(/^$|[0-9]+$/))
                        setOrder(toInteger(e.target.value));
                    }}
                    label="Order"
                    name="order"
                  />
                </Grid>
                <Grid item xs={6}>
                  Status :
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                        name="checkedA"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {file ? (
                    <>
                      {" "}
                      <img className={classes.img} src={file} />{" "}
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/x-png,image/jpeg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    // directory=""
                    //   webkitdirectory=""
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      fullWidth
                      variant="outlined"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit Section Type
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
