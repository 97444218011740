/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ButtonGroup } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { useSnackbar } from "notistack";
import { default_image } from "../../../assets";
import { builder_pic_url, s3baseUrl } from "../../../config/config";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import {
  get_source_code_html,
  get_source_code_css,
  get_source_code_js,
  fetch_file_from_s3,
} from "../../../DAL/fetch_api/fetch_api";
import { upload_files } from "../../../DAL/section/section";
import {
  Template_Pages_List,
  get_file_listing,
} from "../../../DAL/templates/templates";
import Image_url_replace_Modal from "./Image_url_replace_Modal";
import Editor from "@monaco-editor/react";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },

  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditTemplateSourceCode(props) {
  console.log(props.match.params.id, "to change");
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(true);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]);

  const [imageData, setImageData] = React.useState([]);
  const [openImagesModal, setOpenImagesModal] = React.useState(false);

  const [name, setName] = useState("");
  const [CssFile, setCss] = useState("");
  const [htmlFile, setHtml] = useState("");
  const [JSFile, setJSFile] = useState("");
  const [sectiontype, setSectionType] = useState("");
  const [order, setOrder] = useState("");
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const [PagesList, setPagesList] = useState([]);
  const [SelectedPage, setSelectedPage] = useState("index");
  const [selectedTab, setSelectedTab] = useState("html");
  const [isBackPage, setIsBackPage] = useState(false);
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [dotTabs, setDotTabs] = useState({
    html: false,
    css: false,
    js: false,
  });
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  var myInit = {
    method: "GET",
    headers: myHeaders,
  };

  const handleback = () => {
    if (isCodeChanged) {
      handlePageBack();
    } else {
      history.goBack();
    }
  };

  const handlePageBack = () => {
    setIsBackPage(true);
  };

  const handleCodeCganged = (tab) => {
    setDotTabs((prevState) => ({
      ...prevState,
      [tab]: true,
    }));
    setIsCodeChanged(true);
    // console.log("chaaangeessCoodeeeeeeeeeeee");
  };

  const handlesubmit = async (data) => {
    data.preventDefault();
    setIsCodeChanged(false);
    setIsBackPage(false);
    // console.log(CssFile,"csss");
    // console.log(htmlFile,"htmlklllll");
    setiswaiting(true);
    let postdata = {
      project_id: props.match.params.id,
      html: htmlFile,
      css: CssFile,
      javascript: JSFile,
      type: "template",
      slug_name: SelectedPage,
    };
    const edit_file = await upload_files(postdata);
    if (edit_file.code == 200) {
      // props.history.push("/templates");
      setiswaiting(false);
      enqueueSnackbar(edit_file.message, { variant: "success" });
    } else {
      setiswaiting(false);
      enqueueSnackbar(edit_file.message, { variant: "error" });
    }
  };

  const checkAvailableImages = () => {
    const dynamicRegExp = new RegExp(`\\b${builder_pic_url}\\b`, "gi");
    // Use the regular expression to find all instances of the dynamic word in the input string
    let matches = [];
    let match;
    while ((match = dynamicRegExp.exec(htmlFile)) !== null) {
      matches.push({
        index: match.index,
        value: match[0],
      });
    }
    // Find all elements having links
    let all_links = [];
    matches.map((url) => {
      let starting_index = url.index;
      let ending_index = htmlFile.indexOf('"', starting_index);

      let link = htmlFile.slice(starting_index, ending_index);
      ending_index = link.indexOf("&quot;)");
      if (ending_index !== -1) {
        link = link.slice(0, ending_index);
      }

      if (!link.includes("/asserts/")) {
        return;
      }

      let sliceString = htmlFile.slice(0, starting_index);

      let element_starting_index = sliceString.lastIndexOf("<", starting_index);
      let element_ending_index = htmlFile.indexOf(">", starting_index);

      let element = htmlFile.slice(
        element_starting_index,
        element_ending_index + 1
      );

      let obj = {
        element: element,
        link: link,
      };
      all_links.push(obj);
    });

    // Get Sorted Links and to_replace links

    // let sorted_links = [];
    all_links.map((link, i) => {
      let link_to_replace = "";
      let name = "";
      if (link.element.includes("data-image_name")) {
        let image_name_starting_index = link.element.indexOf("data-image_name");
        let image_name_ending_index = link.element.indexOf(
          '"',
          image_name_starting_index
        );

        name = link.element.slice(
          image_name_ending_index + 1,
          link.element.indexOf('"', image_name_ending_index + 1)
        );

        data.map((asset) => {
          if (asset.Key === `${props.match.params.id}/assets/${name}`) {
            link_to_replace = s3baseUrl + asset.Key;
          }
        });

        // let obj = {
        //   link: link.link,
        //   element: link.element,
        //   image_name: name,
        //   link_to_replace: link_to_replace,
        // };
        // sorted_links.push(obj);
      }

      all_links[i].name = name;
      all_links[i].link_to_replace = link_to_replace;
      if (link_to_replace !== "") {
        all_links[i].change_image = false;
      } else {
        all_links[i].change_image = true;
      }
    });

    setImageData(all_links);

    setOpenImagesModal(true);
  };

  const updateAllUrls = () => {
    let htmlString = htmlFile;

    imageData.map((item) => {
      if (item.link_to_replace !== "") {
        htmlString = htmlString.replaceAll(item.link, item.link_to_replace);
      }
    });

    setHtml(htmlString);
    setOpenImagesModal(false);
    enqueueSnackbar("URL's Updated Successfully", { variant: "success" });
    console.log(htmlString, "updatedHTML");
  };

  const fetch_files_from_s3 = async () => {
    const html_file_path = `${s3baseUrl}${props.match.params.id}/${SelectedPage}.html`;
    const result_html = await fetch_file_from_s3(html_file_path);
    setHtml(result_html);

    const css_file_path = `${s3baseUrl}${props.match.params.id}/css/${
      SelectedPage == "index" ? "style" : SelectedPage
    }.css`;
    const result_css = await fetch_file_from_s3(css_file_path);
    setCss(result_css);

    const js_file_path = `${s3baseUrl}${props.match.params.id}/js/${SelectedPage}.js`;
    const result_js = await fetch_file_from_s3(js_file_path);
    setJSFile(result_js);
    setiswaiting(false);
  };

  const get_file = async () => {
    const postData = {
      path: props.match.params.id + "/assets/",
    };
    const list_resp = await get_file_listing(postData);

    if (list_resp.code === 200) {
      let dataWithCheckedKey = list_resp?.templates.map((item, i) => {
        return {
          ETag: item.ETag,
          Key: item.Key,
          LastModified: item.LastModified,
          Owner: item.Owner,
          Size: item.Size,
          StorageClass: item.StorageClass,
          checked: false,
        };
      });
      setData(dataWithCheckedKey);
      console.log(list_resp.templates, "file templates", dataWithCheckedKey);
    } else {
      enqueueSnackbar(list_resp.message, { variant: "error" });
    }
  };

  useEffect(async () => {
    setiswaiting(true);

    fetch_files_from_s3();
    get_file();
  }, [SelectedPage]);

  useEffect(async () => {
    let resp = await Template_Pages_List(props.match.params.id);
    if (resp.code == 200) {
      setPagesList(resp.Pages);
    }
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Image_url_replace_Modal
        open={openImagesModal}
        setOpen={setOpenImagesModal}
        data={imageData}
        updateAllUrls={updateAllUrls}
        sourceImages={data}
        template_id={props.match.params.id}
        setImageData={setImageData}
      />
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-1220px",
            }}
            onClick={handleback}
          />
          {/* add select after styleing to change source code and add new api to update according to slug */}
          {/* <select
            onChange={(e) => {
              setSelectedPage(e.target.value);
            }}
            value={SelectedPage}
            name="cars"
            id="cars"
          >
            {PagesList.length == 0 && <option value={"index"}>Index</option>}
            {PagesList.map((page) => (
              <option value={page.page_slug}>{page.page_slug}</option>
            ))}
          </select> */}
          <Typography className={classes.txt} component="h1" variant="h5">
            Edit Template
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              color="primary"
              size="small"
            >
              <Button
                style={{ width: "87px" }}
                variant={selectedTab === "html" ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab("html");
                }}
              >
                HTML{" "}
                {dotTabs.html && (
                  <Icon style={{ fontSize: 15 }}>fiber_manual_record</Icon>
                )}
              </Button>
              <Button
                style={{ width: "87px" }}
                variant={selectedTab === "css" ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab("css");
                }}
              >
                CSS{" "}
                {dotTabs.css && (
                  <Icon style={{ fontSize: 15 }}>fiber_manual_record</Icon>
                )}
              </Button>
              <Button
                style={{ width: "87px" }}
                variant={selectedTab === "js" ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab("js");
                }}
              >
                JS{" "}
                {dotTabs.js && (
                  <Icon style={{ fontSize: 15 }}>fiber_manual_record</Icon>
                )}
              </Button>
            </ButtonGroup>
            <FormControl>
              <InputLabel id="test-select-label">Page</InputLabel>
              <Select
                sx={{
                  width: 150,
                  fontSize: 14,
                  color: "black",
                }}
                labelId="test-select-label"
                label="Page"
                onChange={(e) => {
                  setSelectedPage(e.target.value);
                }}
                value={SelectedPage}
                className="border-end"
              >
                {PagesList.length == 0 && (
                  <MenuItem value={"index"}>Index</MenuItem>
                )}
                {PagesList &&
                  PagesList.length > 0 &&
                  PagesList.map((page) => (
                    <MenuItem value={page.page_slug}>{page.page_slug}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              size="small"
              className={classes.default_Theme_btn}
              variant="contained"
              color="primary"
              onClick={checkAvailableImages}
            >
              Check All Available Images
            </Button>
          </div>
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3}>
              {selectedTab === "html" ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <div className="editor">
                    <Typography variant="subtitle2">HTML:</Typography>
                    <Editor
                      height="80vh"
                      defaultLanguage="html"
                      defaultValue="// some comment"
                      value={htmlFile}
                      id="html"
                      onChange={(newValue, event) => {
                        setHtml(newValue);
                        handleCodeCganged("html");
                      }}
                      label="HTML"
                      name="html"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}

              {selectedTab === "css" ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <div className="editor">
                    <Typography variant="subtitle2">CSS:</Typography>
                    <Editor
                      height="80vh"
                      defaultLanguage="css"
                      defaultValue="// some comment"
                      value={CssFile}
                      id="css"
                      onChange={(newValue, event) => {
                        setCss(newValue);
                        handleCodeCganged("css");
                      }}
                      label="CSS"
                      name="css"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}

              {selectedTab === "js" ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <div className="editor">
                    <Typography variant="subtitle2">Javascript:</Typography>
                    <Editor
                      height="80vh"
                      defaultLanguage="javascript"
                      defaultValue="// some comment"
                      value={JSFile}
                      id="js"
                      onChange={(newValue, event) => {
                        setJSFile(newValue);
                        handleCodeCganged("js");
                      }}
                      label="JavaScript"
                      name="js"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      <Dialog
        open={isBackPage}
        onClose={() => setIsBackPage(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "15px 20px 15px 20px" }}
        >
          You have unsaved changes. Would you like to save them?
        </DialogTitle>
        <DialogActions
          style={{
            padding: "15px 20px 20px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              setIsBackPage(false);
            }}
            size="small"
          >
            Cancel
          </Button>
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
                setIsBackPage(false);
                setIsCodeChanged(false);
                setDotTabs(false);
              }}
              size="small"
              className="me-2"
              color="primary"
            >
              Discard Changes
            </Button>
            <Button
              variant="contained"
              onClick={async (e) => {
                history.goBack();
                handlesubmit(e);
                setDotTabs(false);
              }}
              size="small"
              color="primary"
            >
              Save Changes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
