export const template_type = [
  { value: "0", label: "All" },
  { value: "1", label: "Inovate" },
  { value: "2", label: "Magnetizer" },
  { value: "3", label: "Vision" },
];
export const project_type = [
  { value: "0", label: "Free" },
  { value: "1", label: "Paid" },
];
export const is_Paid = [
  { value: true, label: "YES" },
  { value: false, label: "NO" },
];
export const font_weight = [
  { value: "normal", label: "normal" },
  { value: "bold", label: "bold" },
  { value: "bolder", label: "bolder" },
  { value: "lighter", label: "lighter" },
  { value: "initial", label: "initial" },
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "300", label: "300" },
  { value: "400", label: "400" },
  { value: "500", label: "500" },
  { value: "600", label: "600" },
  { value: "700", label: "700" },
  { value: "800", label: "800" },
  { value: "900", label: "900" },
];
export const line_Height = [
  { value: "1", label: "1" },
  { value: "1.25", label: "1.25" },
  { value: "1.5", label: "1.5" },
  { value: "1.75", label: "1.75" },
  { value: "2", label: "2" },
  { value: "2.25", label: "2.25" },
  { value: "2.5", label: "2.5" },
  { value: "2.75", label: "2.75" },
  { value: "3", label: "3" },
  { value: "3.25", label: "3.25" },
  { value: "3.5", label: "3.5" },
  { value: "3.75", label: "3.75" },
  { value: "4", label: "4" },
];

export const color_array = [
  "#000000",
  "#FFFFFF",
  "#808080",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#EB9694",
];
export const options = () => {
  const start = 1;
  const limit = 50;
  let option = [];
  for (let i = start; i <= limit; i++) {
    option.push({
      label: `${i}px`,
      value: `${(i / 16).toFixed(3)}rem`,
    });
  }
  return option;
};

export const google_font_family_classes = [
  {
    css_rule: "'Roboto', sans-serif",
    label: "Default",
    value: "",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Mukta', sans-serif",
    label: "Muktra",
    value: "font_muktra",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap')",
  },
  {
    css_rule: "'Sora', sans-serif",
    label: "Sora",
    value: "font_sora",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap')",
  },
  {
    css_rule: "'Hahmlet', serif",
    label: "Hahmlet",
    value: "font_Hahmlet",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Andada Pro', serif",
    label: "Andada Pro",
    value: "font_andada-pro",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Work Sans', sans-serif",
    label: "Work Sans",
    value: "font_work-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Raleway', sans-serif",
    label: "Raleway",
    value: "font_raleway",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Spectral', serif",
    label: "Spectral",
    value: "font_spectral",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Cormorant', serif",
    label: "Cormorant",
    value: "font_cormorant",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Archivo', sans-serif",
    label: "Archivo",
    value: "font_archivo",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Playfair Display', serif",
    label: "Playfair",
    value: "font_playfair",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'BioRhyme', serif",
    label: "BioRhyme",
    value: "font_biorhyme",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap')",
  },
  {
    css_rule: "'Lora', serif",
    label: "Lora",
    value: "font_lora",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Manrope', sans-serif",
    label: "Manrope",
    value: "font_manrope",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap')",
  },
  {
    css_rule: "'Encode Sans', sans-serif",
    label: "Encode Sans",
    value: "font_encode-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Inter', sans-serif",
    label: "Inter",
    value: "font_inter",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Epilogue', sans-serif",
    label: "Epilogue",
    value: "font_epilogue",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Open Sans', sans-serif",
    label: "Open Sans",
    value: "font_open-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Oxygen', sans-serif",
    label: "Oxygen",
    value: "font_oxygen",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap')",
  },
  {
    css_rule: "'Source Sans Pro', sans-serif",
    label: "Source Sans Pro",
    value: "font_source-sans-pro",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Nunito', sans-serif",
    label: "Nunito",
    value: "font_nunito",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Poppins', sans-serif",
    label: "Poppins",
    value: "font_poppins",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Montserrat', sans-serif",
    label: "Montserrat",
    value: "font_montserrat",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Oswald', sans-serif",
    label: "Oswald",
    value: "font_oswald",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap')",
  },
  {
    css_rule: "'Old Standard TT', serif",
    label: "Old Standard TT",
    value: "font_old-standard-tt",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap')",
  },
  {
    css_rule: "'Anton', sans-serif",
    label: "Anton",
    value: "font_anton",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap')",
  },
  {
    css_rule: "'Lato', sans-serif",
    label: "Lato",
    value: "font_lato",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Philosopher', sans-serif",
    label: "Philosopher",
    value: "font_philosopher",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap')",
  },
  {
    css_rule: "'Sanchez', serif",
    label: "Sanchez",
    value: "font_sanchez",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap')",
  },
  {
    css_rule: "'Rubik', sans-serif",
    label: "Rubik",
    value: "font_rubik",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Rokkitt', serif",
    label: "Rokkitt",
    value: "font_rokkitt",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Rambla', sans-serif",
    label: "Rambla",
    value: "font_rambla",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Rambla:ital,wght@0,400;0,700;1,400;1,700&display=swap')",
  },
  {
    css_rule: "'Noto Sans', sans-serif",
    label: "Noto Sans",
    value: "font_noto-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap')",
  },
  {
    css_rule: "'Mulish', sans-serif",
    label: "Mulish",
    value: "font_mulish",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Merriweather', sans-serif",
    label: "Merriweather",
    value: "font_merriweather",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Karla', sans-serif",
    label: "Karla",
    value: "font_karla",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Josefin Sans', sans-serif",
    label: "Josefin Sans",
    value: "font_josefin-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Fira Sans', sans-serif",
    label: "Fira Sans",
    value: "font_fira-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Fjalla One', sans-serif",
    label: "Fjalla One",
    value: "font_fjalla-one",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap')",
  },
  {
    css_rule: "'DM Sans', sans-serif",
    label: "DM Sans",
    value: "font_dm-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap')",
  },
  {
    css_rule: "'Concert One', cursive",
    label: "Concert One",
    value: "font_concert-one",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap')",
  },
  {
    css_rule: "'Cardo', serif",
    label: "Cardo",
    value: "font_cardo",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap')",
  },
  {
    css_rule: "'Cabin', sans-serif",
    label: "Cabin",
    value: "font_cabin",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Bree Serif', serif",
    label: "Bree Serif",
    value: "font_bree-sarif",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap')",
  },
  {
    css_rule: "'Arimo', sans-serif",
    label: "Arimo",
    value: "font_arimo",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Abril Fatface', cursive",
    label: "Abril Fatface",
    value: "font_abril-fatface",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap')",
  },
  {
    css_rule: "'Readex Pro', sans-serif",
    label: "Readex Pro",
    value: "font_readex-pro",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap')",
  },
  {
    css_rule: "'Ubuntu', sans-serif",
    label: "Ubuntu",
    value: "font_ubuntu",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap')",
  },
  {
    css_rule: "'Courgette', cursive",
    label: "Courgette",
    value: "font_courgette",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap')",
  },
  {
    css_rule: "'Saira', sans-serif",
    label: "Saira",
    value: "font_saira",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: `"Lobster Two", cursive`,
    label: "Lobster Two",
    value: "font_lobster_two",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');`,
  },
  {
    css_rule: `"Roboto", sans-serif`,
    label: "Roboto",
    value: "font-roboto",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');`,
  },
  {
    css_rule: `"Heebo", sans-serif`,
    label: "Heebo",
    value: "font-heebo",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');`,
  },

  {
    css_rule: `"Teko", sans-serif`,
    label: "Teko",
    value: "font_teko",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');`,
  },

  {
    css_rule: `"Bebas Neue"`,
    label: "Bebas Neue",
    value: "font_bebas_Neue",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');`,
  },

  {
    css_rule: `"Manrope"`,
    label: "Manrope",
    value: "font_manrope",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');`,
  },

  {
    css_rule: `"Nova Square"`,
    label: "Nova Square",
    value: "font_nova_square",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Nova+Square&display=swap');`,
  },

  {
    css_rule: `"Palette Mosaic"`,
    label: "Palette Mosaic",
    value: "font_palette_mosaic",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Palette+Mosaic&display=swap');`,
  },

  {
    css_rule: `"Hurricane"`,
    label: "Hurricane",
    value: "font_hurricane",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');`,
  },

  {
    css_rule: `Red Hat Mono`,
    label: "Red Hat Mono",
    value: "font_red_hat_mono",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');`,
  },
  {
    css_rule: `Dancing Script`,
    label: "Dancing Script",
    value: "font_dancing_script",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');`,
  },
  {
    css_rule: `Kaushan Script`,
    label: "kaushan Script",
    value: "font_kaushan_script",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');`,
  },
  {
    css_rule: `Square Peg`,
    label: "Square Peg",
    value: "font_square_peg",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');`,
  },
  {
    css_rule: `Libre Bodoni`,
    label: "Libre Bodoni",
    value: "font_libre_bodoni",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');`,
  },
  {
    css_rule: "'Black Han Sans', sans-serif",
    label: "Black Han Sans",
    value: "font_black_han_sans",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');`,
  },
  {
    css_rule: "'Lustria', serif",
    label: "Lustria",
    value: "font_lustria",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Lustria&display=swap');`,
  },
  {
    css_rule: "'PT Serif',  serif",
    label: "PTSerif",
    value: "font_pt_serif",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');",
  },
  {
    css_rule: "'PT Serif Caption',serif",
    label: "PTSerifCaption",
    value: "font_pt_serif_caption",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=PT+Serif+Caption:ital@0;1&display=swap');",
  },
  {
    css_rule: "'Poiret One', cursive",
    label: "PoiretOne",
    value: "font_poiret_one",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');",
  },
  {
    css_rule: "'Libre Baskerville', serif",
    label: "LibreBaskerville",
    value: "font_libre_baskerville",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');",
  },
  {
    css_rule: "'IBM Plex Sans', sans-serif",
    label: "IBMPlexSans",
    value: "font_ibm_plex_sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'IBM Plex Serif', serif",
    label: "IBMPlexSerif",
    value: "ibm_plex_serif",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Jost', sans-serif",
    label: "Jost",
    value: "font_jost",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');",
  },
  {
    css_rule: "'Charis SIL', serif",
    label: "CharisSIL",
    value: "font_charis_sil",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Charis+SIL:ital,wght@0,400;0,700;1,400;1,700&display=swap')",
  },
  {
    css_rule: "'Barlow', sans-serif",
    label: "Barlow",
    value: "font_barlow",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Prata', serif",
    label: "Prata",
    value: "font_prata",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Prata&display=swap');",
  },
  {
    css_rule: "'Mrs Saint Delafield', cursive",
    label: "MrsSaintDelafield",
    value: "font_mrs_saint_delafield",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap');",
  },
  {
    css_rule: "'Sarabun', sans-serif",
    label: "Sarabun",
    value: "font_sarabun",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');",
  },
  {
    css_rule: "'Amatic SC', sans-serif",
    label: "Amatic SC",
    value: "font_Amatic_SC",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');",
  },
  {
    css_rule: "'Palanquin Dark', sans-serif",
    label: "Palanquin Dark",
    value: "font_palanquin_dark",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&family=Palanquin+Dark:wght@400;500;600;700&display=swap');",
  },
  {
    css_rule: "'Figtree', sans-serif",
    label: "Figtree",
    value: "font_figtree",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');",
  },
  {
    css_rule: "'EB Garamond', sans-serif",
    label: "EB Garamond",
    value: "font_eb_garamond",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');",
  },
  {
    css_rule: "'Quattrocento', sans-serif",
    label: "Quattrocento",
    value: "font_quattrocento",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap');",
  },
  {
    css_rule: "'Exo 2',sans-serif",
    label: "Exo 2",
    value: "font_exo_2",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');",
  },
  {
    css_rule: "'Titillium Web',sans-serif",
    label: "Titillium Web",
    value: "font_Titillium_Web",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700&display=swap');",
  },
  {
    css_rule: "'Tenor Sans',sans-serif",
    label: "Tenor Sans",
    value: "font_tenor_sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');",
  },
  {
    css_rule: "'Kumbh Sans',sans-serif",
    label: "Kumbh Sans",
    value: "font_kumbh_sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');",
  },
  {
    css_rule: "'Outfit',sans-serif",
    label: "Outfit",
    value: "font_Outfit",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&display=swap');",
  },
  {
    css_rule: "'Dosis',sans-serif",
    label: "Dosis",
    value: "font_dosis",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');",
  },
  {
    css_rule: "'Plus Jakarta Sans', sans-serif",
    label: "Plus Jakarta Sans",
    value: "font_PlusJakartaSans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');",
  },
  {
    css_rule: "'Quicksand', sans-serif",
    label: "Quicksand",
    value: "font_Quicksand",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');",
  },
  {
    css_rule: "'Baloo 2', sans-serif",
    label: "Baloo 2",
    value: "font_Baloo_2",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');",
  },
  {
    css_rule: "'Lexend', sans-serif",
    label: "Lexend",
    value: "font_Lexend",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');",
  },
  {
    css_rule: "'Elsie', serif",
    label: "Elsie",
    value: "font_Elsie",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Elsie:wght@400;900&display=swap');",
  },

  {
    css_rule: "'Urbanist', sans-serif",
    label: "Urbanist",
    value: "font_Urbanist",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Elsie:wght@400;900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');",
  },
  {
    css_rule: "'Cabin', sans-serif",
    label: "Cabin",
    value: "font_Cabin",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');",
  },
];
