import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Select,
  TextField,
  FormControl,
  MenuItem,
  Divider,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Chip from "@material-ui/core/Chip";
import { useSnackbar } from "notistack";
import { TwitterPicker } from "react-color";
import {
  get_template_detail,
  edit_Template,
  get_category_detail,
  upload_image,
} from "../../../DAL/templates/templates";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import {
  color_array,
  options,
  font_weight,
  line_Height,
  template_type,
  google_font_family_classes,
  project_type,
  is_Paid,
} from "../../../ui_libs/constants";
import { template_category_list } from "../../../DAL/template_categories/TemplateCategories";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  default_Theme_btn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EditTemplates(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [isuser, setusers] = React.useState(false);
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [templateCategorydata, setTemplateCategorydata] = React.useState([]); //ok
  const [templateCategory, setTemplateCategory] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [imagepath, setImagepath] = React.useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [mode, setMode] = useState("");
  const [primarybackground, setPrimaryBackground] = useState("");
  const [secondarybackground, setSecondaryBackground] = useState("");
  const [primarytextcolour, setPrimaryTextColour] = useState("");
  const [secondarytextcolour, setSecondaryTextColour] = useState("");
  const [order, setOrder] = useState("");
  const [hoverTextColor, sethoverTextColor] = useState("");
  const [hoverBackgroundColor, sethoverBackgroundColor] = useState("");
  const [textFontFamily, settextFontFamily] = useState("");
  const [themeBoxShadow, setthemeBoxShadow] = useState("");
  const [themeH1FontSize, setthemeH1FontSize] = useState("");
  const [themeH2FontSize, setthemeH2FontSize] = useState("");
  const [themeH3FontSize, setthemeH3FontSize] = useState("");
  const [themeH4FontSize, setthemeH4FontSize] = useState("");
  const [themePFontSize, setthemePFontSize] = useState("");
  const [themeLineHeight, setthemeLineHeight] = useState("");
  const [themeH1FontWeight, setthemeH1FontWeight] = useState("");
  const [themeH2FontWeight, setthemeH2FontWeight] = useState("");
  const [themeH3FontWeight, setthemeH3FontWeight] = useState("");
  const [themeH4FontWeight, setthemeH4FontWeight] = useState("");
  const [themePFontWeight, setthemePFontWeight] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [projectType, setProjectType] = useState("0");
  const [state, setState] = React.useState({
    status: true,
    approved: true,
    completed: true,
    published: true,
  });
  const [isPaid, setIsPaid] = useState(false);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [discount, setDiscount] = useState();
  const [ribbon, setRibbon] = useState("");
  const [discountError, setDiscountError] = useState(false);

  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      if (x._id) {
        _catTag.push({ template_category_id: x._id });
      } else {
        _catTag.push({ template_category_id: x.template_category_id });
      }
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };
  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    console.log(category);
    let _cattemplate = [];
    category.map((x, i) => {
      if (x._id) {
        _cattemplate.push({ category_id: x._id });
      } else {
        _cattemplate.push({ category_id: x.template_category_id });
      }
    });
    console.log(_cattemplate, "template id");
    if (!Image) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    } else {
      setiswaiting(true);
      let postdata = {
        name: name,
        mode: mode,
        order: order.toString(),
        category: _cattemplate,
        template_category: templateCategory,
        // theme_primary_color: primarybackground,
        // theme_secondary_color: secondarybackground,
        // text_primary_color: primarytextcolour,
        // text_secondary_color: secondarytextcolour,
        // hover_text_color: hoverTextColor,
        // hover_background_color: hoverBackgroundColor,
        // text_font_family: textFontFamily,
        // theme_box_shadow: themeBoxShadow,
        // theme_h1_font_size: themeH1FontSize,
        // theme_h2_font_size: themeH2FontSize,
        // theme_h3_font_size: themeH3FontSize,
        // theme_h4_font_size: themeH4FontSize,
        // theme_p_font_size: themePFontSize,
        // theme_h1_font_weight: themeH1FontWeight,
        // theme_h2_font_weight: themeH2FontWeight,
        // theme_h3_font_weight: themeH3FontWeight,
        // theme_h4_font_weight: themeH4FontWeight,
        // theme_p_font_weight: themePFontWeight,
        // theme_line_height: themeLineHeight,
        status: state.status,
        is_completed: state.completed,
        app_type: templateType.toString(),
        is_approved: state.approved,
        is_published: state.published,
        image: Image,
        type: projectType,
        is_paid: isPaid,
        amount: amount,
        currency: currency,
        discount: discount,
        ribbon: ribbon,
      };
      const country_edit_resp = await edit_Template(
        postdata,
        props.match.params.id
      );
      if (country_edit_resp.code == 200) {
        handleback();
        // props.history.push("/templates");
      } else {
        setiswaiting(false);
        enqueueSnackbar(country_edit_resp.message, { variant: "error" });
      }
    }
  };

  const get_template = async () => {
    const template = await get_template_detail(props.match.params.id);
    console.log(template, "dataaaaaa");
    if (template.code == 200) {
      console.log(template.template, "template");
      setName(template.template.name);
      setType(template.template.app_type);
      setState({
        ...state,
        status: template.template.status,
        approved: template.template.is_approved,
        completed: template.template.is_completed,
        published: template.template.is_published,
      });
      if (template.template.template_category._id) {
        setTemplateCategory(template.template.template_category._id);
      }
      setOrder(template.template.order);
      setMode(template.template.mode);
      setPrimaryBackground(template.template.theme_primary_color);
      setSecondaryBackground(template.template.theme_secondary_color);
      setPrimaryTextColour(template.template.text_primary_color);
      setSecondaryTextColour(template.template.text_secondary_color);
      sethoverTextColor(template.template.hover_text_color);
      sethoverBackgroundColor(template.template.hover_background_color);
      settextFontFamily(template.template.text_font_family);
      setthemeBoxShadow(template.template.theme_box_shadow);
      setthemeH1FontSize(template.template.theme_h1_font_size);
      setthemeH2FontSize(template.template.theme_h2_font_size);
      setthemeH3FontSize(template.template.theme_h3_font_size);
      setthemeH4FontSize(template.template.theme_h4_font_size);
      setthemePFontSize(template.template.theme_p_font_size);
      setthemeH1FontWeight(template.template.theme_h1_font_weight);
      setthemeH2FontWeight(template.template.theme_h2_font_weight);
      setthemeH3FontWeight(template.template.theme_h3_font_weight);
      setthemeH4FontWeight(template.template.theme_h4_font_weight);
      setTemplateType(template.template.app_type);
      setthemePFontWeight(template.template.theme_p_font_weight);
      setthemeLineHeight(template.template.theme_line_height);
      // setisApproved(template.template.is_approved);
      // setisCompleted(template.template.is_completed);
      // setisPublished(template.template.is_published);
      setCategory(template.template.category);
      setProfileImage(s3baseUrl + template.template.image);
      setimage(template.template.image);
      setProjectType(template.template.type);
      setIsPaid(template.template.is_paid);
      setAmount(template.template.amount);
      setCurrency(template.template.currency);
      setDiscount(template.template.discount);
      setRibbon(template.template.ribbon);
    }
    setusers(true);
  };
  const defaultTheme = () => {
    setMode("light");
    setPrimaryBackground("#FFFFFF");
    setSecondaryBackground("#eb144c");
    setPrimaryTextColour("#000000");
    setSecondaryTextColour("#FFFFFF");
    sethoverTextColor("#FFFFFF");
    sethoverBackgroundColor("#eb144c");
    settextFontFamily("'Roboto', sans-serif");
    setthemeBoxShadow("#ABB8C3");
    setthemeH1FontSize("2.500rem");
    setthemeH2FontSize("2.000rem");
    setthemeH3FontSize("1.750rem");
    setthemeH4FontSize("1.500rem");
    setthemePFontSize("1.000rem");
    setthemeLineHeight("1.5");
    setthemeH1FontWeight("500");
    setthemeH2FontWeight("500");
    setthemeH3FontWeight("500");
    setthemeH4FontWeight("500");
    setthemePFontWeight("500");
  };
  //console.log(hoverTextColor);
  const get_category = async () => {
    const category_detail = await get_category_detail(props.match.params.id);
    console.log(category_detail.category, "category show krwaani");
    setCategorydata(category_detail.category);
  };
  const get_template_category = async () => {
    const templatecategory_detail = await template_category_list();
    setTemplateCategorydata(templatecategory_detail.template_category);
  };
  useEffect(async () => {
    await get_template();
    await get_category();
    await get_template_category();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }
  // console.log(mode, "Mode of the theme");
  // console.log(state, "state.status");
  // console.log(state.approved, "state.approved");
  // console.log(state.completed, "state.completed");
  // console.log(themeH1FontSize, "themeH1FontSize");
  // console.log(templateType, "template type");
  console.log(themeH4FontWeight, "h4 font weight");

  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Template
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    className={classes.txt}
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Name"
                    name="name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={categorydata}
                    // value={category}
                    defaultValue={category}
                    getOptionLabel={(option) => option.name}
                    // getOptionSelected={(option, value) =>
                    //   option.template_category_id == value.template_category_id
                    // }
                    onChange={(event, value) => handleChangeCategory(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Categories"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.txt}
                    style={{ minWidth: 550 }}
                  >
                    <InputLabel id="Type">Template Category</InputLabel>
                    <Select
                      labelId="Template-Category"
                      fullWidth
                      label="Template Category"
                      value={templateCategory}
                      onChange={(e) => {
                        setTemplateCategory(e.target.value);
                      }}
                    >
                      {templateCategorydata.map((option) => {
                        return (
                          <MenuItem value={option._id}>{option.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.txt}
                    style={{ minWidth: 550 }}
                  >
                    <InputLabel id="Type">Template Type</InputLabel>
                    <Select
                      labelId="Type"
                      autoWidth
                      label="Template Type"
                      value={templateType}
                      onChange={(e) => {
                        const selectedType = e.target.value;
                        setTemplateType(selectedType);
                      }}
                    >
                      {template_type.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.txt}
                    style={{ minWidth: 550 }}
                  >
                    <InputLabel id="Type">Project Type</InputLabel>
                    <Select
                      labelId="Type"
                      fullWidth
                      label="Project Type"
                      value={projectType}
                      onChange={(e) => {
                        const selectedType = e.target.value;
                        setProjectType(selectedType);
                      }}
                    >
                      {project_type.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider style={{ width: "100%", marginTop: "13px" }} />

                <Grid item xs={12} sm={4} md={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="Type">Is Paid</InputLabel>
                    <Select
                      labelId="Type"
                      label="Is Paid"
                      value={isPaid}
                      onChange={(e) => {
                        const is_paid = e.target.value;
                        setIsPaid(is_paid);
                      }}
                    >
                      {is_Paid.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      fullWidth
                      required
                      label="Currency"
                      name="currency"
                      disabled={isPaid === false}
                      value={isPaid === true ? currency : ""}
                      // type="number"
                      onChange={(e) => {
                        const is_paid = e.target.value;
                        setCurrency(is_paid);
                      }}
                      // InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      fullWidth
                      required
                      disabled={isPaid === false}
                      label="Amount"
                      name="amount"
                      value={isPaid === true ? amount : ""}
                      onChange={(e) => {
                        const is_paid = e.target.value;
                        setAmount(is_paid);
                      }}
                      // InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <TextField
                      fullWidth
                      label="Discount"
                      name="discount"
                      disabled={isPaid === false}
                      value={isPaid === true ? discount : ""}
                      error={discountError}
                      helperText={
                        discountError
                          ? `Discount cannot be greater than amount`
                          : ""
                      }
                      onChange={(e) => {
                        const newDiscount = parseFloat(e.target.value);
                        if (!isNaN(newDiscount) && newDiscount < amount) {
                          setDiscount(newDiscount);
                          setDiscountError(false);
                        } else if (newDiscount > amount) {
                          setDiscountError(true);
                          // setDiscount("");
                        } else if (newDiscount < amount) {
                          setDiscountError(false);
                          setDiscount("");
                        } else {
                          setDiscountError(false);
                          setDiscount("");
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Ribbon"
                      placeholder="You can enter only 15 characters"
                      name="ribbon"
                      value={isPaid === true ? ribbon : ""}
                      disabled={isPaid === false}
                      onChange={(e) => {
                        const inputValue = e.target.value.slice(0, 15);
                        setRibbon(inputValue);
                      }}
                      inputProps={{ maxLength: 15 }}
                      // disabled={ribbon.length === 14}
                      // InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12}>
                  <Button 
                    size="small"
                    className={classes.default_Theme_btn}
                    variant="contained"
                    color="primary"
                    onClick={defaultTheme}
                  >
                    Set Default Theme Values
                  </Button>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <p>Primary Background</p>
                  <TwitterPicker
                    color={primarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Background</p>
                  <TwitterPicker
                    color={secondarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Text Color</p>
                  <TwitterPicker
                    color={hoverTextColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverTextColor(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Background Color</p>
                  <TwitterPicker
                    color={hoverBackgroundColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverBackgroundColor(updateColor.hex)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <p>Primary Text Colour</p>
                  <TwitterPicker
                    color={primarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Text Colour</p>
                  <TwitterPicker
                    color={secondarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>Box Shadow</p>
                  <TwitterPicker
                    color={themeBoxShadow}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setthemeBoxShadow(updateColor.hex)
                    }
                  />
                </Grid> */}
                <Divider style={{ width: "100%", marginTop: "13px" }} />
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                    sx={{ marginTop: "16px" }}
                  >
                    <InputLabel id="mode">Mode</InputLabel>
                    <Select
                      labelId="mode"
                      // fullWidth
                      label="Mode"
                      value={mode}
                      onChange={(e) => {
                        const selectedMode = e.target.value;
                        setMode(selectedMode);
                      }}
                    >
                      <MenuItem value="dark">Dark</MenuItem>
                      <MenuItem value="light">Light</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      // fullWidth
                      type="number"
                      id="order"
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      label="Order"
                      name="order"
                    />
                  </FormControl>
                </Grid>
                <Divider style={{ width: "100%", marginTop: "13px" }} />
                {/* <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="font_family">Font Family</InputLabel>
                    <Select
                      labelId="font_family"
                      fullWidth
                      label="font family"
                      value={textFontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextFontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="line_height">Line Height</InputLabel>
                    <Select
                      labelId="line_height"
                      fullWidth
                      label="line height"
                      value={themeLineHeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeLineHeight(selectedSize);
                      }}
                    >
                      {line_Height.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Size</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Size"
                      value={themeH1FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Size</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Size"
                      value={themeH2FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontSize(selectedSize);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Font Size
                      </MenuItem>
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Size</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Size"
                      value={themeH3FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontSize(selectedSize);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Font Size
                      </MenuItem>
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H4">H4 Font Size</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Size"
                      value={themeH4FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Size</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Size"
                      value={themePFontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Weight</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Weight"
                      value={themeH1FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Weight</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Weight"
                      value={themeH2FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Weight</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Weight"
                      value={themeH3FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H4">H4 Font Weight</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Weight"
                      value={themeH4FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Weight</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Weight"
                      value={themePFontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={3}>
                  Status :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={state.status}
                        onChange={handleChange}
                        name="status"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  Approved :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        onChange={handleChange}
                        checked={state.approved}
                        name="approved"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  Completed :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={state.completed}
                        onChange={handleChange}
                        name="completed"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  Published :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={state.published}
                        onChange={handleChange}
                        name="published"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {file ? (
                    <>
                      {" "}
                      <img className={classes.img} src={file} />{" "}
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/x-png,image/jpeg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      fullWidth
                      variant="outlined"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Update Template
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
