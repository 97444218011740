import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TwitterPicker } from "react-color";
import {
  get_project_detail,
  edit_Project,
  upload_image,
  get_templatecategory_detail,
} from "../../../DAL/project/projects";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import {
  color_array,
  options,
  font_weight,
  line_Height,
} from "../../../ui_libs/constants";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  default_Theme_btn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  category_field: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EditProject(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [isuser, setusers] = React.useState(false);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [imagepath, setImagepath] = React.useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [mode, setMode] = useState("");
  const [primarybackground, setPrimaryBackground] = useState("");
  const [secondarybackground, setSecondaryBackground] = useState("");
  const [primarytextcolour, setPrimaryTextColour] = useState("");
  const [secondarytextcolour, setSecondaryTextColour] = useState("");
  const [order, setOrder] = useState("");
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [hoverTextColor, sethoverTextColor] = useState("");
  const [hoverBackgroundColor, sethoverBackgroundColor] = useState("");
  const [textFontFamily, settextFontFamily] = useState("");
  const [themeBoxShadow, setthemeBoxShadow] = useState("");
  const [themeH1FontSize, setthemeH1FontSize] = useState("");
  const [themeH2FontSize, setthemeH2FontSize] = useState("");
  const [themeH3FontSize, setthemeH3FontSize] = useState("");
  const [themeH4FontSize, setthemeH4FontSize] = useState("");
  const [themePFontSize, setthemePFontSize] = useState("");
  const [themeLineHeight, setthemeLineHeight] = useState("");
  const [themeH1FontWeight, setthemeH1FontWeight] = useState("");
  const [themeH2FontWeight, setthemeH2FontWeight] = useState("");
  const [themeH3FontWeight, setthemeH3FontWeight] = useState("");
  const [themeH4FontWeight, setthemeH4FontWeight] = useState("");
  const [themePFontWeight, setthemePFontWeight] = useState("");
  const [state, setState] = React.useState({
    status: true,
    complete: true,
    approved: true,
    published: true,
  });
  const [projectCategory, setProjectCategory] = useState("");

  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChangeCategory = (value) => {
    console.log(value, "value");
    // let _catTag = [];
    // value.map((x, i) => {
    //   if (x._id) {
    //     _catTag.push({ template_category_id: x._id });
    //   } else {
    //     _catTag.push({ template_category_id: x.template_category_id });
    //   }
    // });
    // setCategory(_catTag);
    // console.log(_catTag, "final tags");
  };
  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("project_name", "category");
    formData.append("upload_file", e.target.files[0]);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp);
    setimage(upload_image_resp.file_name);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
    }
    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    if (!Image) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    } else {
      setiswaiting(true);
      let postdata = {
        project_name: name,
        mode: mode,
        //order: order.toString(),
        project_category: category,
        theme_primary_color: primarybackground,
        theme_secondary_color: secondarybackground,
        text_primary_color: primarytextcolour,
        text_secondary_color: secondarytextcolour,
        hover_text_color: hoverTextColor,
        hover_background_color: hoverBackgroundColor,
        text_font_family: textFontFamily,
        theme_box_shadow: themeBoxShadow,
        theme_h1_font_size: themeH1FontSize,
        theme_h2_font_size: themeH2FontSize,
        theme_h3_font_size: themeH3FontSize,
        theme_h4_font_size: themeH4FontSize,
        theme_p_font_size: themePFontSize,
        theme_h1_font_weight: themeH1FontWeight,
        theme_h2_font_weight: themeH2FontWeight,
        theme_h3_font_weight: themeH3FontWeight,
        theme_h4_font_weight: themeH4FontWeight,
        theme_p_font_weight: themePFontWeight,
        theme_line_height: themeLineHeight,
        status: state.status,
        is_completed: state.complete,
        is_approved: state.approved,
        is_published: state.published,
        image: Image,
      };
      const project_edit_resp = await edit_Project(
        postdata,
        props.match.params.id
      );
      if (project_edit_resp.code == 200) {
        props.history.push("/project");
      } else {
        setiswaiting(false);
        enqueueSnackbar(project_edit_resp.message, { variant: "error" });
      }
    }
  };

  const get_project = async () => {
    const detail_project = await get_project_detail(props.match.params.id);
    console.log(detail_project.data.project, "detail_project");
    const project = detail_project.data.project;
    console.log(project.project_category, "project details");
    if (detail_project.code == 200) {
      setName(project.project_name);
      setType(project.type);
      setState({
        ...state,
        status: project.status,
        approved: project.is_approved,
        complete: project.is_completed,
        published: project.is_published,
      });
      setOrder(project.order);
      setMode(project.mode);
      //setProjectCategory(project.project_category);
      setPrimaryBackground(project.theme_primary_color);
      setSecondaryBackground(project.theme_secondary_color);
      setPrimaryTextColour(project.text_primary_color);
      setSecondaryTextColour(project.text_secondary_color);
      sethoverTextColor(project.hover_text_color);
      sethoverBackgroundColor(project.hover_background_color);
      settextFontFamily(project.text_font_family);
      setthemeBoxShadow(project.theme_box_shadow);
      setthemeH1FontSize(project.theme_h1_font_size);
      setthemeH2FontSize(project.theme_h2_font_size);
      setthemeH3FontSize(project.theme_h3_font_size);
      setthemeH4FontSize(project.theme_h4_font_size);
      setthemePFontSize(project.theme_p_font_size);
      setthemeH1FontWeight(project.theme_h1_font_weight);
      setthemeH2FontWeight(project.theme_h2_font_weight);
      setthemeH3FontWeight(project.theme_h3_font_weight);
      setthemePFontWeight(project.theme_p_font_weight);
      setthemeLineHeight(project.theme_line_height);
      setProfileImage(s3baseUrl + project.image);
      setimage(project.image);
      setCategory(project.project_category._id);
    }
    setusers(true);
  };
  const defaultTheme = () => {
    setMode("light");
    setPrimaryBackground("#FFFFFF");
    setSecondaryBackground("#eb144c");
    setPrimaryTextColour("#000000");
    setSecondaryTextColour("#FFFFFF");
    sethoverTextColor("#FFFFFF");
    sethoverBackgroundColor("#eb144c");
    settextFontFamily("'Roboto', sans-serif");
    setthemeBoxShadow("#ABB8C3");
    setthemeH1FontSize("2.500rem");
    setthemeH2FontSize("2.000rem");
    setthemeH3FontSize("1.750rem");
    setthemeH4FontSize("1.500rem");
    setthemePFontSize("1.000rem");
    setthemeLineHeight("1.5");
    setthemeH1FontWeight("500");
    setthemeH2FontWeight("500");
    setthemeH3FontWeight("500");
    setthemePFontWeight("500");
  };
  const get_projectcategory = async () => {
    const projectcategory_detail = await get_templatecategory_detail();
    console.log(
      projectcategory_detail.template_category,
      "details of category list"
    );
    setCategorydata(projectcategory_detail.template_category);
    get_project();
  };
  // const get_project_type = async () => {
  //   const section_type_detail = await get_templatecategory_detail();
  //   console.log(section_type_detail.template_type, "section");
  //   setSectiondata(section_type_detail.template_type);
  // };
  useEffect(() => {
    get_projectcategory();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }
  console.log(category, "cat name from Api result ");
  // console.log(state.complete, "state.complete ");
  // console.log(state.published, "state.published ");

  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Project
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Name"
                    name="name"
                    autoFocus
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="categorydata"
                    value={categorydata}
                    onChange={(e) => setName(e.target.value)}
                    label="Category"
                    name="categorydata"
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="category"
                    value={projectCategory}
                    onChange={(e) => setProjectCategory(e.target.value)}
                    label="Category"
                    name="category"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={(classes.formControl, classes.category_field)}
                    fullWidth
                  >
                    <InputLabel id="cat_id">Category</InputLabel>
                    <Select
                      labelId="cat_id"
                      value={category}
                      // defaultValue={category}
                      onChange={(event) => {
                        setCategory(event.target.value);
                      }}
                      label="Category"
                    >
                      {categorydata.map((option) => (
                        <MenuItem value={option._id}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="small"
                    className={classes.default_Theme_btn}
                    variant="contained"
                    color="primary"
                    onClick={defaultTheme}
                  >
                    Set Default Theme Values
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <p>Primary Background</p>
                  <TwitterPicker
                    color={primarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Background</p>
                  <TwitterPicker
                    color={secondarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Text Color</p>
                  <TwitterPicker
                    color={hoverTextColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverTextColor(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Background Color</p>
                  <TwitterPicker
                    color={hoverBackgroundColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverBackgroundColor(updateColor.hex)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <p>Primary Text Colour</p>
                  <TwitterPicker
                    color={primarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Text Colour</p>
                  <TwitterPicker
                    color={secondarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>Box Shadow</p>
                  <TwitterPicker
                    color={themeBoxShadow}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setthemeBoxShadow(updateColor.hex)
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="mode">Mode</InputLabel>
                    <Select
                      labelId="mode"
                      fullWidth
                      label="Mode"
                      value={mode}
                      onChange={(e) => {
                        const selectedMode = e.target.value;
                        setMode(selectedMode);
                      }}
                    >
                      <MenuItem value="dark">Dark</MenuItem>
                      <MenuItem value="light">Light</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="font_family">Font Family</InputLabel>
                    <Select
                      labelId="font_family"
                      fullWidth
                      label="font family"
                      value={textFontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextFontFamily(selectedSize);
                      }}
                    >
                      <MenuItem value="'Roboto', sans-serif">
                        "Roboto", sans-serif
                      </MenuItem>
                      <MenuItem value="'Montserrat', sans-serif">
                        "Montserrat", sans-serif
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="line_height">Line Height</InputLabel>
                    <Select
                      labelId="line_height"
                      fullWidth
                      label="line height"
                      value={themeLineHeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeLineHeight(selectedSize);
                      }}
                    >
                      {line_Height.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Size</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Size"
                      value={themeH1FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Size</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Size"
                      value={themeH2FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Size</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Size"
                      value={themeH3FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H4">H4 Font Size</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Size"
                      value={themeH4FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Size</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Size"
                      value={themePFontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Weight</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Weight"
                      value={themeH1FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Weight</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Weight"
                      value={themeH2FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Weight</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Weight"
                      value={themeH3FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H4 Font Weight</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Weight"
                      value={themeH4FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Weight</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Weight"
                      value={themePFontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    type="number"
                    id="order"
                    fullWidth
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    label="Order"
                    name="order"
                  />
                </Grid>
                <Grid item xs={3}>
                  Status :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={state.status}
                        onChange={handleChange}
                        name="status"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  Completed :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        onChange={handleChange}
                        name="complete"
                        checked={state.complete}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  Approved :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={state.approved}
                        onChange={handleChange}
                        name="approved"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  Published :
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={state.published}
                        onChange={handleChange}
                        name="published"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {file ? (
                    <>
                      {" "}
                      <img
                        className={classes.img}
                        src={file !== "" ? file : s3baseUrl + default_image}
                      />{" "}
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/x-png,image/jpeg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      fullWidth
                      variant="outlined"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Update Project
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
