import { invokeApi } from "../../bl_libs/invokeApi";

export const Project_list = async () => {
  let requestObj = {
    path: `api/project/project_list_for_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const upload_image = async (data) => {
  let requestObj = {
    path: "api/app_api/upload_image_on_s3",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const upload_files = async (data) => {
  let requestObj = {
    path: "api/admin/save_files_by_admin",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const upload_folder = async (data) => {
//   let requestObj = {
//     path: `api/app_api/save_page`,
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
// export const add_section = async (data) => {
//   let requestObj = {
//     path: "api/section/add_section",
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

// export const get_project_detail = async (id) => {
//   // console.log(id)
//   let requestObj = {
//     path: `/api/project/project_details_for_admin/`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
export const get_project_detail = async (id) => {
  // console.log(id)
  let requestObj = {
    path: `api/project/project_details_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
// //Source code get Api
// export const get_source_code = async (id) => {
//   console.log(id, "code id");
//   let requestObj = {
//     path: `api/section/list_section_all/`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
// //Source code get Api ends here

export const delete_project = async (id) => {
  let requestObj = {
    path: `api/project/delete_project_for_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const get_section_detail = async (id) => {
//   // console.log(id)
//   let requestObj = {
//     path: `api/section/detail_section/${id}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };

// export const get_sectionType_detail = async () => {
//   // console.log(id)
//   let requestObj = {
//     path: `api/template_type/list_template_type`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };

export const edit_Project = async (data, id) => {
  let requestObj = {
    path: `api/project/edit_project_for_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const save_as_template = async (data, id) => {
  console.log(id, "ijijijijijijiji");
  let requestObj = {
    path: `api/project/project_save_as_template_for_admin/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_templatecategory_detail = async () => {
  // console.log(id)
  let requestObj = {
    path: `api/template_category/list_template_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
