import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TwitterPicker } from "react-color";
import {
  get_template_detail,
  edit_Template,
  get_category_detail,
  upload_image,
  edit_Template_root_values,
} from "../../../DAL/templates/templates";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import {
  color_array,
  options,
  font_weight,
  line_Height,
  template_type,
  google_font_family_classes,
  project_type,
} from "../../../ui_libs/constants";
import { template_category_list } from "../../../DAL/template_categories/TemplateCategories";
import { Divider } from "@mui/material";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  default_Theme_btn: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EditRootValues(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errorflag, setflag] = useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [isuser, setusers] = React.useState(false);
  const [categorydata, setCategorydata] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [templateCategorydata, setTemplateCategorydata] = React.useState([]); //ok
  const [templateCategory, setTemplateCategory] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [imagepath, setImagepath] = React.useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [mode, setMode] = useState("");
  const [order, setOrder] = useState("");
  const [primarybackground, setPrimaryBackground] = useState("");
  const [secondarybackground, setSecondaryBackground] = useState("");
  const [textHeadingcolour, setTextHeadingColour] = useState("");
  const [primarytextcolour, setPrimaryTextColour] = useState("");
  const [secondarytextcolour, setSecondaryTextColour] = useState("");
  const [hoverTextColor, sethoverTextColor] = useState("");
  const [hoverBackgroundColor, sethoverBackgroundColor] = useState("");
  const [themeBoxShadow, setthemeBoxShadow] = useState("");
  const [menuColour, setMenuColour] = useState("");
  const [menuHoverColour, setMenuHoverColour] = useState("");
  const [inputTextColour, setInputTextColour] = useState("");
  const [inputPlaceholderColour, setInputPlaceholderColour] = useState("");
  const [inputBackGroudColour, setinputBackgroundColour] = useState("");
  const [inputBorderColour, setinputBorderColour] = useState("");

  const [textFontFamily, settextFontFamily] = useState("");
  const [textH1FontFamily, settextH1FontFamily] = useState("");
  const [textH2FontFamily, settextH2FontFamily] = useState("");
  const [textH3FontFamily, settextH3FontFamily] = useState("");
  const [textH4FontFamily, settextH4FontFamily] = useState("");

  const [themeH1FontSize, setthemeH1FontSize] = useState("");
  const [themeH2FontSize, setthemeH2FontSize] = useState("");
  const [themeH3FontSize, setthemeH3FontSize] = useState("");
  const [themeH4FontSize, setthemeH4FontSize] = useState("");
  const [themePFontSize, setthemePFontSize] = useState("");
  const [themeLineHeight, setthemeLineHeight] = useState("");
  const [themeH1FontWeight, setthemeH1FontWeight] = useState("");
  const [themeH2FontWeight, setthemeH2FontWeight] = useState("");
  const [themeH3FontWeight, setthemeH3FontWeight] = useState("");
  const [themeH4FontWeight, setthemeH4FontWeight] = useState("");
  const [themePFontWeight, setthemePFontWeight] = useState("");

  const [templateType, setTemplateType] = useState("");
  const [projectType, setProjectType] = useState("0");
  const [state, setState] = React.useState({
    status: true,
    approved: true,
    completed: true,
    published: true,
  });
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  // const handleChangeCategory = (value) => {
  //   console.log(value, "value");
  //   let _catTag = [];
  //   value.map((x, i) => {
  //     if (x._id) {
  //       _catTag.push({ template_category_id: x._id });
  //     } else {
  //       _catTag.push({ template_category_id: x.template_category_id });
  //     }
  //   });
  //   setCategory(_catTag);
  //   console.log(_catTag, "final tags");
  // };
  // const fileChangedHandler = async (e) => {
  //   setiswaiting(true);
  //   console.log(e.target.files[0]);
  //   setProfileImage(URL.createObjectURL(e.target.files[0]));
  //   let formData = new FormData(); //formdata object
  //   formData.append("project_name", "category");
  //   formData.append("upload_file", e.target.files[0]);
  //   const upload_image_resp = await upload_image(formData);
  //   console.log(upload_image_resp);
  //   setimage(upload_image_resp.file_name);
  //   if (upload_image_resp.code == 200) {
  //     setiswaiting(false);
  //   }
  //   // if (e.target.files.length) {
  //   //   setimage(e.target.files[0]);
  //   //   setProfileImage(URL.createObjectURL(e.target.files[0]));
  //   // }
  // };

  const handleback = () => {
    history.goBack();
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    let response = false;
    if (props.location.data?.changeRootValuesOfAllTemplates) {
      const promisesArray = props.location.data.groupPages.map((template) =>
        handleChangeValues(template.template)
      );
      Promise.all(promisesArray).then((results) => {
        // Check if all promises returned truthy values
        const allTruthy = results.every((result) => !!result);
        if (allTruthy) {
          handleback();
          enqueueSnackbar(`Rootvalues Updated Successfully`, {
            variant: "success",
          });
        }
      });
    } else {
      response = await handleChangeValues(props.match.params.id);
    }

    if (response) {
      handleback();
      enqueueSnackbar(`Rootvalues Updated Successfully`, {
        variant: "success",
      });
    }

    setiswaiting(false);
  };
  const handleChangeValues = async (template_id) => {
    setiswaiting(true);
    let postdata = {
      theme_primary_color: primarybackground,
      theme_secondary_color: secondarybackground,
      text_primary_color: primarytextcolour,
      text_secondary_color: secondarytextcolour,
      hover_text_color: hoverTextColor,
      hover_background_color: hoverBackgroundColor,
      theme_box_shadow: themeBoxShadow,
      theme_heading_color: textHeadingcolour,
      menu_color: menuColour,
      menu_hover_color: menuHoverColour,
      input_text_color: inputTextColour,
      input_placeholder_color: inputPlaceholderColour,
      input_background_color: inputBackGroudColour,
      input_border_color: inputBorderColour,

      text_font_family: textFontFamily,
      theme_h1_font_size: themeH1FontSize.toString(),
      theme_h2_font_size: themeH2FontSize.toString(),
      theme_h3_font_size: themeH3FontSize.toString(),
      theme_h4_font_size: themeH4FontSize.toString(),
      theme_p_font_size: themePFontSize.toString(),
      theme_h1_font_weight: themeH1FontWeight.toString(),
      theme_h2_font_weight: themeH2FontWeight.toString(),
      theme_h3_font_weight: themeH3FontWeight.toString(),
      theme_h4_font_weight: themeH4FontWeight.toString(),
      theme_p_font_weight: themePFontWeight.toString(),
      theme_h1_font_family: textH1FontFamily.toString(),
      theme_h2_font_family: textH2FontFamily.toString(),
      theme_h3_font_family: textH3FontFamily.toString(),
      theme_h4_font_family: textH4FontFamily.toString(),
      theme_line_height: themeLineHeight.toString(),
    };
    console.log(postdata, "rootvalespostdata");
    const country_edit_resp = await edit_Template_root_values(
      postdata,
      template_id
    );
    if (country_edit_resp.code == 200) {
      // props.history.push("/templates");
      // handleback();
      return true;
    } else {
      enqueueSnackbar(country_edit_resp.message, { variant: "error" });
    }
  };

  function findMatchingCssRule(css_rule, returnValue = null) {
    for (let item of google_font_family_classes) {
      if (
        css_rule.replaceAll(" ", "").replaceAll(";", "") ===
        item.css_rule.replaceAll(" ", "").replaceAll(";", "")
      ) {
        return item.css_rule;
      }
    }
    return returnValue ? returnValue : "'Montserrat',sans-serif"; // Return null if no match is found
  }

  const get_template = async () => {
    const template = await get_template_detail(props.match.params.id);
    console.log(template, "dataaaaaa");
    if (template.code == 200) {
      console.log(template.template, "template");
      console.log(template.template.theme_h2_font_family, "template");

      setPrimaryBackground(template.template.theme_primary_color);
      setSecondaryBackground(template.template.theme_secondary_color);
      setPrimaryTextColour(template.template.text_primary_color);
      setSecondaryTextColour(template.template.text_secondary_color);
      sethoverTextColor(template.template.hover_text_color);
      sethoverBackgroundColor(template.template.hover_background_color);
      setthemeBoxShadow(template.template.theme_box_shadow);
      setTextHeadingColour(template.template.theme_heading_color);
      setMenuColour(template.template.menu_color);
      setMenuHoverColour(template.template.menu_hover_color);
      setInputTextColour(template.template.input_text_color);
      setInputPlaceholderColour(template.template.input_placeholder_color);
      setinputBackgroundColour(template.template.input_background_color);
      setinputBorderColour(template.template.input_border_color);

      setthemeLineHeight(template.template.theme_line_height);
      settextFontFamily(
        findMatchingCssRule(
          template.template.text_font_family,
          "'Roboto',sans-serif"
        )
      );
      settextH1FontFamily(
        findMatchingCssRule(template.template.theme_h1_font_family)
      );
      settextH2FontFamily(
        findMatchingCssRule(template.template.theme_h2_font_family)
      );
      settextH3FontFamily(
        findMatchingCssRule(template.template.theme_h3_font_family)
      );
      settextH4FontFamily(
        findMatchingCssRule(template.template.theme_h4_font_family)
      );
      setthemeH1FontSize(template.template.theme_h1_font_size);
      setthemeH2FontSize(template.template.theme_h2_font_size);
      setthemeH3FontSize(template.template.theme_h3_font_size);
      setthemeH4FontSize(template.template.theme_h4_font_size);
      setthemePFontSize(template.template.theme_p_font_size);
      setthemeH1FontWeight(template.template.theme_h1_font_weight);
      setthemeH2FontWeight(template.template.theme_h2_font_weight);
      setthemeH3FontWeight(template.template.theme_h3_font_weight);
      setthemeH4FontWeight(template.template.theme_h4_font_weight);
      setthemePFontWeight(template.template.theme_p_font_weight);
    }
    setusers(true);
  };
  const defaultTheme = () => {
    setMode("light");
    setPrimaryBackground("#FFFFFF");
    setSecondaryBackground("#eb144c");
    setPrimaryTextColour("#000000");
    setSecondaryTextColour("#FFFFFF");
    sethoverTextColor("#FFFFFF");
    sethoverBackgroundColor("#eb144c");
    setTextHeadingColour("#000000");
    setMenuColour("#000000");
    setMenuHoverColour("#ffffff");
    setInputTextColour("");
    setInputPlaceholderColour("");
    setinputBorderColour("");
    setinputBackgroundColour("");
    settextFontFamily("'Roboto',sans-serif");

    settextH1FontFamily("'Montserrat',sans-serif");
    settextH2FontFamily("'Montserrat',sans-serif");
    settextH3FontFamily("'Montserrat',sans-serif");
    settextH4FontFamily("'Montserrat',sans-serif");

    setthemeBoxShadow("#ABB8C3");
    setthemeH1FontSize("2.500rem");
    setthemeH2FontSize("2.000rem");
    setthemeH3FontSize("1.750rem");
    setthemeH4FontSize("1.500rem");
    setthemePFontSize("1.000rem");
    setthemeLineHeight("1.5");
    setthemeH1FontWeight("500");
    setthemeH2FontWeight("500");
    setthemeH3FontWeight("500");
    setthemeH4FontWeight("500");
    setthemePFontWeight("500");
  };
  //console.log(hoverTextColor);
  const get_category = async () => {
    const category_detail = await get_category_detail(props.match.params.id);
    console.log(category_detail.category, "category show krwaani");
    setCategorydata(category_detail.category);
  };
  const get_template_category = async () => {
    const templatecategory_detail = await template_category_list();
    setTemplateCategorydata(templatecategory_detail.template_category);
  };
  useEffect(async () => {
    await get_template();
    // await get_category();
    // await get_template_category();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      {isuser == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Template Root Values
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    size="small"
                    className={classes.default_Theme_btn}
                    variant="contained"
                    color="primary"
                    onClick={defaultTheme}
                  >
                    Set Default Theme Values
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <p>Primary Background</p>
                  <TwitterPicker
                    color={primarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Background</p>
                  <TwitterPicker
                    color={secondarybackground}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryBackground(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Background Color</p>
                  <TwitterPicker
                    color={hoverBackgroundColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverBackgroundColor(updateColor.hex)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <p>Text Heading Colour</p>
                  <TwitterPicker
                    color={textHeadingcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setTextHeadingColour(updateColor.hex)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <p>Primary Text Colour</p>
                  <TwitterPicker
                    color={primarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setPrimaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Secondary Text Colour</p>
                  <TwitterPicker
                    color={secondarytextcolour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setSecondaryTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Hover Text Color</p>
                  <TwitterPicker
                    color={hoverTextColor}
                    colors={color_array}
                    onChange={(updateColor) =>
                      sethoverTextColor(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Box Shadow</p>
                  <TwitterPicker
                    color={themeBoxShadow}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setthemeBoxShadow(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Menu Colour</p>
                  <TwitterPicker
                    color={menuColour}
                    colors={color_array}
                    onChange={(updateColor) => setMenuColour(updateColor.hex)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Menu Hover Colour</p>
                  <TwitterPicker
                    color={menuHoverColour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setMenuHoverColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Input Text Colour</p>
                  <TwitterPicker
                    color={inputTextColour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setInputTextColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Input Placeholder Colour</p>
                  <TwitterPicker
                    color={inputPlaceholderColour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setInputPlaceholderColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Input Background Colour</p>
                  <TwitterPicker
                    color={inputBackGroudColour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setinputBackgroundColour(updateColor.hex)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Input Border Colour</p>
                  <TwitterPicker
                    color={inputBorderColour}
                    colors={color_array}
                    onChange={(updateColor) =>
                      setinputBorderColour(updateColor.hex)
                    }
                  />
                </Grid>

                <Divider style={{ width: "100%", marginBlock: "24px" }} />

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="line_height">Line Height</InputLabel>
                    <Select
                      labelId="line_height"
                      fullWidth
                      variant="outlined"
                      label="line height"
                      value={themeLineHeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeLineHeight(selectedSize);
                      }}
                    >
                      {line_Height.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="font_family">Font Family</InputLabel>
                    <Select
                      labelId="font_family"
                      fullWidth
                      label="font family"
                      value={textFontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextFontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h1_font_family">H1 Font Family </InputLabel>
                    <Select
                      labelId="h1_font_family"
                      fullWidth
                      label="h1 font family"
                      value={textH1FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH1FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h2_font_family"> H2 Font Family</InputLabel>
                    <Select
                      labelId="h2_font_family"
                      fullWidth
                      label="h2 font family"
                      value={textH2FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH2FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h3_font_family">
                      {" "}
                      H3 Font Family{" "}
                    </InputLabel>
                    <Select
                      labelId="h3_font_family"
                      fullWidth
                      label="h3 font family"
                      value={textH3FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH3FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="h4_font_family">
                      {" "}
                      H4 Font Family{" "}
                    </InputLabel>
                    <Select
                      labelId="h4_font_family"
                      fullWidth
                      label="h4 font family"
                      value={textH4FontFamily}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        settextH4FontFamily(selectedSize);
                      }}
                    >
                      {google_font_family_classes.map((font) => (
                        <MenuItem value={font.css_rule}>{font.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider style={{ width: "100%" }} />

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Size</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Size"
                      value={themeH1FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Size</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Size"
                      value={themeH2FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Size</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Size"
                      value={themeH3FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H4">H4 Font Size</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Size"
                      value={themeH4FontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Size</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Size"
                      value={themePFontSize}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontSize(selectedSize);
                      }}
                    >
                      {options().map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Divider style={{ width: "100%" }} />
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H1">H1 Font Weight</InputLabel>
                    <Select
                      labelId="H1"
                      fullWidth
                      label="H1 font Weight"
                      value={themeH1FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH1FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H2">H2 Font Weight</InputLabel>
                    <Select
                      labelId="H2"
                      fullWidth
                      label="H2 font Weight"
                      value={themeH2FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH2FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H3 Font Weight</InputLabel>
                    <Select
                      labelId="H3"
                      fullWidth
                      label="H3 font Weight"
                      value={themeH3FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH3FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="H3">H4 Font Weight</InputLabel>
                    <Select
                      labelId="H4"
                      fullWidth
                      label="H4 font Weight"
                      value={themeH4FontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemeH4FontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="p">P Font Weight</InputLabel>
                    <Select
                      labelId="p"
                      fullWidth
                      label="P font Weight"
                      value={themePFontWeight}
                      onChange={(e) => {
                        const selectedSize = e.target.value;
                        setthemePFontWeight(selectedSize);
                      }}
                    >
                      {font_weight.map((val, index) => {
                        return (
                          <MenuItem value={val.value}>{val.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Update Root Values
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
