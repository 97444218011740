import { Icon } from "@iconify/react";
import {
  Grid,
  Tooltip,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import PreviewDialog from "./components/PreviewDialog";
import UploadMediaDialog from "./components/UploadMedia";
import { s3baseUrl } from "../../config/config";

import { color_palette } from "../../theme/theme";
import { get_readable_storage_size, get_short_string } from "../utils/utils";
import {
  delete_picture,
  get_gallery_images,
} from "../../DAL/Gallery/gallery_api";
import { Button } from "@material-ui/core";
import CustomConfirmation from "../../components/CustomConfirmation";

// import BaseCard from "../../components/baseCard/BaseCard";

const Gallery = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [userImagesList, setUserImagesList] = useState([]);
  const [searchImageList, setSearchImageList] = useState([]);

  const [previewDialog, setPreviewDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [targetImage, setTargetImage] = useState(false);
  const [openDeleteImageDiloge, setOpenDeleteImageDiloge] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userAssertDetails, setUserAssertDetails] = useState("");
  const [planDetail, setplanDetail] = useState({
    assert_size: 0,
    assert_count: 0,
  });

  const isImagesNotFound = userImagesList.length === 0;

  const handlePreview = (e, image) => {
    setPreviewDialog(true);
    setPreviewImage(image);
  };

  const handleDialogOpen = () => {
    setUploadDialog(true);
  };

  const DeleteImageConfirmation = (image) => {
    setTargetImage(image);
    setOpenDeleteImageDiloge(true);
  };
  const handleDeleteImage = async () => {
    const resp = await delete_picture(targetImage._id);
    if (resp.code == 200) {
      let list = userImagesList.filter((img) => {
        if (img != targetImage) return img;
      });
      setOpenDeleteImageDiloge(false);
      setUserImagesList([...list]);
      setSearchImageList([...list]);
    } else {
      setOpenDeleteImageDiloge(false);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const GetImagesListing = async () => {
    let resp = await get_gallery_images();
    if (resp.code === 200) {
      if (resp.stock_image) {
        setUserImagesList(resp.stock_image);
        setSearchImageList(resp.stock_image.reverse());
      } else {
        setUserImagesList([]);
        setSearchImageList([]);
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };
  const updateUserAsserts = (file_count, file_size) => {
    let temp = { file_count: file_count, file_size: file_size };
    setUserAssertDetails({ ...temp });
  };

  const handleChangeSearch = (e) => {
    let value = e.target.value;
    let list = userImagesList;
    if (value.trim().length > 0) {
      list = [];
      userImagesList.map((image) => {
        if (image.file_name.toLowerCase().includes(value.toLowerCase())) {
          list.push(image);
        }
      });
    }
    setSearchImageList([...list]);
  };

  useEffect(() => {
    GetImagesListing();
  }, []);

  if (isLoading) {
    return <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />;
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={12}>
        <Box
          p={2}
          display="flex"
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems="flex-start"
          flexWrap={"wrap"}
        >
          <div className="d-flex flex-column pb-2">
            <h1 className="h1-font-size">Stock Images</h1>
            <div className="">
              <TextField
                InputProps={{
                  style: {
                    borderRadius: "30px",
                    backgroundColor: "white",
                  },
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#bebebe" }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                id="outlined-required"
                placeholder="Search..."
                // value={searchTemplates}
                onChange={handleChangeSearch}
              />
            </div>
          </div>
          <div
            className="d-flex flex-column"
            style={{
              width: "30%",
              alignItems: "flex-end",
            }}
          >
            <div>
              <Button
                variant={"contained"}
                sx={{
                  borderRadius: "30px",
                  padding: "4px 16px",
                  marginBottom: "16px",
                  // width: "250px",
                }}
                onClick={handleDialogOpen}
                color="primary"
              >
                <Icon
                  icon="material-symbols:add"
                  style={{ fontSize: "1.25rem" }}
                />
                <span
                  style={{
                    marginLeft: "0.5rem",
                    fontSize: "1rem",
                    fontWeight: "lighter",
                  }}
                >
                  Upload Images
                </span>
              </Button>
            </div>
          </div>
        </Box>
        <Card>
          <CardContent>
            {isImagesNotFound && (
              <div align="center" colSpan={12} sx={{ py: 3 }}>
                <h3>No data found to display</h3>
              </div>
            )}

            <div className="row">
              {userImagesList &&
                searchImageList.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="wb-image col-lg-3 col-md-4 col-4 mb-2 px-1"
                      style={{
                        position: "relative",
                      }}
                      onDoubleClick={(e) => handlePreview(e, image)}
                    >
                      <div
                        style={{
                          border: "0.005rem solid",
                          borderColor: color_palette.primary,
                          borderRadius: "5px",
                          height: "100%",
                        }}
                      >
                        <img
                          className="image img-fluid gallery-image-style border-bottom"
                          src={s3baseUrl + image.file_path}
                          alt="upload"
                        />

                        <div className="image-name-size">
                          <span>{get_short_string(image.file_name, 30)}</span>
                          <span>
                            {get_readable_storage_size(image.file_size)}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          display: "flex",
                          top: "3%",
                          right: "4%",
                        }}
                      >
                        <Tooltip title="Preview Image" placement="top" arrow>
                          <div
                            className="image-icon me-2"
                            onClick={(e) => handlePreview(e, image)}
                          >
                            <Icon icon="ic:outline-remove-red-eye" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Remove Image" placement="top" arrow>
                          <div
                            className="image-icon icon-delete"
                            onClick={() => DeleteImageConfirmation(image)}
                          >
                            <Icon icon="material-symbols:delete" />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  );
                })}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <PreviewDialog
        setPreviewDialog={setPreviewDialog}
        previewDialog={previewDialog}
        previewImage={previewImage}
      />
      <UploadMediaDialog
        uploadDialog={uploadDialog}
        setUploadDialog={setUploadDialog}
        setUserImagesList={setUserImagesList}
        setSearchImageList={setSearchImageList}
        userImagesList={userImagesList}
        searchImageList={searchImageList}
      />
      <CustomConfirmation
        open={openDeleteImageDiloge}
        setOpen={setOpenDeleteImageDiloge}
        handleAgree={handleDeleteImage}
        title="Are you sure you want to delete this image?"
      />
    </Grid>
  );
};

export default Gallery;
