import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import UploadDropZone from "./UploadDropZone";
import { Icon } from "@iconify/react";

function UploadMediaDialog(props) {
  const {
    uploadDialog,
    setUploadDialog,
    setUserImagesList,
    setSearchImageList,
    userImagesList,
    searchImageList,
  } = props;

  const handleClose = () => {
    setUploadDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={uploadDialog}
        onClose={handleClose}
      >
        <div
          className="border-bottom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <DialogTitle>Upload Images</DialogTitle>
          <div
            className="icon"
            style={{ margin: "16px 24px", cursor: "pointer" }}
            onClick={handleClose}
          >
            <Icon icon="system-uicons:cross" />
          </div>
        </div>
        <div
          style={{
            height: "75vh",
            display: "flex",
            flexDirection: "row",
            overflow: "auto",
          }}
        >
          <UploadDropZone
            setUploadDialog={setUploadDialog}
            setUserImagesList={setUserImagesList}
            setSearchImageList={setSearchImageList}
            userImagesList={userImagesList}
            searchImageList={searchImageList}
          />
        </div>
      </Dialog>
    </>
  );
}

export default UploadMediaDialog;
