import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

function CustomConfirmation({ open, setOpen, handleAgree, title }) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} style={{overflow:"hidden"}}>
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAgree}>Yes, Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
