import { invoke_api_code } from "../../bl_libs/invokeApi";

var myHeaders = new Headers();
myHeaders.append("pragma", "no-cache");
myHeaders.append("cache-control", "no-cache");

var myInit = {
  method: "GET",
  headers: myHeaders,
};

export const fetch_file_from_s3 = (url) => {
  return new Promise(async (resolve, reject) => {
    const resp = await fetch(url, myInit);
    if (resp.status === 200) {
      const data = await resp.text();
      resolve(data);
    } else {
      console.error(resp, "fetch_from_s3_error");
      resolve("");
    }
  });
};

//Source code get Api
export const get_source_code_html = async (id) => {
  //console.log(id,"code id to show")
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  let requestObj = {
    path: `${id}/index.html`,
    method: "GET",
    headers: myHeaders,
  };
  return invoke_api_code(requestObj);
};
//css===============================
export const get_source_code_css = async (id) => {
  //console.log(id,"code id to show css")
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  let requestObj = {
    path: `${id}/css/style.css`,
    method: "GET",
    headers: myHeaders,
  };
  return invoke_api_code(requestObj);
};

//css===============================
export const get_source_code_js = async (id) => {
  //console.log(id,"code id to show css")
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  let requestObj = {
    path: `${id}/js/index.js`,
    method: "GET",
    headers: myHeaders,
  };
  return invoke_api_code(requestObj);
};
//Source code get Api ends here
