import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from "@mui/material";

import { Delete } from "@mui/icons-material";

const FilePreviewModal = ({ open, onClose, files, onDelete, onUpload }) => {
  const handleDelete = (index) => {
    onDelete(index);
  };

  return (
    <Dialog open={open} onClose={onClose}   sx={{
        "& .MuiDialog-paper": {
          width: "800px", // Set the desired width
          height: "500px", // Set the desired height
        },
      }} >

   
      <DialogTitle>Uploaded Files</DialogTitle>
      <DialogContent>
        {files.length === 0 ? (
          <Typography>No files uploaded.</Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        style={{ width: 50, height: 55, objectFit: "cover" }}
                      />
                    </TableCell>
                    <TableCell>{file.name}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(index)}>
                        <Delete/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onUpload}>Upload</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilePreviewModal;
