import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import {
  get_project_detail,
  edit_Project,
  upload_image,
  save_as_template,
} from "../../../DAL/project/projects";
import { template_type } from "../../../ui_libs/constants";
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 10,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SaveAsTemplate(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [imagedata, setImageData] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [iswaiting, setiswaiting] = useState(false);
  const [status, setStatus] = React.useState(true);
  const [templateType, setTemplateType] = React.useState("");
  const handlename = (event) => {
    setName(event.target.value);
  };
  const handleChange = (event) => {
    //  console.log(event.target.checked);
    setStatus(event.target.checked);
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();

    if (!imagedata) {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    }
    setiswaiting(true);
    let formData = new FormData(); //formdata object

    formData.append("project_name", "category");
    formData.append("upload_file", imagedata);
    const upload_image_resp = await upload_image(formData);
    console.log(upload_image_resp, "resssssssssss");
    // setimage(upload_image_resp.file_name);

    if (upload_image_resp.code == 200) {
      console.log(Image, "image show");
      let formData = new FormData(); //formdata object
      formData.append("name", name);
      // if(Image){
      formData.append("image", upload_image_resp.file_name);
      formData.append("template_type", templateType);
      formData.append("status", status);
      // }else{
      //   enqueueSnackbar("please upload image", { variant: "error" });
      //   return;
      // }

      const templatecategory_add_resp = await save_as_template(
        formData,
        props.match.params.id
      );
      console.log(templatecategory_add_resp);
      if (templatecategory_add_resp.code == 200) {
        setiswaiting(false);
        enqueueSnackbar(name + "Saved As Template", {
          variant: "success",
        });
        props.history.push("/project");
      } else {
        setiswaiting(false);
        enqueueSnackbar(templatecategory_add_resp.message, {
          variant: "error",
        });
      }
    } else {
      setiswaiting(false);
      enqueueSnackbar("image not uploaded", { variant: "error" });
    }
  };
  const fileChangedHandler = async (e) => {
    console.log(e.target.files[0]);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImageData(e.target.files[0]);

    // if (e.target.files.length) {
    //   setimage(e.target.files[0]);
    //   setProfileImage(URL.createObjectURL(e.target.files[0]));
    // }
  };

  useEffect(() => {}, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Save As Template
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Name"
                  className={classes.txt}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="name"
                  value={name}
                  onChange={handlename}
                  name="name"
                  autoFocus
                />
                <Grid item xs={6}>
                  Status :
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    className={classes.txt}
                    control={
                      <Switch
                        color="primary"
                        checked={status}
                        onChange={handleChange}
                        name="checkedA"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.txt}
                    variant="outlined"
                    style={{ minWidth: 550 }}
                  >
                    <InputLabel id="Type">Template Type</InputLabel>
                    <Select
                      labelId="Type"
                      fullWidth
                      label="Template Type"
                      value={templateType}
                      onChange={(e) => {
                        const selectedType = e.target.value;
                        setTemplateType(selectedType);
                      }}
                    >
                      {template_type.map((option, index) => {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {file ? (
                    <>
                      {" "}
                      <img className={classes.img} src={file} />{" "}
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/x-png,image/jpeg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      fullWidth
                      className={classes.txt}
                      variant="outlined"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Save Template
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
