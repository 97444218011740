import { Button, Dialog, DialogTitle, Stack } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { s3baseUrl } from "../../config/config";

function PageTemplateModal({
  pageTemplateModal,
  setPageTemplateModal,
  templatesList,
  handlePageClick,
}) {
  const handleClose = () => {
    setPageTemplateModal(false);
  };

  const handleAddPageMoadal = (template) => {
    handlePageClick(template);
  };

  return (
    <>
      <Dialog
        open={pageTemplateModal}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderTop: "5px solid #023373",
              maxWidth: "100vw",
              maxHeight: "98vh",
              marginY: "16px",
            },
          },
          maxHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <div
          className="border-bottom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
            alignItems: "center",
            minHeight: "65px",
          }}
        >
          <DialogTitle className="px-0">
            <Stack sx={{ fontSize: "24px" }}>Templates</Stack>
          </DialogTitle>
          <div
            className="icon"
            style={{ margin: "16px 24px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </div>

        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {templatesList.length === 0 ? (
              <div className="w-100 p-5" style={{ textAlign: "center" }}>
                <h1>No Data Found</h1>
              </div>
            ) : (
              templatesList.map((template) => (
                <div key={template._id}>
                  <div className="create-project-card mx-3 mb-2">
                    <div style={{ height: "230px", width: "400px" }}>
                      <img
                        src={s3baseUrl + template.image}
                        alt=""
                        width="100%"
                        height="228px"
                        className="create-project-image"
                      />
                      <div className="create-project-edit-button">
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: "30px",
                            padding: "5px 23px",
                            margin: "0 8px 12px 0",
                          }}
                          onClick={() => {
                            handleAddPageMoadal(template);
                          }}
                        >
                          Choose Page
                        </Button>
                        {template.name !== "Blank Theme" && (
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: "30px",
                              padding: "5px 23px",
                              margin: "0 8px 12px 0",
                            }}
                            onClick={() => {
                              window.open(
                                s3baseUrl + template._id + "/" + "index.html"
                              );
                            }}
                          >
                            Preview Page
                          </Button>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        height: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 25px",
                        background: "#fff",
                      }}
                    >
                      {template.name === "Blank Theme"
                        ? "Blank Page"
                        : template.name}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      </Dialog>
    </>
  );
}

export default PageTemplateModal;
