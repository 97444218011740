import { invokeApi } from "../../bl_libs/invokeApi";

export const template_category_list = async () => {
  let requestObj = {
    path: `api/template_category/list_template_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const template_search = async (search_text) => {
//     let requestObj = {
//       path: `api/template/search_template?text=${search_text}`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };

export const upload_image = async (data) => {
  let requestObj = {
    path: "api/app_api/upload_image_on_s3",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_template_category = async (data) => {
  let requestObj = {
    path: "api/template_category/add_template_category",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_template_category = async (id) => {
  let requestObj = {
    path: `api/template_category/delete_template_category/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_template_category_detail = async (id) => {
  // console.log(id)
  let requestObj = {
    path: `api/template_category/detail_template_category/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_template_category = async (data, id) => {
  let requestObj = {
    path: `api/template_category/update_template_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
