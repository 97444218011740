import { invokeApi } from "../../bl_libs/invokeApi";

export const AddSettings = async (data) => {
  let requestObj = {
    path: "api/website_setting/edit_website_setting",
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;

  return invokeApi(requestObj);
};

export const GetSettings = async () => {
  let requestObj = {
    path: "api/website_setting/",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
